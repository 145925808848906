/**
 * @copyright 2023 @ DigiNet
 * @author ANHTAI
 * @create 2023/04/15 15:54
 * @update 2023/04/15 15:54
 */

import * as types from '../../actions/types.js';

const initialState = {
    dataSettingForm: {},
    dataOriginalDecimal: {},

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F1005_GET_DATA_SETTING_FORM:
            return {
                ...state,
                dataSettingForm: action.dataSettingForm
            };
        case types.W17_W17F1005_ORIGINAL_DECIMAL_COMPLETE:
            return {
                ...state,
                dataOriginalDecimal: action.dataOriginalDecimal
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from '../actions/types.js';

const initialState = {
    dataW18F4030: null,
    cboTaskType:null,
    cboStatus:null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18F4030_LOAD_REPORT_COMPLETE:
            return {
                ...state,
                dataW18F4030: action.dataW18F4030
            };
        case types.W18F4030_LOAD_TASK_TYPE_COMPLETE:
            return {
                ...state,
                cboTaskType: action.cboTaskType
            };
        case types.W18F4030_LOAD_STATUS_COMPLETE:
            return {
                ...state,
                cboStatus: action.cboStatus
            };
        default:
            return state;
    }
}
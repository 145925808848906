/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/02 16:36
 * @update 2019/10/02 16:36
 */


import * as types from '../actions/types.js';

const initialState = {
    getComboStatus: [],
    getComboFeedback: [],
    getCaseType: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1060_GET_COMBO_STATUS:
            return {
                ...state,
                getComboStatus: action.getComboStatus
            };
        case types.W18_W18F1060_GET_COMBO_FEEDBACK:
            return {
                ...state,
                getComboFeedback: action.getComboFeedback
            };
        case types.W18_W18F1060_GET_CASE_TYPE:
            return {
                ...state,
                getCaseType: action.getCaseType
            };
        default:
            return state;
    }
}

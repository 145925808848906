/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {


};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1025_HANDOVER_COMPLETE:
            return {
                ...state,
                data: action.data
            };

        default:
            return state;
    }
}
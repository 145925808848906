/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */


import * as types from '../actions/types.js';

const initialState = {
    loadGrid: null,
    cbInfoTypeW18F1050: null,
    cbInfoValueW18F1050: null,
    cbRefInfoW18F1050: null,
    cbRefValueW18F1050: null,
    deleteW18F1050: null,
    loadDetailW19F1051: null,
    loadProvinceW19F1051: null,
    loadTypeInformation: null,
    loadCodeInformation: null,
    loadTypeCondition: null,
    loadCodeCondition: null,
    addW18F1051: null,
    editW18F1051: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1050_DATAGRID_COMPLETE:
            return {
                ...state,
                loadGrid: action.loadGrid
            };
        case types.W18_W18F1050_CB_INFO_TYPE_COMPLETE:
            return {
                ...state,
                cbInfoTypeW18F1050: action.cbInfoTypeW18F1050
            };
        case types.W18_W18F1050_CB_INFO_VALUE_COMPLETE:
            return {
                ...state,
                cbInfoValueW18F1050: action.cbInfoValueW18F1050
            };
        case types.W18_W18F1050_CB_INFO_REF_COMPLETE:
            return {
                ...state,
                cbRefInfoW18F1050: action.cbRefInfoW18F1050
            };
        case types.W18_W18F1050_CB_VALUE_REF_COMPLETE:
            return {
                ...state,
                cbRefValueW18F1050: action.cbRefValueW18F1050
            };
        case types.W18_W18F1050_DELETE_COMPLETE:
            return {
                ...state,
                deleteW18F1050: action.deleteW18F1050
            };
        case types.W18_W18F1051_LOAD_MASTER_COMPLETE:
            return {
                ...state,
                loadDetailW19F1051: action.loadDetailW19F1051
            };
        case types.W18_W18F1051_LOAD_PROVINCE_COMPLETE:
            return {
                ...state,
                loadProvinceW19F1051: action.loadProvinceW19F1051
            };
        case types.W18_W18F1051_LOAD_TYPE_INFORMATION_COMPLETE:
            return {
                ...state,
                loadTypeInformation: action.loadTypeInformation
            };
        case types.W18_W18F1051_LOAD_CODE_INFORMATION_COMPLETE:
            return {
                ...state,
                loadCodeInformation: action.loadCodeInformation
            };
        case types.W18_W18F1051_LOAD_TYPE_CONDITION_COMPLETE:
            return {
                ...state,
                loadTypeCondition: action.loadTypeCondition
            };
        case types.W18_W18F1051_LOAD_CODE_CONDITION_COMPLETE:
            return {
                ...state,
                loadCodeCondition: action.loadCodeCondition
            };
        case types.W18_W18F1051_ADD_COMPLETE:
            return {
                ...state,
                addW18F1051: action.addW18F1051
            };
        case types.W18_W18F1051_EDIT_COMPLETE:
            return {
                ...state,
                editW18F1051: action.editW18F1051
            };
        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/07/19 13:00
 * @update 2019/07/19 13:00
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    loadComboW18F1026: null,
    saveW18F1026: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1026_LOAD_COMBO_COMPLETE:
            return {
                ...state,
                loadComboW18F1026: action.loadComboW18F1026
            };
            case types.W18_W18F1026_SAVE_COMPLETE:
            return {
                ...state,
                saveW18F1026: action.saveW18F1026
            };
        default:
            return state;
    }
}
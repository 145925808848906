/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/14 17:37
 * @update 2019/02/14 17:37
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW18F1010Store: null,
    dataFilter: null,
    delW18F1010: null,
    loadCompertitorW18F1010: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18F1010_STORE_COMPLETE:
            return {
                ...state,
                loadW18F1010Store: action.loadW18F1010Store
            };
        case types.W18_W18F1010_FILTER_COMPLETE:
            return {
                ...state,
                dataFilter: action.dataFilter
            };
        case types.W18_W18F1010_DEL_COMPLETE:
            return {
                ...state,
                delW18F1010: action.delW18F1010
            };
        case types.W18F1010_COMPERTITOR_COMPLETE:
            return {
                ...state,
                loadCompertitorW18F1010: action.loadCompertitorW18F1010
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/06/03 14:00
 * @update 2019/06/03 14:00
 */

import * as types from '../actions/types.js';

const initialState = {
    dataGrid: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F0005_DATAGRID_COMPLETE:
            return {
                ...state,
                dataGrid: action.dataGrid
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW18F1021: null,
    comboGroupSalesW18F1021: null,
    comboSalesPersonW18F1021: null,
    comboTypeTimeW18F1021: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1021_LOAD_COMPLETE:
            return {
                ...state,
                loadW18F1021: action.loadW18F1021
            };
        case types.W18_W18F1021_GroupSales_COMPLETE:
            return {
                ...state,
                comboGroupSalesW18F1021: action.comboGroupSalesW18F1021
            };
        case types.W18_W18F1021_SalesPerson_COMPLETE:
            return {
                ...state,
                comboSalesPersonW18F1021: action.comboSalesPersonW18F1021
            };
        case types.W18_W18F1021_TypeTime_COMPLETE:
            return {
                ...state,
                comboTypeTimeW18F1021: action.comboTypeTimeW18F1021
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/routes/routes.js
 */

import React, {Component} from 'react';
import {browserHistory, IndexRoute, Route, Router} from 'react-router'
import App from '../container/app';
import Config from '../config';
import ModuleNotFound from '../components/not-found/not-found';
import NotPermission from '../components/not-permission/not-permission';
import DashboardPage from '../components/dashboard/dashboard';

import W17F0003Page from '../components/W17/W17F0003';
import W17F0005Page from '../components/W17/W17F0005';
import W17F0010Page from '../components/W17/W17F0010';
import W17F0020Page from '../components/W17/W17F0020';
import W17F0040Page from '../components/W17/W17F0040';
import W17F0043Page from '../components/W17/W17F0043';
import W17F0050Page from '../components/W17/W17F0050';
import W17F0051Page from '../components/W17/W17F0051';
import W17F1000Page from '../components/W17/W17F1000';
import W17F1025Page from '../components/W17/W17F1025/W17F1025';
import W17F1026Page from '../components/W17/W17F1026/W17F1026';
import W17F2025Page from '../components/W17/W17F2025/W17F2025';
import W17F2050Page from '../components/W17/W17F2050/W17F2050';
import W17F1510Page from '../components/W17/W17F1510';
import W17F1511Page from '../components/W17/W17F1511';
import W17F1555Page from '../components/W17/W17F1555';
import W17F1520Page from '../components/W17/W17F1520';
import W17F2000Page from '../components/W17/W17F2000';
import W17F2051Page from '../components/W17/W17F2051/W17F2051';
import W17F9000Page from '../components/W17/W17F9000';
import W17F2040Page from '../components/W17/W17F2040';
import W17F5560Page from '../components/W17/W17F5560/W17F5560';
import W17F5565Page from '../components/W17/W17F5565/W17F5565';

import W18F1000Page from '../components/W18/W18F1000';
import W18F1001Page from '../components/W18/W18F1001';
import W18F1002Page from '../components/W18/W18F1002';
import W18F1003Page from '../components/W18/W18F1003';
import W18F1010Page from '../components/W18/W18F1010';
import W18F1011Page from '../components/W18/W18F1011';
import W18F1012Page from '../components/W18/W18F1012';
import W18F1013Page from '../components/W18/W18F1013';
import W18F1016Page from '../components/W18/W18F1016';
import W18F1021Page from '../components/W18/W18F1021';
import W18F1022Page from '../components/W18/W18F1022';
import W18F1024Page from '../components/W18/W18F1024';
import W18F1025Page from '../components/W18/W18F1025';
import W18F1026Page from '../components/W18/W18F1026';
import W18F1030Page from '../components/W18/W18F1030';
import W18F1031Page from '../components/W18/W18F1031';
import W18F1040Page from '../components/W18/W18F1040';
import W18F1041Page from '../components/W18/W18F1041';
import W18F1050Page from '../components/W18/W18F1050';
import W18F1051Page from '../components/W18/W18F1051';
import W18F1060Page from "../components/W18/W18F1060";
import W18F1061Page from '../components/W18/W18F1061';
import W18F4000Page from '../components/W18/W18F4000';
import W18F4001Page from '../components/W18/W18F4001';
import W18F4030Page from '../components/W18/W18F4030';
import W18F4040Page from '../components/W18/W18F4040';
import W18F4050Page from '../components/W18/W18F4050';
import W18F4070Page from '../components/W18/W18F4070';
import W18F4080Page from '../components/W18/W18F4080';

import W19F1000Page from '../components/W19/W19F1000';
import W19F1001Page from '../components/W19/W19F1001';
import W19F1010Page from '../components/W19/W19F1010';
import W19F1020Page from '../components/W19/W19F1020';
import W19F1021Page from '../components/W19/W19F1021';
import W19F1030Page from '../components/W19/W19F1030';
import W19F1031Page from '../components/W19/W19F1031';
import W19F2000Page from '../components/W19/W19F2000';
import W19F2001Page from '../components/W19/W19F2001';

import W20F1000Page from '../components/W20/W20F1000';
import W20F1001Page from '../components/W20/W20F1001';
import W20F1002Page from '../components/W20/W20F1002';
import W20F1003Page from '../components/W20/W20F1003';

import W94F1000Page from '../components/W94/W94F1000';
import W94F1001Page from '../components/W94/W94F1001';
import W94F1002Page from '../components/W94/W94F1002';
import W17F1005Page from '../components/W17/W17F1005/W17F1005';


import ErrorPage from '../components/error-500/error-500';
import Report    from "../components/report/report";

let oldRouter = '';
class Routes extends Component {

    onRouteUpdate = (e) => {
        // ===== DEBUGGER =====
        if (oldRouter !== browserHistory.getCurrentLocation()?.pathname && Config?.debugger) {
            let dataDebuggerError = Config.debugger.dataDebuggerError;
            Config.debugger.setData(dataDebuggerError);
        }
        oldRouter = browserHistory.getCurrentLocation()?.pathname;
        // ===== DEBUGGER =====
    };

    render(){
        return (
            <Router onUpdate={this.onRouteUpdate}  history={browserHistory}>
                <Route ref={'router'} path={Config.getRootPath()} component={App}>
                    <IndexRoute component={DashboardPage}/>
                    <Route path={'dashboard'} component={DashboardPage}/>

                    {/************************ W17F0XXX ***************************/}
                    <Route path="W17F0003" component={W17F0003Page}/>
                    <Route path="W17F0005" component={W17F0005Page}/>
                    <Route path="W17F0010" component={W17F0010Page}/>
                    <Route path="W17F0020" component={W17F0020Page}/>
                    <Route path="W17F0040" component={W17F0040Page}/>
                    <Route path="W17F0043" component={W17F0043Page}/>
                    <Route path="W17F0050" component={W17F0050Page}/>
                    <Route path="W17F0051" component={W17F0051Page}/>
                    <Route path="W17F1520" component={W17F1520Page}/>


                    {/************************ W17F1XXX ***************************/}
                    <Route path="W17F1000" component={W17F1000Page}/>
                    <Route path="W17F1025" component={W17F1025Page}/>
                    <Route path="W17F1026" component={W17F1026Page}/>
                    <Route path="W17F1510" component={W17F1510Page}/>
                    <Route path="W17F1511" component={W17F1511Page}/>
                    <Route path="W17F1555" component={W17F1555Page}/>
                    <Route path="W17F1005" component={W17F1005Page}/>
                    {/************************ W17F2XXX ***************************/}
                    <Route path="W17F2025" component={W17F2025Page}/>

                    {/************************ W17F2XXX ***************************/}
                    <Route path="W17F2000" component={W17F2000Page}/>

                    {/************************ W17F2XXX ***************************/}
                    <Route path="W17F2040" component={W17F2040Page}/>

                    <Route path="W17F2050" component={W17F2050Page}/>
                    {/************************ W17F2XXX ***************************/}
                    <Route path="W17F2051" component={W17F2051Page}/>

                    {/************************ W17F9XXX ***************************/}
                    <Route path="W17F5560" component={W17F5560Page}/>
                    <Route path="W17F5565" component={W17F5565Page}/>

                    {/************************ W17F9XXX ***************************/}
                    <Route path="W17F9000" component={W17F9000Page}/>


                    {/************************ W18F1XXX ***************************/}
                    <Route path="W18F1000" component={W18F1000Page}/>
                    <Route path="W18F1001" component={W18F1001Page}/>
                    <Route path="W18F1002" component={W18F1002Page}/>
                    <Route path="W18F1003" component={W18F1003Page}/>
                    <Route path="W18F1010" component={W18F1010Page}/>
                    <Route path="W18F1011" component={W18F1011Page}/>
                    <Route path="W18F1012" component={W18F1012Page}/>
                    <Route path="W18F1013" component={W18F1013Page}/>
                    <Route path="W18F1016" component={W18F1016Page}/>
                    <Route path="W18F1021" component={W18F1021Page}/>
                    <Route path="W18F1022" component={W18F1022Page}/>
                    <Route path="W18F1024" component={W18F1024Page}/>
                    <Route path="W18F1025" component={W18F1025Page}/>
                    <Route path="W18F1026" component={W18F1026Page}/>
                    <Route path="W18F1030" component={W18F1030Page}/>
                    <Route path="W18F1031" component={W18F1031Page}/>
                    <Route path="W18F1040" component={W18F1040Page}/>
                    <Route path="W18F1041" component={W18F1041Page}/>
                    <Route path="W18F1050" component={W18F1050Page}/>
                    <Route path="W18F1051" component={W18F1051Page}/>
                    <Route path="W18F1061" component={W18F1061Page}/>
                    <Route path="W18F1060" component={W18F1060Page}/>

                    {/************************ W18F4XXX ***************************/}
                    <Route path="W18F4000" component={W18F4000Page}/>
                    <Route path="W18F4001" component={W18F4001Page}/>
                    <Route path="W18F4030" component={W18F4030Page}/>
                    <Route path="W18F4040" component={W18F4040Page}/>
                    <Route path="W18F4050" component={W18F4050Page}/>
                    <Route path="W18F4070" component={W18F4070Page}/>
                    <Route path="W18F4080" component={W18F4080Page}/>

                    {/************************ W19F1XXX ***************************/}
                    <Route path="W19F1000" component={W19F1000Page}/>
                    <Route path="W19F1001" component={W19F1001Page}/>
                    <Route path="W19F1010" component={W19F1010Page}/>
                    <Route path="W19F1020" component={W19F1020Page}/>
                    <Route path="W19F1021" component={W19F1021Page}/>
                    <Route path="W19F1030" component={W19F1030Page}/>
                    <Route path="W19F1031" component={W19F1031Page}/>

                    {/************************ W19F2XXX ***************************/}
                    <Route path="W19F2000" component={W19F2000Page}/>
                    <Route path="W19F2001" component={W19F2001Page}/>

                    {/************************ W20F1XXX ***************************/}
                    <Route path="W20F1000" component={W20F1000Page}/>
                    <Route path="W20F1001" component={W20F1001Page}/>
                    <Route path="W20F1002" component={W20F1002Page}/>
                    <Route path="W20F1003" component={W20F1003Page}/>

                    {/************************ W24f100X ***************************/}
                    <Route path="W94F1000" component={W94F1000Page}/>
                    <Route path="W94F1001" component={W94F1001Page}/>
                    <Route path="W94F1002" component={W94F1002Page}/>

                    {/************************ Report ***************************/}
                    <Route path="report" component={Report}/>

                    <Route path="error-page" component={ErrorPage}/>
                    <Route path="not-permission" component={NotPermission}/>
                    <Route path='*' exact={true} component={ModuleNotFound}/>
                </Route>
            </Router>
        );
    }
}

export default Routes;

/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/06/02 03:37
 * @update 2019/06/02 03:37
 * @file src/reducers/setting.js
 */

import * as types from '../actions/types.js';

const initialState = {
    listSetting: null,
    addSetting: null,
    editSetting: null,
    delSetting: null
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.SETTING_LIST_COMPLETE:
            return{
                ...state,
                listSetting: action.listSetting
            };
        case types.SETTING_ADD_COMPLETE:
            return{
                ...state,
                addSetting: action.addSetting
            };
        case types.SETTING_EDIT_COMPLETE:
            return{
                ...state,
                editSetting: action.editSetting
            };
        case types.SETTING_DELETE_COMPLETE:
            return{
                ...state,
                delSetting: action.delSetting
            };
        case types.SETTING_DELETE_ALL_COMPLETE:
            return{
                ...state,
                listSetting: {rows: [], total:0},
                delSetting: action.delSetting
            };

        default:
            return state;
    }
}
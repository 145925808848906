/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    listUser: null,
    getUser: null,
    addUser: null,
    editUser: null,
    delUser: null,
    isPermission: -1
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.USER_LIST_COMPLETE:
            return {
                ...state,
                listUser: action.listUser
            };
        case types.USER_GET_COMPLETE:
            return {
                ...state,
                getUser: action.getUser
            };
        case types.USER_ADD_COMPLETE:
            return {
                ...state,
                addUser: action.addUser
            };
        case types.USER_EDIT_COMPLETE:
            return {
                ...state,
                editUser: action.editUser
            };
        case types.USER_DELETE_COMPLETE:
            return {
                ...state,
                delUser: action.delUser
            };
        case types.USER_DELETE_ALL_COMPLETE:
            return {
                ...state,
                listUser: {rows: [], total:0},
                delUser: action.delUser
            };
        case types.USER_GET_PERMISSION_COMPLETE:
            return {
                ...state,
                isPermission: action.isPermission
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/17 15:37
 * @update 2019/10/17 15:37
 */

import * as types from '../actions/types.js';

const initialState = {
    dataGridCase: [],
    dataGridDelivery: [],
    //cbo
    cboCompanyType:[],
    cboCaseSource:[],
    cboIndustryGroup:[],
    cboCompanyGroup:[],
    cboCustomerLevel:[],
    cboGroupSales:[],
    cboSalesPerson:[],
    cboCountry:[],
    cboProvince:[],
    cboDistrict:[],
    cboWard:[],
    loadFormMaster:[],
    getRequestGrid:[]
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F1026_LOAD_CASETAB_COMPLETE:
            return {
                ...state,
                dataGridCase: action.dataGridCase
            };
        case types.W17F1026_LOAD_GRID_DELIVERY_COMPLETE:
            return {
                ...state,
                dataGridDelivery: action.dataGridDelivery
            };

        //cbo
        case types.W17F1026_LOAD_CBO_COMPANY_TYPE_COMPLETE:
            return {
                ...state,
                cboCompanyType: action.cboCompanyType
            };
        case types.W17F1026_LOAD_CBO_CASE_SOURCE_COMPLETE:
            return {
                ...state,
                cboCaseSource: action.cboCaseSource
            };
        case types.W17F1026_LOAD_CBO_INDUSTRY_GROUP_COMPLETE:
            return {
                ...state,
                cboIndustryGroup: action.cboIndustryGroup
            };
        case types.W17F1026_LOAD_CBO_COMPANY_GROUP_COMPLETE:
            return {
                ...state,
                cboCompanyGroup: action.cboCompanyGroup
            };
        case types.W17F1026_LOAD_CBO_CUSTOM_LEVEL_COMPLETE:
            return {
                ...state,
                cboCustomerLevel: action.cboCustomerLevel
            };
        case types.W17F1026_LOAD_CBO_GROUP_SALES_COMPLETE:
            return {
                ...state,
                cboGroupSales: action.cboGroupSales
            };
        case types.W17F1026_LOAD_CBO_SALES_PERSON_COMPLETE:
            return {
                ...state,
                cboSalesPerson: action.cboSalesPerson
            };
        case types.W17F1026_LOAD_CBO_COUNTRY_COMPLETE:
            return {
                ...state,
                cboCountry: action.cboCountry
            };
       
        case types.W17F1026_LOAD_CBO_PROVINCE_COMPLETE:
            return {
                ...state,
                cboProvince: action.cboProvince
            };
        case types.W17F1026_LOAD_CBO_DISTRICT_COMPLETE:
            return {
                ...state,
                cboDistrict: action.cboDistrict
            };
            case types.W17F1026_LOAD_CBO_WARD_COMPLETE:
                return {
                    ...state,
                    cboWard: action.cboWard
                };
        case types.W17F1026_LOAD_FORM_MASTER_COMPLETE:
            return {
                ...state,
                loadFormMaster: action.loadFormMaster
            };
        
        case types.W17F1026_GET_REQUEST_GRID_COMPLETE:
            return {
                ...state,
                getRequestGrid: action.getRequestGrid
            };
        
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/04/16 10:30
 * @update 2019/04/16 10:30
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW19F1010: null,
    comboGroupSalesW19F1010: null,
    comboYearW19F1010: null,
    saveW19F1010: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W19_W19F1010_LOAD_COMPLETE:
            return {
                ...state,
                loadW19F1010: action.loadW19F1010
            };
        case types.W19_W19F1010_CBGROUPSALE_COMPLETE:
        return {
            ...state,
            comboGroupSalesW19F1010: action.comboGroupSalesW19F1010
        };
        case types.W19_W19F1010_CBYEAR_COMPLETE:
            return {
                ...state,
                comboYearW19F1010: action.comboYearW19F1010
            };
        case types.W19_W19F1010_SAVE_COMPLETE:
            return {
                ...state,
                saveW19F1010: action.saveW19F1010
            };
        default:
            return state;
    }
}
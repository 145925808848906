/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    loadGroupSalesType:null,
    isRoot:null,

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F1001_GROUPSALESTYPE_COMPLETE:
            return {
                ...state,
                loadGroupSalesType: action.loadGroupSalesType
            };
        case types.W17_W17F1001_GROUPSALESPARENT_COMPLETE:
            return {
                ...state,
                dataGroupSalesParent: action.dataGroupSalesParent
            };
            case types.W17_W17F1001_CHECKISROOT:
            return {
                ...state,
                isRoot: action.isRoot
            };



        default:
            return state;
    }
}
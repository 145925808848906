/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    dataTreeList:null,
    orgListData:null,

};

export default function (state = initialState, action = {}) {

    switch (action.type) {
        case types.W17_W17F1000_DATATREELIST_COMPLETE:
            return {
                ...state,
                treeListData: action.treeListData
            };
            case types.W17_W17F1000_DATAORGLIST_COMPLETE:
            return {
                ...state,
                orgListData: action.orgListData
            };


        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/14 17:37
 * @update 2019/02/14 17:37
 */

import * as types from '../actions/types';

const initialState = {
    dataGrid: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1016_LOADGRID_COMPLETE:
            return {
                ...state,
                dataGrid: action.dataGrid
            };
        default:
            return state;
    }
}
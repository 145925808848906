/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW17F0003: null,
    loadComboW17F0003: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F0003_LOAD_COMPLETE:
            return {
                ...state,
                loadW17F0003: action.loadW17F0003
            };
        case types.W17_W17F0003_LOADCOMBO_COMPLETE:
            return {
                ...state,
                loadComboW17F0003: action.loadComboW17F0003
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/4/9
 * @update 2019/4/9
 */

import * as types from '../actions/types.js';
import _ from "lodash";

const initialState = {
    dataGrid: null,
    decimalScaleColumn: [],
    dataCboInventory: []
};

export default function (state = initialState, action = { }) {
    switch (action.type) {
        case types.W19_W19F2000_DATAGRID_COMPLETE:
            return {
                ...state,
                dataGrid: action.dataGrid
            };
        case types.W19_W19F2000_CBO_INVENTORY_COMPLETE:
            let dataCboInventory = [];
            if (!_.isEmpty(action.data)) {
                dataCboInventory = action.data.map(item => {
                    const line = !_.isEmpty(item.InventoryName) ? "-" : "";
                    return ({ ...item, value: item.InventoryID, customNameID: `${item.InventoryID} ${line} ${item.InventoryName}` })
                });
            }
            return { ...state, dataCboInventory };
        case types.W19_W19F2000_DECIMAL_COMPLETE:
            return { ...state, decimalScaleColumn: action.data };
        default:
            return state;
    }
}

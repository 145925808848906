/**
 * @copyright 2021 @ DigiNet
 * @author thanhhai
 * @create 2021/03/13
 * @update 2021/03/13
 */

import * as types from '../../actions/types.js';

const initialState = {
    
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F2050_LOAD_GRID_COMPLETE:
            return {
                ...state,
                loadGrid: action.data
            };
        
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/4/9
 * @update 2019/4/9
 */

import * as types from '../actions/types.js';

const initialState = {
    dataGrid: null,
};

export default function (state = initialState, action = {}) {
    if (action.type === types.W17_W17F0050_DATAGRID_COMPLETE) {
        return {
            ...state,
            dataGrid: action.dataGrid
        };
    } else {
        return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW18F1040: null,
    addW18F1040: null,
    editW18F1040: null,
    deleteW18F1040: null,
    loadW18F1041: null,
    addW18F1041: null,
    checkDeleteW18F1040: null,
    editW18F1041: null,
    deleteW18F1041: null,
    checkDeleteW18F1041: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1040_LOAD_COMPLETE:
            return {
                ...state,
                loadW18F1040: action.loadW18F1040
            };
            case types.W18_W18F1040_ADD_COMPLETE:
            return {
                ...state,
                addW18F1040: action.loadW18F1040
            };
        case types.W18_W18F1040_EDIT_COMPLETE:
            return {
                ...state,
                editW18F1040: action.loadW18F1040
            };
        case types.W18_W18F1040_DELETE_COMPLETE:
            return {
                ...state,
                deleteW18F1040: action.loadW18F1040
            };
        case types.W18_W18F1041_LOAD_COMPLETE:
            return {
                ...state,
                loadW18F1041: action.loadW18F1041
            };
        case types.W18_W18F1041_ADD_COMPLETE:
            return {
                ...state,
                addW18F1041: action.addW18F1041
            };
        case types.W18_W18F1040_CHECKDELETE_COMPLETE:
            return {
                ...state,
                checkDeleteW18F1040: action.checkDeleteW18F1040
            };
        case types.W18_W18F1041_EDIT_COMPLETE:
            return {
                ...state,
                editW18F1041: action.editW18F1041
            };
        case types.W18_W18F1041_DELETE_COMPLETE:
            return {
                ...state,
                deleteW18F1041: action.deleteW18F1041
            };
        case types.W18_W18F1041_CHECKDELETE_COMPLETE:
            return {
                ...state,
                checkDeleteW18F1041: action.checkDeleteW18F1041
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author tranhoang
 * @create 2019/06/0315:37
 * @file src/reducers/dash-board.js
 */

import * as types from '../actions/types.js';

const initialState = {
    listDefaultFilter: null,
    popOverFilter: null,
    isManager: null,

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.LIST_DEFAULT_FILTER_ADVANCED_SEARCH:
            return {
                ...state,
                listDefaultFilter: action.listDefaultFilter
            };
        case types.POPOVER_FILTER_DETAIL_ADVANCED_SEARCH:
            return {
                ...state,
                popOverFilter: action.popOverFilter
            };
        case types.CHECK_MANAGER_ADVANCED_SEARCH:
            return {
                ...state,
                isManager: action.isManager
            };

        default:
            return state;
    }
}
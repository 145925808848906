/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/25/03 10:35
 * @update 2019/25/03 10:35
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW17F0010: null,
    editW17F0010: null,
    loadFieldRequiredW17F0010: null,
    dataCondition: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F0010_LOAD_COMPLETE:
            return {
                ...state,
                loadW17F0010: action.loadW17F0010
            };
        case types.W17_W17F0010_LOAD_FIELDEQUIRED_COMPLETE:
            return {
                ...state,
                loadFieldRequiredW17F0010: action.loadFieldRequiredW17F0010
            };
        case types.W17_W17F0010_EDIT_COMPLETE:
            return {
                ...state,
                editW17F0010: action.editW17F0010
            };
        case types.W17_W17F0010_NUMDATECHANGE_COMPLETE:
            return {
                ...state,
                numDateChange: action.numDateChange
            };
        case types.W17_W17F0010_CONDITION_COMPLETE:
            return {
                ...state,
                condition: action.condition
            };
        case types.W17_W17F0010_DATACONDITION_COMPLETE:
            return {
                ...state,
                dataCondition: action.dataCondition
            };
        default:
            return state;
    }
}
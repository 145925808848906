/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 03/11/2020
 */

import * as types from '../actions/types.js';

const initialState = {
    dataGroupList: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W94_W94F1000_GET_GROUP_LIST_COMPLETE:
            return {
                ...state,
                dataGroupList: action.data
            };
        // case types.W94_W94F1000_GET_GROUP_LIST_COMPLETE:
        //     return {
        //         ...state,
        //         loadMaster: action.loadMaster
        //     };
        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/17 15:37
 * @update 2019/10/17 15:37
 */

import * as types from '../actions/types.js';

const initialState = {
    dataGridCase: [],
    dataGridDelivery: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1012_LOAD_CASETAB_COMPLETE:
            return {
                ...state,
                dataGridCase: action.dataGridCase
            };
        case types.W18_W18F1012_LOAD_GRID_DELIVERY_COMPLETE:
            return {
                ...state,
                dataGridDelivery: action.dataGridDelivery
            };
        default:
            return state;
    }
}
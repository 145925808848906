/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from '../actions/types.js';

const initialState = {
    listReport: null,
    updateW17F0043: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F0043_LOAD_REPORT_LIST_COMPLETE:
            return {
                ...state,
                listReport: action.listReport
            };
        case types.W17F0043_UPDATE_COMPLETE:
            return {
                ...state,
                updateW17F0043: action.updateW17F0043
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/24/2021
 * @Example
 */

import * as types from '../../actions/types.js';

const initialState = {
    dataCboInventoryType: [],
    dataCboInventory: [],
    dataCboTypeCode: [],
    dataCboCode: [],
    dataGrid: [],
    dataColumns: [],
    dataCboWarehouse: [],
    dataQuantityDecimal: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F5560_GET_CBO_INVENTORY_TYPE_COMPLETE:
            return {
                ...state,
                dataCboInventoryType: action.data?.map(i => ({...i, label: i.InventoryTypeName, value: i.InventoryTypeID}))
            };
        case types.W17F5560_GET_CBO_INVENTORY_COMPLETE:
            return {
                ...state,
                dataCboInventory: action.data?.map(i => ({...i, label: i.InventoryName, value: i.InventoryID}))
            };
        case types.W17F5560_GET_CBO_TYPECODE_COMPLETE:
            return {
                ...state,
                dataCboTypeCode: action.data?.map(i => ({...i, label: i.TypeCodeName, value: i.TypeCodeID}))
            };
        case types.W17F5560_GET_CBO_CODE_COMPLETE:
            return {
                ...state,
                dataCboCode: action.data?.map(i => ({...i, label: i.ICodeName, value: i.ICodeID}))
            };
        case types.W17F5560_GET_FORM_COMPLETE:
            return {
                ...state,
                dataGrid: action.data
            };
        case types.W17F5560_GET_COLUMNS_COMPLETE:
            return {
                ...state,
                dataColumns: action.data
            };
        case types.W17F5560_GET_CBO_WAREHOUSE_COMPLETE:
            return {
                ...state,
                dataCboWarehouse: action.data?.map(i => ({...i, label: i.WareHouseName, value: i.WareHouseID}))
            };
        case types.W17F5560_GET_QUANTITY_DECIMAL_COMPLETE:
            return {
                ...state,
                dataQuantityDecimal: action.data
            };

        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    cboContactW18F1014: null,
    cboPriorityW18F1014: null,
    cboTaskStatusW18F1014: null,
    cboTaskTypeW18F1014: null,
    dataMasterW18F1014: null,
    dataAttachedW18F1014: null,

};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1014_CBOCONTACT_COMPLETE:
            return {
                ...state,
                cboContactW18F1014: action.cboContactW18F1014
            };
        case types.W18_W18F1014_CBOPRIORITY_COMPLETE:
            return {
                ...state,
                cboPriorityW18F1014: action.cboPriorityW18F1014
            };
        case types.W18_W18F1014_CBOTASKSTATUS_COMPLETE:
            return {
                ...state,
                cboTaskStatusW18F1014: action.cboTaskStatusW18F1014
            };
        case types.W18_W18F1014_CBOTASKTYPE_COMPLETE:
            return {
                ...state,
                cboTaskTypeW18F1014: action.cboTaskTypeW18F1014
            };
        case types.W18_W18F1014_DATA_MASTER_COMPLETE:
            return {
                ...state,
                dataMasterW18F1014: action.dataMasterW18F1014
            };
        case types.W18_W18F1014_DATAATTACH_COMPLETE:
            return {
                ...state,
                dataAttachedW18F1014: action.dataAttachedW18F1014
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/template-dropdown.js
 */

import * as types from '../actions/types.js';

const initialState = {
    cboReportTemplate: null,
    cboSalePerson: null,
    cboTaskType: null,
    cboTypeTime: null,
    cboReportType: null,
    cboCustomer: null,
    cboArea: null,
    cboCustomerLevel: null,
    cboCompanyStatus: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.TEMPLATE_DROPDOWN_REPORTTEMPLATE_COMPLETE:
            return {
                ...state,
                cboReportTemplate: action.cboReportTemplate
            };
        case types.TEMPLATE_DROPDOWN_SALEPERSON_COMPLETE:
            return {
                ...state,
                cboSalePerson: action.cboSalePerson
            };
        case types.TEMPLATE_DROPDOWN_TYPETIME_COMPLETE:
            return {
                ...state,
                cboTypeTime: action.cboTypeTime
            };
        case types.TEMPLATE_DROPDOWN_TASKTYPE_COMPLETE:
            return {
                ...state,
                cboTaskType: action.cboTaskType
            };
        case types.TEMPLATE_DROPDOWN_REPORTTYPE_COMPLETE:
            return {
                ...state,
                cboReportType: action.cboReportType
            };
        case types.TEMPLATE_DROPDOWN_AREATEMPLATE_COMPLETE:
            return {
                ...state,
                cboArea: action.cboArea
            };
        case types.TEMPLATE_DROPDOWN_CUSTOMERLEVEL_COMPLETE:
            return {
                ...state,
                cboCustomerLevel: action.cboCustomerLevel
            };
        case types.TEMPLATE_DROPDOWN_COMPANYSTATUS_COMPLETE:
            return {
                ...state,
                cboCompanyStatus: action.cboCompanyStatus
            };

        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/17 15:37
 * @update 2019/10/17 15:37
 */

import * as types from '../../actions/types.js';

const initialState = {
    //cbo
    cboStatus:[],
    cboCaseSource:[],
    cboSalesPerson:[],
    cboCollaborator:[],
    getForm: {},


};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F2025_GET_CBO_STATUS_COMPLETE:{
            return {
                ...state,
                cboStatus: action.data
            };
        }
        case types.W17F2025_GET_CBO_SALES_PERSON_COMPLETE:{
            return {
                ...state,
                cboSalesPerson: action.data
            };
        }
        case types.W17F2025_GET_CBO_CASE_SOURCE_COMPLETE:{
            return {
                ...state,
                cboCaseSource: action.data
            };
        }
        case types.W17F2025_GET_CBO_COLLABORATOR_COMPLETE:{
            return {
                ...state,
                cboCollaborator: action.data
            };
        }
        case types.W17F2025_GET_FORM_COMPLETE:{
            return {
                ...state,
                getForm: action.data
            };
        }
            
        
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW18F1002: null,
    loadW18F1010: null,
    loadW18F1013: null,
    addW18F1013: null,
    editW18F1013: null,
    dataW18F1012: null,
    dataMasterW18F1012: null,
    gridDataW18F1012: null,
    cboCompanyStatusW18F1012: null,
    cboGroupSalesW18F1011: null,
    cboCaseSourceW18F1011: null,
    cboSalesPersonW18F1011: null,
    dataGridW18F1011: null,
    cboWardW18F1011: null,
    cboDistrictW18F1011: null,
    cboProvinceW18F1011: null,
    loadW18F1030: null,
    cboContactW18F1014: null,
    cboPriorityW18F1014: null,
    cboTaskStatusW18F1014: null,
    cboTaskTypeW18F1014: null,
    deleteW18F1031: null,
    cboCompanySizeW18F1011: null,
    cboRevenueW18F1011: null,
    checkStoreW18F1030: null,
    cboCompetitorW18F1011: null,
    cboPartnerW18F1011: null,
    deleteW18F1000: null,
    getCompanyNameW18F1011: null,
    addW18F1001: null,
    editW18F1001: null,
    loadQuantityW18F1030: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1014_CBOCONTACT_COMPLETE:
            return {
                ...state,
                cboContactW18F1014: action.cboContactW18F1014
            };
        case types.W18_W18F1012_ACTION_TAB_COMPLETE:
            return {
                ...state,
                actionTabDataW18F1012: action.actionTabDataW18F1012
            };
        case types.W18_W18F1014_CBOPRIORITY_COMPLETE:
            return {
                ...state,
                cboPriorityW18F1014: action.cboPriorityW18F1014
            };
        case types.W18_W18F1014_CBOTASKSTATUS_COMPLETE:
            return {
                ...state,
                cboTaskStatusW18F1014: action.cboTaskStatusW18F1014
            };
        case types.W18_W18F1014_CBOTASKTYPE_COMPLETE:
            return {
                ...state,
                cboTaskTypeW18F1014: action.cboTaskTypeW18F1014
            };

        case types.W18_W18F1011_CBODATA_COMPLETE:
            return {
                ...state,
                cboDataW18F1011: action.cboDataW18F1011
            };
        case types.W18_W18F1011_CBOCOMPANYSIZE_COMPLETE:
            return {
                ...state,
                cboCompanySizeW18F1011: action.cboCompanySizeW18F1011
            };
        case types.W18_W18F1011_CBONORM_COMPLETE:
            return {
                ...state,
                cboNormW18F1011: action.cboNormW18F1011
            };
        case types.W18_W18F1011_CBOREVENUE_COMPLETE:
            return {
                ...state,
                cboRevenueW18F1011: action.cboRevenueW18F1011
            };
        case types.W18_W18F1011_CBOCUSTOMERLEVEL_COMPLETE:
            return {
                ...state,
                cboCustomerLevelW18F1011: action.cboCustomerLevelW18F1011
            };
        case types.W18_W18F1011_GRID_COMPLETE:
            return {
                ...state,
                dataGridW18F1011: action.dataGridW18F1011
            };
        case types.W18_W18F1011_CBOWARD_COMPLETE:
            return {
                ...state,
                cboWardW18F1011: action.cboWardW18F1011
            };
        case types.W18_W18F1011_CBODISTRICT_COMPLETE:
            return {
                ...state,
                cboDistrictW18F1011: action.cboDistrictW18F1011
            };
        case types.W18_W18F1011_CBOPROVINCE_COMPLETE:
            return {
                ...state,
                cboProvinceW18F1011: action.cboProvinceW18F1011
            };
        case types.W18_W18F1011_CBOINDUTRYGROUP_COMPLETE:
            return {
                ...state,
                cboIndutryGroupW18F1011: action.cboIndutryGroupW18F1011
            };
        case types.W18_W18F1011_CBOSALESPERSON_COMPLETE:
            return {
                ...state,
                cboSalesPersonW18F1011: action.cboSalesPersonW18F1011
            };
        case types.W18_W18F1011_DATA_MASTER_COMPLETE:
            return {
                ...state,
                dataMasterW18F1011: action.dataMasterW18F1011
            };
        case types.W18_W18F1011_CBOCASESOURCE_COMPLETE:
            return {
                ...state,
                cboCaseSourceW18F1011: action.cboCaseSourceW18F1011
            };
        case types.W18_W18F1011_CBOCOMPANYSTATUS_COMPLETE:
            return {
                ...state,
                cboCompanyStatusW18F1011: action.cboCompanyStatusW18F1011
            };
        case types.W18_W18F1011_CBOCOMPANYGROUP_COMPLETE:
            return {
                ...state,
                cboCompanyGroupW18F1011: action.cboCompanyGroupW18F1011
            };
        case types.W18_W18F1011_CBOGROUPSALES_COMPLETE:
            return {
                ...state,
                cboGroupSalesW18F1011: action.cboGroupSalesW18F1011
            };
        case types.W18_W18F1002_COMPLETE:
            return {
                ...state,
                loadW18F1002: action.loadW18F1002
            };
        case types.W18_W18F1010_COMPLETE:
            return {
                ...state,
                loadW18F1010: action.loadW18F1010
            };
        case types.W18_W18F1012_COMPLETE:
            return {
                ...state,
                dataMasterW18F1012: action.dataMasterW18F1012
            };
        case types.W18_W18F1012_GRID_COMPLETE:
            return {
                ...state,
                gridDataW18F1012: action.gridDataW18F1012
            };
        case types.W18_W18F1013_COMPLETE:
            return {
                ...state,
                loadW18F1013: action.loadW18F1013
            };
        case types.W18F1013_ADD_COMPLETE:
            return {
                ...state,
                addW18F1013: action.addW18F1013
            };
        case types.W18F1013_EDIT_COMPLETE:
            return {
                ...state,
                editW18F1013: action.editW18F1013
            };
        case types.W18_W18F1000_COMPLETE:
            return {
                ...state,
                loadW18F1000: action.loadW18F1000
            };
        case types.W18_W18F1030_COMPLETE:
            return {
                ...state,
                loadW18F1030: action.loadW18F1030
            };
        case types.W18_W18F1031_DELETE_COMPLETE:
            return {
                ...state,
                deleteW18F1031: action.deleteW18F1031
            };
        case types.CHECKING_STOREW18F1030_COMPLETE:
            return {
                ...state,
                checkStoreW18F1030: action.checkStoreW18F1030
            };
        case types.W18_W18F1011_CBOCOMPETITOR_COMPLETE:
            return {
                ...state,
                cboCompetitorW18F1011: action.cboCompetitorW18F1011
            };
        case types.W18_W18F1011_CBOPARTNER_COMPLETE:
            return {
                ...state,
                cboPartnerW18F1011: action.cboPartnerW18F1011
            };
        case types.W18_W18F1000_DELETE_COMPLETE:
            return {
                ...state,
                deleteW18F1000: action.deleteW18F1000
            };
        case types.W18_W18F1011_COMPANYNAME_COMPLETE:
            return {
                ...state,
                getCompanyNameW18F1011: action.getCompanyNameW18F1011
            };
        case types.W18_W18F1001_ADD_COMPLETE:
            return {
                ...state,
                addW18F1001: action.addW18F1001
            };
        case types.W18_W18F1001_EDIT_COMPLETE:
            return {
                ...state,
                editW18F1001: action.editW18F1001
            };
        case types.W18_W18F1030_QUANTITY_COMPLETE:
            return {
                ...state,
                loadQuantityW18F1030: action.loadQuantityW18F1030
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/09/20 11:00
 * @update 22019/09/20 11:00
 */

import * as types from '../actions/types.js';

const initialState = {
    loadCBStatus: null,
    loadMaster: null,
    loadTotal: null,
    loadDetail: null,
    dataCboGender: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W20_W20F1000_CB_STATUS_COMPLETE:
            return {
                ...state,
                loadCBStatus: action.loadCBStatus
            };
        case types.W20_W20F1000_LOAD_MASTER_COMPLETE:
            return {
                ...state,
                loadMaster: action.loadMaster
            };
        case types.W20_W20F1000_LOAD_TOTAL_COMPLETE:
            return {
                ...state,
                loadTotal: action.loadTotal
            };
        case types.W20_W20F1000_LOAD_DETAIL_COMPLETE:
            return {
                ...state,
                loadDetail: action.loadDetail
            };
        case types.W20_W20F1000_CBO_GENDER_COMPLETE:
            return {
                ...state,
                dataCboGender: action.dataCboGender
            };
        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    listNotify: null,
    listMenu: null,
    loadW17F1555: null,
    loadW17F1555_Combo: null,
    deleteW17F1555: null,
    actionW17F1556: null,
    checkStore: null,
    loadW17F0001: null,
    editW17F0001: null,
    addW17F0001: null,
    delW17F0001: null,
    getW17F0001: null,
    checkEditW17F1555: null,
    checkDeleteW17F1555: null,
    checkAddW17F1556: null,
    dataW17F1026: null,
    dataMasterW17F1026: null,
    gridDataW17F1026: null,
    cboCompanyStatusW17F1026: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_LIST_NOTIFY_COMPLETE:
            return {
                ...state,
                listNotify: action.listNotify
            };
        case types.W17_LIST_MENU_COMPLETE:
            return {
                ...state,
                listMenu: action.listMenu
            };
        case types.W17_W17F1555_GRID_COMPLETE:
            return {
                ...state,
                loadW17F1555: action.loadW17F1555
            };
        case types.W17_W17F1555_COMBO_COMPLETE:
            return {
                ...state,
                loadW17F1555_Combo: action.loadW17F1555_Combo
            };
        case types.W17_W17F1556_DELETE_COMPLETE:
            return {
                ...state,
                deleteW17F1555: action.deleteW17F1555
            };
        case types.W17_W17F1556_ACTION_COMPLETE:
            return {
                ...state,
                actionW17F1556: action.actionW17F1556
            };
        case types.CHECKING_STORE_COMPLETE:
            return {
                ...state,
                checkStore: action.checkStore
            };
        case types.W17_W17F0001_GRID_COMPLETE:
            return {
                ...state,
                loadW17F0001: action.loadW17F0001
            };
        case types.W17_W17F0001_DELETE_COMPLETE:
            return {
                ...state,
                delW17F0001: action.delW17F0001
            };
        case types.W17_W17F0001_EDIT_COMPLETE:
            return {
                ...state,
                editW17F0001: action.editW17F0001
            };
        case types.W17_W17F0001_ADD_COMPLETE:
            return {
                ...state,
                addW17F0001: action.addW17F0001
            };
        case types.W17_W17F0001_GET_COMPLETE:
            return {
                ...state,
                getW17F0001: action.getW17F0001
            };
        case types.W17_W17F1555_CHECKEDIT_COMPLETE:
            return {
                ...state,
                checkEditW17F1555: action.checkEditW17F1555
            };
        case types.W17_W17F1555_CHECKDELETE_COMPLETE:
            return {
                ...state,
                checkDeleteW17F1555: action.checkDeleteW17F1555
            };
        case types.W17_W17F1556_CHECKADD_COMPLETE:
            return {
                ...state,
                checkAddW17F1556: action.checkAddW17F1556
            };
        case types.W17F1026_ACTION_TAB_COMPLETE:
            return {
                ...state,
                actionTabDataW17F1026: action.actionTabDataW17F1026
            };
        case types.W17F1026_COMPLETE:
            return {
                ...state,
                dataMasterW17F1026: action.dataMasterW17F1026
            };
        case types.W17F1026_GRID_COMPLETE:
            return {
                ...state,
                gridDataW17F1026: action.gridDataW17F1026
            };
        default:
            return state;
    }
}
export const localize = {
    "vi": {
        "CRM_403_loi": "Bạn không có quyền truy cập để xem trang này",
        "CRM_404_loi": "Trang bạn đang tìm kiếm có thể đã bị xóa, có tên thay đổi hoặc tạm thời không có.",
        "CRM_AC_vui_long_chon_y_kien_cua_minh": "Anh/Chị vui lòng chọn ý kiến của mình tương ứng với mức độ đồng ý cho những dịch vụ cung cấp dưới đây",
        "CRM_Ap": "Ấp",
        "CRM_Ap_Khu_pho": "Ấp/Khu phố",
        "CRM_App": "Quản trị mối quan hệ khách hàng",
        "CRM_Ban_chua_chon_chu_so_huu": "Bạn chưa chọn chủ sở hữu 4",
        "CRM_Ban_chua_chon_hang_hoa": "Bạn chưa chọn hàng hóa",
        "CRM_Ban_chua_chon_khach_hang": "Bạn chưa chọn khách hàng",
        "CRM_Ban_chua_chon_lien_he": "Bạn chưa chọn liên hệ",
        "CRM_Ban_chua_chon_mau_bao_cao": "Bạn chưa chọn mẫu báo cáo",
        "CRM_Ban_chua_chon_ngay_den": "Bạn chưa chọn ngày đến",
        "CRM_Ban_chua_chon_nhom_hang": "Bạn chưa chọn nhóm hàng",
        "CRM_Ban_chua_chon_truong_thiet_lap_bat_buoc_nhap": "Bạn chưa chọn thường thiết lập bắt buộc nhập",
        "CRM_Ban_chua_co_thong_bao_nao": "Bạn chưa có thông báo nào",
        "CRM_Ban_chua_nhap_chi_tieu_doanh_so": "Bạn chưa nhập chỉ tiêu doanh số",
        "CRM_Ban_chua_nhap_chu_de_cua_Email": "Bạn chưa nhập chủ đề của Email!",
        "CRM_Ban_chua_nhap_Email_Bcc": "Bạn chưa nhập Email Bcc!",
        "CRM_Ban_chua_nhap_Email_Cc": "Bạn chưa nhập Email Cc!",
        "CRM_Ban_chua_nhap_Email_nguoi_nhan": "Bạn chưa nhập Email người nhận!",
        "CRM_Ban_chua_nhap_gia_tri": "Bạn chưa nhập giá trị",
        "CRM_Ban_chua_nhap_noi_dung_Email": "Bạn chưa nhập nội dung Email!",
        "CRM_Ban_chua_nhap_truong:_%p_Ban_co_muon_luu_khong?": "Bạn chưa nhập trường: %p Bạn có muốn lưu không?",
        "CRM_Ban_chua_thiet_lap_Mau_bao_cao": "Bạn chưa thiết lập Mẫu báo cáo.",
        "CRM_Ban_co": "Bạn có",
        "CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay": "Bạn có chắc chắn muốn sửa dữ liệu này",
        "CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "Bạn có chắc chắn muốn xóa dữ liệu này ?",
        "CRM_Ban_co_muon_chia_se_bao_gia_nay": "Bạn có muốn chia sẻ báo giá này",
        "CRM_Ban_co_muon_dong_bao gia": "Bạn có muốn đóng báo giá",
        "CRM_Ban_co_muon_mo_lai_bao_gia": "Bạn có muốn mở lại báo giá",
        "CRM_Ban_co_muon_nhap_tiep_khong": "Bạn có muốn nhập tiếp không?",
        "CRM_Ban_co_muon_xoa_quyen_cua_nhan_vien": "Bạn có muốn xóa quyền của nhân viên",
        "CRM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "Bạn đã đăng nhập sai quá số lần cho phép, vui lòng chờ %p để đăng nhập lại",
        "CRM_Ban_giao": "Bàn giao",
        "CRM_Ban_giao_cho": "Bàn giao cho",
        "CRM_Ban_khong_co_quyen_truy_cap": "Bạn không có quyền truy cập.",
        "CRM_Ban_khong_co_quyen_truy_cap_Module_nay": "Bạn không có quyền truy cập Module này",
        "CRM_Ban_phai_chon_doanh_nghiep": "Bạn phải chọn doanh nghiệp",
        "CRM_Ban_phai_chon_don_vi_ban": "Bạn phải chọn đơn vị bán",
        "CRM_Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang": "Bạn phải chọn thời gian không tái ký đặt hàng",
        "CRM_Ban_phai_chuyen_khach_hang_thanh_doi_tuong": "Bạn phải chuyển khách hàng thành đối tượng",
        "CRM_Bang_bao_gia": "Bảng báo giá",
        "CRM_Bang_gia": "Bảng giá",
        "CRM_Bang_gia_khong_duoc_de_trong": "Bảng giá không được để trống",
        "CRM_Bao_cao": "Báo cáo",
        "CRM_Bao_cao_chi_tiet_cong_viec": "Báo cáo chi tiết công việc",
        "CRM_Bao_cao_hien_thi": "Báo cáo hiển thị",
        "CRM_Bao_cao_hoat_dong": "Báo cáo hoạt động",
        "CRM_Bao_cao_hoat_dong_theo_khach_hang": "Báo cáo hoạt động theo khách hàng",
        "CRM_Bao_cao_khach_hang_theo_ma_hang": "Báo cáo đơn hàng theo mã hàng",
        "CRM_Bao_cao_phan_tich_tien_thu_ban_hang": "Báo cáo phân tích tiền thu bán hàng",
        "CRM_Bao_cao_so_lieu_hoat_dong": "Báo cáo số liệu hoạt động",
        "CRM_Bao_cao_thong_ke_khach_hang": "Báo cáo thống kê khách hàng",
        "CRM_Bao_gia": "Báo giá",
        "CRM_Bao_gia_da_duyet": "Báo giá đã duyệt, cần thương lượng từ chối gấp với khách hàng đã yêu cầu Mẫu thử. Mẫu thử tốn nhiều chi phí, nếu khách hàng đồng ý chi trả phí thì OK",
        "CRM_Bao_gia_duoc_dong": "Báo giá được đóng",
        "CRM_Bat_buoc_nhap": "Bắt buộc nhập",
        "CRM_Bo_duyet": "Bỏ duyệt",
        "CRM_Bo_duyet_thanh_cong": "Bỏ duyệt thành công.",
        "CRM_Bo_phan": "Bộ phận",
        "CRM_Boi": "Bởi",
        "CRM_Ca_nam": "Cả năm",
        "CRM_Ca_nhan": "Cá nhân",
        "CRM_Cac_chi_tieu_DK_A": "Các chỉ tiêu ĐK A",
        "CRM_Cac_chi_tieu_DK_B": "Các chỉ tiêu ĐK B",
        "CRM_Cac_gop_y_khac": "Các góp ý khác",
        "CRM_Cac_tham_so_la_bat_buoc": "Các tham số là bắt buộc",
        "CRM_Cac_thong_so_luong": "Các thông số lương",
        "CRM_Cac_tieu_chi_la_bat_buoc_nhap": "Các chỉ tiêu bắt buộc nhập",
        "CRM_Cai_dat": "Cài đặt",
        "CRM_Canh_bao_nhap": "Cảnh báo nhập",
        "CRM_Cap_co_cau": "Cấp cơ cấu",
        "CRM_Cap_do": "Cấp độ",
        "CRM_Cap_nhat_danh_gia": "Cập nhật đánh giá chung",
        "CRM_Cap_nhat_danh_muc": "Cập nhật danh mục",
        "CRM_Cap_nhat_danh_sach_khach_hang": "Cập nhật danh sách khách hàng",
        "CRM_Cap_nhat_dia_ban": "Cập nhật địa bàn",
        "CRM_Cap_nhat_ghi_nhan_nhu_cau_khach_hang": "Cập nhật ghi nhận nhu cầu khách hàng",
        "CRM_Cap_nhat_hoat_dong": "Cập nhật hoạt động",
        "CRM_Cap_nhat_lien_he": "Cập nhật liên hệ",
        "CRM_Cap_nhat_loai_danh_gia": "Cập nhật loại đánh giá",
        "CRM_Cap_nhat_loai_tinh_huong": "Cập nhật Loại tình huống",
        "CRM_Cap_nhat_ma_hang": "Cập nhật mã hàng",
        "CRM_Cap_nhat_mau_bao_cao": "Cập nhật mẫu báo cáo",
        "CRM_Cap_nhat_ngon_ngu": "Cập nhật ngôn ngữ",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh": "&Cập nhật nhân viên kinh doanh",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh1": "Cập nhật nhân viên kinh doanh",
        "CRM_Cap_nhat_nhom_khach_hang": "Cập nhật nhóm khách hàng",
        "CRM_Cap_nhat_thong_tin_bo_sung": "Cập nhật thông tin bổ sung",
        "CRM_Cap_nhat_thong_tin_giao_hang": "Cập nhật thông tin giao hàng",
        "CRM_Cap_nhat_tinh_huong": "Cập nhật tình huống",
        "CRM_Cap_nhat_trang_thai": "Cập nhật trạng thái",
        "CRM_Cap_nhat_trang_thai_KH": "Cập nhật trạng thái khách hàng",
        "CRM_Cap_nhat_trang_thai_thuc_hien_DH": "Cập nhật trạng thái thực hiện ĐH",
        "CRM_Chap_nhan_don_hang": "Chấp nhận đơn hàng",
        "CRM_Chi_nhanh_ngan_hang": "Chi nhánh ngân hàng",
        "CRM_Chi_tiet": "Chi tiết",
        "CRM_Chi_tiet_bao_gia": "Chi tiết báo giá",
        "CRM_Chi_tiet_don_hang": "Chi tiết đơn hàng",
        "CRM_Chi_tiet_loi": "Chi tiết lỗi",
        "CRM_Chi_tiet_nguoi_lien_he": "Chi tiết người liên hệ",
        "CRM_Chi_tiet_theo": "Chi tiết theo",
        "CRM_Chi_tieu": "Chỉ tiêu",
        "CRM_Chi_tieu_chi_tiet_vuot_tong_chi_tieu_cua_nhom": "Chỉ tiêu chi tiết vượt tổng chỉ tiêu của nhóm",
        "CRM_Chi_tieu_doanh_so": "Chỉ tiêu doanh số",
        "CRM_Chia_se": "Chia sẻ",
        "CRM_Chia_se_doi_tac": "Chia sẻ đối tác",
        "CRM_Chia_se_doi_thu": "Chia sẻ đối thủ",
        "CRM_Chia_se_khach_hang": "Chia sẻ khách hàng",
        "CRM_Chiet_khau": "Chiết khấu",
        "CRM_Chiet_khau_khong_duoc_lon_hon": "Chiết khấu không được lớn hơn",
        "CRM_Chinh_sua_khong_thanh_cong": "Chỉnh sửa không thành công",
        "CRM_Chinh_sua_thanh_cong": "Chỉnh sửa thành công",
        "CRM_Cho_duyet": "Chờ duyệt",
        "CRM_Chon": "Chọn",
        "CRM_Chon_combo": "-- Chọn --",
        "CRM_Chon_gia_tri": "Chọn giá trị",
        "CRM_Chon_hang": "Chọn hàng",
        "CRM_Chon_hang_hoa": "Chọn hàng hóa",
        "CRM_Chon_hinh": "Chọn hình",
        "CRM_Chon_kenh": "Chọn kênh",
        "CRM_Chon_khach_hang": "Chọn khách hàng",
        "CRM_Chon_loai_tinh_huong": "Chọn loại tình huống",
        "CRM_Chon_mau_bao_cao": "Chọn mẫu báo cáo",
        "CRM_Chon_nguoi_giai_quyet": "Chọn người giải quyết",
        "CRM_Chon_nhom_hang": "Chọn nhóm hàng",
        "CRM_Chon_tap_tin": "Chọn tập tin",
        "CRM_Chon_tat_ca": "Chọn tất cả",
        "CRM_Chon_tinh": "Chọn tỉnh",
        "CRM_Chon_trang_thai": "Chọn trạng thái",
        "CRM_Chua_co_lien_ket_nao": "Chưa có liên kết nào",
        "CRM_Chua_dap_ung": "Chưa đáp ứng",
        "CRM_Chua_doc": "Chưa đọc",
        "CRM_Chua_thuc_hien": "Chưa thực hiện",
        "CRM_Chuc_danh": "Chức danh",
        "CRM_Chuc_nang_chua_phat_trien": "Chức năng chưa phát triển",
        "CRM_Chuc_nang_dang_duoc_phat_trien": "Chức năng đang được phát triển",
        "CRM_Chuc_nang_them_moi": "Chức năng thêm mới",
        "CRM_Chuc_vu": "Chức vụ",
        "CRM_Chuc_vu_bat_buoc_nhap": "Chức vụ bắt buộc nhập",
        "CRM_Chuong_trinh_cham_soc_khach_hang": "Chương trình chăm sóc khách hàng",
        "CRM_Chuyen_chu_so_huu": "Chuyển chủ sở hữu",
        "CRM_Chuyen_doi_trang_thai": "Chuyển đổi trạng thái",
        "CRM_CMR_Khong_tim_thay_trang": "KHÔNG TÌM THẤY TRANG",
        "CRM_Co_cau_cha": "Cơ cấu cha",
        "CRM_Co_cau_kinh_doanh": "Cơ cấu kinh doanh",
        "CRM_Co_cau_to_chuc": "Cơ cấu tổ chức",
        "CRM_Co_it_nhat_mot_chu_cai": "Có ít nhất 1 chữ cái (a-z)",
        "CRM_Co_it_nhat_mot_chu_cai_in_hoa": "Có ít nhất 1 chữ cái in hoa (A-Z)",
        "CRM_Co_it_nhat_mot_so": "Có ít nhất 1 số (0-9)",
        "CRM_Co_it_nhat_sau_ky_tu": "Có ít nhất 6 ký tự",
        "CRM_Co_loi_khi_luu": "Có lỗi khi lưu",
        "CRM_Co_loi_khi_luu_lich_su": "Có lỗi khi lưu lịch sử",
        "CRM_Co_loi_khi_tim_kiem": "Có lỗi khi tìm kiếm",
        "CRM_Co_loi_khi_xoa": "Có lỗi khi xóa",
        "CRM_Co_loi_khi_xoa_dinh_kem": "Có lỗi khi xóa đính kèm",
        "CRM_Co_loi_khi_xoa_file_bao_cao": "Có lỗi khi xóa file báo cáo",
        "CRM_Co_loi_trong_qua_trinh_gui_Mail": "Có lỗi trong quá trình gửi Mail.",
        "CRM_Co_loi_trong_qua_trinh_luu_File": "Có lỗi trong quá trình lưu File.",
        "CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu": "Có lỗi trong quá trình xử lý dữ liệu",
        "CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "Có lỗi xảy ra trong quá trình xử lý.",
        "CRM_Co1": "Có",
        "CRM_Cong_tac_vien": "Cộng tác viên",
        "CRM_Cong_thuc_ton_tai_chi_tieu_chia_cho_0": "Công thức tồn tại chỉ tiêu chia cho 0",
        "CRM_Cong_trinh/Cong_ty": "Công trình/Công ty",
        "CRM_Cong_ty": "Công ty",
        "CRM_Cong_ty_cong_tac": "Công ty công tác",
        "CRM_Cong_viec": "Công việc",
        "CRM_Cot_gia_tri": "Cột giá trị",
        "CRM_Cua_bo_phan": "Của bộ phận",
        "CRM_Cua_hang_hoa": "Của hàng hóa",
        "CRM_cua_khach_hang": " của khách hàng ",
        "CRM_Cua_toi": "Của tôi",
        "CRM_Da_co_mat_hang": "Đã có mặt hàng",
        "CRM_Da_dong": "Đã đóng",
        "CRM_Da_duyet": "Đã duyệt",
        "CRM_Da_hoan_thanh": "Đã hoàn thành",
        "CRM_Da_nghi_viec": "Đã nghỉ việc",
        "CRM_Dang_cong_tac": "Đang công tác",
        "CRM_Dang_duyet": "Đang duyệt",
        "CRM_Dang_Lam_Viec": "Đang làm việc",
        "CRM_Dang_nhap": "Đăng nhập",
        "CRM_dang_nhap_lai": "đăng nhập lại",
        "CRM_Dang_nhap_lai2": "Bạn đã đăng nhập tài khoản trên một thiết bị khác. Vui lòng đăng nhập lại.",
        "CRM_Dang_tai": "Đang tải",
        "CRM_Dang_thuc_hien": "Đang thực hiện",
        "CRM_Dang_xuat": "Dạng xuất",
        "CRM_Dang_xuat1": "Đăng xuất",
        "CRM_Danh_dau_tat_ca_la_da_doc": "Đánh dầu tất cả là đã đọc",
        "CRM_Danh_gia": "Đánh giá",
        "CRM_Danh_muc/Phan_loai_khach_hang": "Danh mục/Phân loại khách hàng",
        "CRM_Danh_muc_danh_gia": "Danh mục đánh giá",
        "CRM_Danh_muc_Loai_tinh_huong": "Danh mục Loại tình huống",
        "CRM_Danh_muc_trang_thai_lam_viec": "Danh mục trạng thái làm việc",
        "CRM_Danh_sach": "Danh sách",
        "CRM_Danh_sach_bao_cao": "Danh sách báo cáo",
        "CRM_Danh_sach_bao_gia": "Danh sách báo giá",
        "CRM_Danh_sach_bo_loc": "Danh sách bộ lọc",
        "CRM_Danh_sach_chuong_trinh_cham_soc": "Danh sách chương trình chăm sóc",
        "CRM_Danh_sach_cong_tac_vien": "Danh sách cộng tác viên",
        "CRM_Danh_sach_danh_gia_chung": "Danh sách đánh giá chung",
        "CRM_Danh_sach_dia_ban": "Danh sách địa bàn",
        "CRM_Danh_sach_doi_tac": "Danh sách đối tác",
        "CRM_Danh_sach_doi_thu": "Danh sách đối thủ",
        "CRM_Danh_sach_don_hang": "Danh sách đơn hàng",
        "CRM_Danh_sach_file_dinh_kem": "Danh sách file đính kèm",
        "CRM_Danh_sach_khach_hang": "Danh sách khách hàng",
        "CRM_Danh_sach_khach_hang_dung_chung": "Danh sách khách hàng dùng chung",
        "CRM_Danh_sach_lich_su_chia_se_va_chuyen_chu_so_huu": "Danh sách lịch sử chia sẻ và chuyển chủ sở hữu",
        "CRM_Danh_sach_lich_su_gui_mail": "Danh sách lịch sử gửi mail",
        "CRM_Danh_sach_lich_su_thay_doi": "Danh sách lịch sử thay đổi",
        "CRM_Danh_sach_lien_he": "Danh sách liên hệ",
        "CRM_Danh_sach_mat_hang": "Danh sách mặt hàng",
        "CRM_Danh_sach_nhan_vien_kinh_doanh": "Danh sách nhân viên kinh doanh",
        "CRM_Danh_sach_sinh_nhat_cua_nguoi_lien_he": "Danh sách sinh nhật của người liên hệ",
        "CRM_Danh_sach_thong_tin_bo_sung": "Danh sách thông tin bổ sung",
        "CRM_Danh_sach_tinh_huong": "Danh sách tình huống",
        "CRM_Dap_ung": "Đáp ứng",
        "CRM_Date_input_format_invalid": "Ngày nhập không hợp lệ",
        "CRM_Den": "Đến",
        "CRM_Den_ngay": "Đến ngày",
        "CRM_DG_goc": "ĐG gốc",
        "CRM_DG_truoc_thue": "ĐG trước thuế",
        "CRM_Di_dong": "Di động",
        "CRM_Dia_ban": "Địa bàn",
        "CRM_Dia_chi": "Địa chỉ",
        "CRM_Dia_chi_DKKD": "Địa chỉ ĐKKD",
        "CRM_Dia_chi_doi_tuong_thue": "Địa chỉ đối tượng thuế",
        "CRM_Dia_chi_giao_dich": "Địa chỉ giao dịch",
        "CRM_Dia_chi_giao_hang": "Địa chỉ giao hàng",
        "CRM_Dia_chi_IP": "Địa chỉ IP",
        "CRM_Dia_chi_khach_hang": "Địa chỉ khách hàng",
        "CRM_Dia_chi_khong_duoc_de_trong": "Địa chỉ không được để trống",
        "CRM_Dia_chi_lien_he": "Địa chỉ liên hệ",
        "CRM_Dia_chi_thuong_tru": "Địa chỉ thường trú",
        "CRM_Dia_diem": "Địa điểm",
        "CRM_Dia_diem_giao_dich": "Địa điểm giao dịch",
        "CRM_Dia_diem_giao_hang": "Địa điểm giao hàng",
        "CRM_Dien_dai": "Diễn giải",
        "CRM_Dien_giai": "Diễn giải",
        "CRM_Dien_thoai": "Điện thoại",
        "CRM_Dien_thoai_cong_ty": "Điện thoại công ty",
        "CRM_Dien_thoai_di_dong": "Điện thoại di động",
        "CRM_Dieu_chinh": "Điều chỉnh",
        "CRM_Dieu_khoan_thuong_mai": "Điều khoản thương mại",
        "CRM_Dieu_khoan_TM": "Điều khoản TM",
        "CRM_Dieu_kien": "Điều kiện",
        "CRM_Dieu_kien_loc": "Điều kiện lọc",
        "CRM_Dieu_kien_loc_chi_tiet": "Điều kiện lọc chi tiết",
        "CRM_Dieu_kien_loc_khong_hop_le": "Điều kiện lọc không hợp lệ",
        "CRM_Dieu_kien_loc_mac_dinh": "Điều kiện lọc mặc định",
        "CRM_Dinh_dang_email_khong_hop_le": "Định dạng email không hợp lệ",
        "CRM_Dinh_dang_file_khong_hop_le": "Định dạng file không hợp lệ",
        "CRM_Dinh_kem": "Đính kèm",
        "CRM_Dinh_kem_hinh_anh": "Đính kèm hình ảnh",
        "CRM_Do_dai_mat_khau_khong_hop_le": "Độ dài mật khẩu không hợp lệ",
        "CRM_Do_uu_tien": "Độ ưu tiên",
        "CRM_Doanh_so": "Doanh số",
        "CRM_Doanh_thu": "Doanh thu",
        "CRM_Doanh_thu_nam": "Doanh thu năm",
        "CRM_Doi_mat_khau": "Đổi mật khẩu",
        "CRM_Doi_mat_khau_thanh_cong": "Đổi mật khẩu thành công",
        "CRM_Doi_tac": "Công ty",
        "CRM_Doi_thu": "Đối thủ",
        "CRM_Doi_tuong": "Đối tượng",
        "CRM_Doi_tuong_ap_dung": "Đối tượng áp dụng",
        "CRM_Doi_tuong_QLCN": "Đối tượng QLCN",
        "CRM_Doi_tuong_thue": "Đối tượng thuế",
        "CRM_Don_gia": "Đơn giá",
        "CRM_Don_gia_ban": "Đơn giá bán",
        "CRM_Don_gia_sau_DC": "Đơn giá sau ĐC",
        "CRM_Don_gia_sau_thue": "Đơn giá sau thuế",
        "CRM_Don_gia_truoc_thue": "Đơn giá trước thuế",
        "CRM_Don_hang": "Đơn hàng",
        "CRM_Don_vi": "Đơn vị",
        "CRM_Don_vi_ban": "Đơn vị bán",
        "CRM_Don_vi_tinh": "Đơn vị tính",
        "CRM_Dong_bao_gia": "Đóng báo giá",
        "CRM_Dong_bao_gia_thanh_cong": "Đóng báo giá thành công",
        "CRM_Dong_chuong_trinh": "Đóng chương trình",
        "CRM_Dong_khong_thanh_cong": "Đóng không thành công",
        "CRM_Dong_y": "Đồng ý",
        "CRM_Dong1": "Đóng",
        "CRM_Du_co_cuoi_ky": "Dư có cuối kỳ",
        "CRM_Du_co_dau_ky": "Dư có đầu kỳ",
        "CRM_Du_lieu": "Dữ liệu",
        "CRM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Dữ liệu chưa được lưu. Bạn có muốn lưu không?",
        "CRM_Du_lieu_co_thay_doi_ban_co_muon_luu_khong": "Dữ liệu có thay đổi. Bạn có muốn lưu không?",
        "CRM_Du_lieu_da_duoc_import_dang_o_trang_thai_Chua_duyet_hoac_Tu_choi_Ban_co_muon_tiep_tuc_khong": "Dữ liệu đã được import đang ở trạng thái Chưa duyệt hoặc Từ chối. Bạn có muốn tiếp tục không?",
        "CRM_Du_lieu_da_duoc_su_dung": "Dữ liệu đã được sử dụng",
        "CRM_Du_lieu_da_xoa_thanh_cong": "Dữ liệu đã xóa thành công",
        "CRM_Du_lieu_dinh_kem": "Dữ liệu đính kèm",
        "CRM_Du_lieu_import_bi_loi": "Dữ liệu import bị lỗi",
        "CRM_Du_lieu_tham_chieu": "Dữ liệu tham chiếu",
        "CRM_Du_no_cuoi_ky": "Dư nợ cuối kỳ",
        "CRM_Du_no_dau_ky": "Dư nợ đầu kỳ",
        "CRM_Dung_luong": "Dung lượng",
        "CRM_Dung_luong_File_khong_duoc_lon_hon": "Dung lượng File không được lớn hơn",
        "CRM_Duong_dan": "Đường dẫn",
        "CRM_Duyet": "Duyệt",
        "CRM_Duyet_thanh_cong": "Duyệt thành công",
        "CRM_DVT": "ĐVT",
        "CRM_Email": "Email",
        "CRM_Email_ca_nhan": "Email cá nhân",
        "CRM_Email_cong_ty": "Email công ty",
        "CRM_Email_nguoi_gui": "Email người gửi",
        "CRM_Email_nguoi_nhan": "Email người nhận",
        "CRM_Facebook": "Facebook",
        "CRM_File_Import_khong_hop_le": "File Import không hợp lệ!",
        "CRM_Ghi_chu": "Ghi chú",
        "CRM_Ghi_chu_duyet": "Ghi chú duyệt",
        "CRM_Ghi_chu_KH": "Ghi chú KH",
        "CRM_Ghi_chu_noi_bo": "Ghi chú nội bộ",
        "CRM_Ghi_nhan_nhu_cau": "Ghi nhận nhu cầu",
        "CRM_Gia_goc": "Giá gốc",
        "CRM_Gia_tri_bat_buoc_nhap": "Giá trị bắt buộc nhập!",
        "CRM_Gia_tri_khong_hop_le": "Giá trị không hợp lệ",
        "CRM_Giai_phap": "Giải pháp",
        "CRM_Giam_dan": "Giảm dần",
        "CRM_Giao_hang": "Giao hàng",
        "CRM_Giay": "Giây",
        "CRM_Giay_CN_DKKD": "Giấy CN ĐKKD",
        "CRM_Gioi_tinh": "Giới tính",
        "CRM_Gioi_tinh_khong_duoc_de_trong": "Giới tính không được để trống",
        "CRM_Gui": "Gửi",
        "CRM_Gui_mail": "Gửi mail",
        "CRM_Gui_thanh_cong": "Gửi thành công",
        "CRM_Hai_long_can_cai_tien_them": "Hài lòng cần cải tiến thêm",
        "CRM_Han_ket_thuc": "Hạn kết thúc",
        "CRM_Han_xu_ly": "Hạn xử lý",
        "CRM_Han_xu_ly_khong_duoc_de_trong": "Hạn xử lý phải không được để trống",
        "CRM_Han_xu_ly_phai_lon_hon_ngay_bat_dau": "Hạn xử lý phải lớn hơn ngày bắt đầu",
        "CRM_Hang_hoa": "Hàng hóa",
        "CRM_Hang_hoa_da_chon": "Hàng hóa đã chọn",
        "CRM_Hang_hoa_nay_da_ton_tai": "Hàng hóa này đã tồn tại",
        "CRM_Hanh_dong": "Hành động",
        "CRM_He_thong": "Hệ thống",
        "CRM_He_thong_dang_trong_qua_trinh_nang_cap": "Hệ thống đang trong quá trình nâng cấp.",
        "CRM_Het_dong": "Hết dòng",
        "CRM_Hien_thi_chi_tiet": "Hiển thị chi tiết",
        "CRM_Hien_thi_chi_tieu_da_huy": "Hiển thị chỉ tiêu đã hủy",
        "CRM_Hien_thi_danh_sach_khong_su_dung": "Hiển thị danh sách không sử dụng",
        "CRM_Hien_thi_khong_su_dung": "Hiển thị không sử dụng",
        "CRM_Hien_thi_ngung_hoat_dong": "Hiển thị ngừng hoạt động",
        "CRM_Hien_thi_so_luong_bang_0": "Hiển thị số lượng bằng 0",
        "CRM_Hien_thi_voi_moi_nguoi": "Hiển thị với mọi người",
        "CRM_Hieu_luc_den": "Hiệu lực đến",
        "CRM_Hieu_luc_den_phai_lon_hon_hieu_luc_tu": "Hiệu lực đến phải lớn hơn hiệu lực từ",
        "CRM_Hieu_luc_tu": "Hiệu lực từ",
        "CRM_Ho_chieu": "Hộ chiếu",
        "CRM_Ho_ten": "Họ tên",
        "CRM_Ho_va_ten": "Họ và tên",
        "CRM_Ho_va_ten_khong_duoc_de_trong": "Họ và tên không được để trống",
        "CRM_Ho_va_ten_nguoi_lien_he": "Họ và tên người liên hệ",
        "CRM_Hoan_toan_hai_long": "Hoàn toàn hài lòng",
        "CRM_Hoat_dong": "Hoạt động",
        "CRM_Hoat_dong_cua_toi": "Hoạt động của tôi",
        "CRM_Hom_nay": "Hôm nay",
        "CRM_Huy": "Hủy",
        "CRM_Huy_bo": "Hủy bỏ",
        "CRM_Huyen": "Huyện",
        "CRM_Import_bao_cao": "Import báo cáo",
        "CRM_Import_du_lieu": "Impo&rt dữ liệu",
        "CRM_In": "&In",
        "CRM_In1": "In",
        "CRM_Kenh_gop_y": "Kênh góp ý",
        "CRM_Kenh_truyen_thong": "Kênh truyền thông",
        "CRM_Kenh_truyen_thong_khong_duoc_de_trong": "Kênh truyền thông không được để trống",
        "CRM_Ket_qua": "Kết quả",
        "CRM_Khac": "Khác",
        "CRM_Khach_hang": "Khách hàng",
        "CRM_Khach_hang_da_phat_sinh_doat_dong_ban_khong_duoc_phep_xoa": "Khách hàng đã phát sinh hoạt động. Bạn không được phép xóa.",
        "CRM_Khach_hang_khong_duoc_de_trong": "Khách hàng không được để trống",
        "CRM_Khach_hang_la_nguoi_lien_he": "khách hàng là người liên hệ",
        "CRM_Khach_hang_va_lien_he": "Khách hàng và liên hệ",
        "CRM_Khao_sat_y_kien_khach_hang": "Khảo sát ý kiến khách hàng",
        "CRM_Kho": "Kho",
        "CRM_Kho_xuat": "Kho xuất",
        "CRM_Khoi_la_bat_buoc_nhap": "Khối bắt buộc nhập",
        "CRM_Khong": "Không",
        "CRM_khong_co_du_lieu": "Không có dữ liệu",
        "CRM_Khong_co_hang_hoa_moi_nao_duoc_chon": "Không có hàng hóa mới nào được chọn ",
        "CRM_Khong_co_thong_bao": "Không có thông báo",
        "CRM_Khong_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "CRM_Khong_duoc_de_trong": "Không được để trống",
        "CRM_Khong_duoc_lon_hon_ngay_den": "Không được lớn hơn ngày đến",
        "CRM_Khong_duoc_nho_hon_ngay_tu": "Không được nhỏ hơn ngày từ",
        "CRM_Khong_luu": "Không lưu",
        "CRM_Khong_su_dung": "Không sử dụng",
        "CRM_Khong_tai_ky_dat_hang": "Không tái ký đặt hàng",
        "CRM_Khong_tim_thay_du_lieu": "Không tìm thấy dữ liệu",
        "CRM_Khong_tim_thay_EOFFICE_URL": "Không tìm thấy EOFFICE URL",
        "CRM_Khong_tim_thay_trang": "KHÔNG TÌM THẤY TRANG",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_phai": "Không tồn tại mã chỉ tiêu ở công thức phải",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_trai": "Không tồn tại mã chỉ tiêu ở công thức trái",
        "CRM_Khong_xet1": "Không xét",
        "CRM_Khu_pho": "Khu phố",
        "CRM_Khu_vuc": "Khu vực",
        "CRM_Khu_vuc_nay_da_co_nhan_vien_so_huu": "Khu vực này đã có nhân viên sở hữu",
        "CRM_Kich_thuoc_cua_kien_nghi_vuot_qua_gioi_han_cho_phep": "Kích thước của kiến nghị vượt quá giới hạn cho phép",
        "CRM_Kich_thuoc_cua_noi_dung_vuot_qua_gioi_han_cho_phep": "Kích thước của nội dung vượt quá giới hạn cho phép",
        "CRM_Kich_thuoc_vuot_qua_gioi_han_cho_phep": "Kích thước vượt quá giới hạn cho phép",
        "CRM_Kiem_tra": "Kiểm tra",
        "CRM_Kien_nghi": "Kiến nghị",
        "CRM_Kieu_du_lieu": "Kiểu dữ liệu",
        "CRM_Kieu_nhap_lieu": "Kiểu nhập liệu",
        "CRM_Kieu_nhap_lieu_khong_duoc_de_trong": "Kiểu nhập liệu không được để trống",
        "CRM_KSD": "KSD",
        "CRM_Ky_bao_cao": "Kỳ báo cáo",
        "CRM_Ky_chua_import": "Kỳ chưa import",
        "CRM_Ky_import": "Kỳ import",
        "CRM_Ky_niem_ngay_thanh_lap": "Kỷ niệm ngày thành lập",
        "CRM_La_cong_tac_vien": "Là Cộng tác viên",
        "CRM_La_danh_sach": "Là danh sách",
        "CRM_La_goc": "Là gốc",
        "CRM_Lap_bao_gia": "Lập báo giá",
        "CRM_Lap_don_hang": "Lập đơn hàng",
        "CRM_Lich_su": "Lịch sử",
        "CRM_Lich_su_chia_se_va_chuyen_chu_so_huu": "Lịch sử chia sẻ và chuyển chủ sở hữu",
        "CRM_Lich_su_chinh_sua": "Lịch sử chỉnh sửa",
        "CRM_Lich_su_Import": "Lịch sử Import",
        "CRM_Lich_su_tac_dong": "Lịch sử tác động",
        "CRM_Lich_su_thay_doi": "Lịch sử thay đổi",
        "CRM_Lien_he": "Liên hệ",
        "CRM_Lien_he_chinh": "Liên hệ chính",
        "CRM_Loai": "Loại",
        "CRM_Loai_bao_cao": "Loại báo cáo",
        "CRM_Loai_chia_se": "Loại chia sẻ",
        "CRM_Loai_cong_viec": "Loại công việc",
        "CRM_Loai_cong_viec_khong_duoc_de_trong": "Loại công việc không được để trống",
        "CRM_Loai_danh_gia": "Loại đánh giá",
        "CRM_Loai_danh_gia_khong_duoc_de_trong": "Loại đánh giá không được để trống",
        "CRM_Loai_dieu_kien": "Loại điều kiện",
        "CRM_Loai_doi_tuong_ap_dung": "Loại đối tượng áp dụng",
        "CRM_Loai_hang": "Loại hàng",
        "CRM_Loai_hang_hoa": "Loại hàng hóa",
        "CRM_Loai_hoat_dong": "Loại hoạt động",
        "CRM_Loai_mau": "Loại mẫu",
        "CRM_Loai_quy_trinh": "Loại quy trình",
        "CRM_Loai_thong_tin_khong_duoc_de_trong": "Loại thông tin không được để trống",
        "CRM_Loai_thong_tin_quan_ly": "Loại thông tin quản lý",
        "CRM_Loai_thong_tin_tham_chieu": "Loại thông tin tham chiếu",
        "CRM_Loai_tien": "Loại tiền",
        "CRM_Loai_tinh_huong": "Loại tình huống",
        "CRM_Loc": "Lọc",
        "CRM_Loc_chon_hanh_dong": "Lọc chọn hành động",
        "CRM_Loi_chua_xac_dinh": "Lỗi chưa xác định",
        "CRM_Loi_dieu_kien_loc": "Lỗi điều kiện lọc",
        "CRM_Loi_khong_lay_duoc_token_CDN": "Lỗi không lấy được CDN token",
        "CRM_Luu": "Lưu",
        "CRM_Luu_lich_su_khong_thanh_cong": "Lưu lịch sử không thành công",
        "CRM_Luu_mat_khau": "Lưu mật khẩu",
        "CRM_Luu_quyen_thanh_cong": "Lưu quyền thành công",
        "CRM_Luu_thanh_cong": "Lưu thành công",
        "CRM_Luu_thong_tin_chung_tu_khong_thanh_cong": "Lưu thông tin chứng từ không thành công",
        "CRM_Luu_va_nhap_tiep": "Lưu và Nhập tiếp",
        "CRM_Ly_do_chuyen": "Lý do chuyển",
        "CRM_Ly_do_dong": "Lý do đóng",
        "CRM_Ly_do_dong_bao_gia": "Lý do đóng báo giá",
        "CRM_Ma": "Mã",
        "CRM_Ma_403": "Mã  403",
        "CRM_Ma_bao_cao": "Mã báo cáo",
        "CRM_Ma_chi_tieu": "Mã chỉ tiêu",
        "CRM_Ma_co_cau": "Mã cơ cấu",
        "CRM_Ma_co_ky_tu_khong_hop_le": "Mã có ký tự không hợp lệ.",
        "CRM_Ma_CTV": "Mã CTV",
        "CRM_Ma_da_ton_tai": "Mã đã tồn tại",
        "CRM_Ma_danh_gia_khong_ton_tai": "Mã đánh giá không tồn tại",
        "CRM_Ma_danh_sach": "Mã danh sách",
        "CRM_Ma_dia_ban": "Mã địa bàn",
        "CRM_Ma_dia_ban_khong_duoc_de_trong": "Mã địa bàn không được để trống",
        "CRM_Ma_doi_tac": "Mã công ty",
        "CRM_Ma_doi_thu": "Mã đối thủ",
        "CRM_Ma_doi_tuong": "Mã đối tượng",
        "CRM_Ma_hang": "Mã hàng",
        "CRM_Ma_hang_hoa": "Mã hàng hóa",
        "CRM_Ma_khach_hang": "Mã khách hàng",
        "CRM_Ma_kho": "Mã kho",
        "CRM_Ma_khong_duoc_de_trong": "Mã không được để trống",
        "CRM_Ma_kinh_doanh_da_ton_tai": "Mã kinh doanh đã tồn tại",
        "CRM_Ma_kinh_doanh_khong_duoc_chua_ky_tu_dac_biet": "Mã kinh doanh không được chứa ký tự đặc biệt",
        "CRM_Ma_lien_he": "Mã liên hệ",
        "CRM_Ma_loai_danh_gia": "Mã loại đánh giá",
        "CRM_Ma_loai_danh_gia_khong_duoc_de_trong": "Mã loại đánh giá không được để trống",
        "CRM_Ma_loai_dieu_kien": "Mã loại điều kiện",
        "CRM_Ma_loai_khong_duoc_chua_ky_tu_dac_biet": "Mã loại không được chứa ký tự đặc biệt",
        "CRM_Ma_loai_khong_duoc_de_trong": "Mã loại không được để trống",
        "CRM_Ma_loai_thong_tin": "Mã loại thông tin",
        "CRM_Ma_loai_tinh_huong": "Mã loại tình huống",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa": "Mã này đã được sử dụng bạn không được phép xóa!",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_phep_xoa": "Mã này đã được sử dụng bạn không được phép xóa!",
        "CRM_Ma_nay_da_ton_tai": "Mã này đã tồn tại",
        "CRM_Ma_nhan_vien": "Mã nhân viên",
        "CRM_Ma_nhan_vien_khong_duoc_chua_ky_tu_dac_biet": "Mã nhân viên không được chứa ký tự đặc biệt",
        "CRM_Ma_nhan_vien_khong_duoc_de_trong": "Mã nhân viên không được để trống",
        "CRM_Ma_nhan_vien_nay_da_ton_tai": "Mã nhân viên này đã tồn tại",
        "CRM_Ma_nhom_hang": "Mã nhóm hàng",
        "CRM_Ma_nhom_kinh_doanh": "Mã nhóm kinh doanh",
        "CRM_Ma_nhom_kinh_doanh_khong_duoc_de_trong": "Mã nhóm kinh doanh không được để trống",
        "CRM_Ma_nhu_cau": "Mã nhu cầu",
        "CRM_Ma_NVKD": "Mã NVKD",
        "CRM_Ma_so_thue": "Mã số thuế",
        "CRM_Ma_Ten": "Mã/ Tên",
        "CRM_Ma_tinh_huong": "Mã tình huống",
        "CRM_Ma_tinh_huong_khong_duoc_de_trong": "Mã tình huống không được để trống",
        "CRM_Mac_dinh": "Mặc định",
        "CRM_Mat_hang": "Mặt hàng",
        "CRM_Mat_hang_chu_luc": "Mặt hàng chủ lực",
        "CRM_Mat_khau": "Mật khẩu",
        "CRM_Mat_khau_cu": "Mật khẩu cũ",
        "CRM_Mat_khau_cu_khong_dung": "Mật khẩu cũ không đúng",
        "CRM_Mat_khau_khong_duoc_de_trong": "Mật khẩu không được để trống.",
        "CRM_Mat_khau_moi": "Mật khẩu mới",
        "CRM_Mat_khau_moi_khong_hop_le": "Mật khẩu mới không hợp lệ",
        "CRM_Mat_khau_xac_nhan_khong_dung": "Mật khẩu xác nhận không đúng",
        "CRM_Mau_bao_cao": "Mẫu báo cáo",
        "CRM_Mau_bao_cao_khong_ton_tai": "Mẫu báo cáo không tồn tại",
        "CRM_Mau_dinh_kem": "Mẫu đính kèm",
        "CRM_Mau_giao_dien": "Màu giao diện",
        "CRM_Mau_sac": "Màu sắc",
        "CRM_Mo": "Mỏ",
        "CRM_Mo_chuong_trinh": "Mở chương trình",
        "CRM_Mo_ta": "Mô tả",
        "CRM_Mo_ta_san_pham": "Mô tả sản phẩm",
        "CRM_MST": "MST",
        "CRM_Muc_do_dong_y": "Mức độ đồng ý",
        "CRM_Nam": "Năm",
        "CRM_Nam_thang": "Năm",
        "CRM_Nam1": "Nam",
        "CRM_Ngay": "Ngày",
        "CRM_Ngay_bao_gia": "Ngày báo giá",
        "CRM_Ngay_bao_gia_khong_duoc_de_trong": "Ngày báo giá không được để trống",
        "CRM_Ngay_bat_dau": "Ngày bắt đầu",
        "CRM_Ngay_bat_dau_khong_duoc_lon_hon_ngay_ket_thuc": "Ngày bắt đầu không được lớn hơn ngày kết thúc",
        "CRM_Ngay_cap": "Ngày cấp",
        "CRM_Ngay_cap_CMND": "Ngày cấp CMND",
        "CRM_Ngay_cap_ho_chieu": "Ngày cấp hộ chiếu",
        "CRM_Ngay_cap_nhat": "Ngày cập nhật",
        "CRM_Ngay_chinh_sua_cuoi": "Ngày chỉnh sủa cuối",
        "CRM_Ngay_chung_tuU": "Ngày chứng từ",
        "CRM_Ngay_chuyen": "Ngày chuyển",
        "CRM_Ngay_danh_gia": "Ngày đánh giá",
        "CRM_Ngay_danh_gia_bat_buoc_nhap": "Ngày đánh giá bắt buộc nhập",
        "CRM_Ngay_danh_gia_khong_duoc_de_trong": "Ngày đánh giá không được để trống",
        "CRM_Ngay_dao_han": "Ngày đáo hạn",
        "CRM_Ngay_den": "Ngày đến",
        "CRM_Ngay_den_phai_lon_hon_ngay_tu": "Ngày đến phải lớn hơn ngày từ",
        "CRM_Ngay_dong": "Ngày đóng",
        "CRM_Ngay_dong_khong_duoc_nho_hon_ngay_hieu_luc": "Ngày đóng không được nhỏ hơn ngày hiệu lực",
        "CRM_Ngay_duyet": "Ngày duyệt",
        "CRM_Ngay_giao_hang": "Ngày giao hàng",
        "CRM_Ngay_gio": "Ngày giờ",
        "CRM_Ngay_gui": "Ngày gửi",
        "CRM_Ngay_het_han": "Ngày hết hạn",
        "CRM_Ngay_het_hieu_luc": "Ngày hết hiệu lực",
        "CRM_Ngay_het_hieu_luc_phai_lon_hon_ngay_hieu_luc": "Ngày hết hiệu lực phải lớn hơn ngày hiệu lực",
        "CRM_Ngay_hieu_luc": "Ngày hiệu lực",
        "CRM_Ngay_hieu_luc_khong_duoc_de_trong": "Ngày hiệu lực không được để trống",
        "CRM_Ngay_hoan_thanh": "Ngày hoàn thành",
        "CRM_Ngay_hoan_thanh_khong_duoc_de_trong": "Ngày hoàn thành không được để trống",
        "CRM_Ngay_hoan_thanh_phai_lon_hon_ngay_bat_dau": "Ngày hoàn thành phải lớn hơn ngày bắt đầu",
        "CRM_Ngay_ket_thuc": "Ngày kết thúc",
        "CRM_Ngay_ket_thuc_phai_nho_hon_ngay_bat_dau": "Ngày kết thúc phải nhỏ hơn ngày bắt đầu",
        "CRM_Ngay_lap": "Ngày lập",
        "CRM_Ngay_nghi_viec": "Ngày nghỉ việc",
        "CRM_Ngay_nhan": "Ngày nhận",
        "CRM_Ngay_phat_sinh": "Ngày phát sinh",
        "CRM_Ngay_phieu": "Ngày phiếu",
        "CRM_Ngay_sinh": "Ngày sinh",
        "CRM_Ngay_sua": "Ngày sửa",
        "CRM_Ngay_tac_dong": "Ngày tác động",
        "CRM_Ngay_tao": "Ngày tạo",
        "CRM_Ngay_tao_khach_hang": "Ngày tạo khách hàng",
        "CRM_Ngay_thanh_lap": "Ngày thành lập",
        "CRM_Ngay_them": "Ngày thêm",
        "CRM_Ngay_tinh_no": "Ngày tính nợ",
        "CRM_Ngay_tu": "Ngày từ",
        "CRM_Ngay_tu/Ngay_den_bat_buoc_nhap": "Ngày từ/Ngày đến bắt buộc nhập",
        "CRM_Nghiep_vu": "Nghiệp vụ",
        "CRM_Ngung_hoat_dong": "Ngừng hoạt dộng",
        "CRM_Nguoi_ban_giao_va_nguoi_duoc_ban_giao_phai_khac_nhau": "Người bàn giao và người được bàn giao phải khác nhau",
        "CRM_Nguoi_bao_gia": "Người báo giá",
        "CRM_Nguoi_cap_nhat": "Người cập nhật",
        "CRM_Nguoi_chuyen": "Người chuyển",
        "CRM_Nguoi_dai_dien_phap_luat": "Người đại diện pháp luật",
        "CRM_Nguoi_danh_gia": "Người đánh giá",
        "CRM_Nguoi_danh_gia_bat_buoc_nhap": "Người đánh giá bắt buộc nhập",
        "CRM_Nguoi_dong": "Người đóng",
        "CRM_Nguoi_dung": "Người dùng",
        "CRM_Nguoi_dung_khong_duoc_de_trong": "Người dùng không được để trống",
        "CRM_Nguoi_dung_khong_su_dung": "Người dùng không sử dụng",
        "CRM_Nguoi_duyet": "Người duyệt",
        "CRM_Nguoi_giai_quyet": "Người giải quyết",
        "CRM_Nguoi_gui": "Người gửi",
        "CRM_Nguoi_lap": "Người lập",
        "CRM_Nguoi_lien_he": "Người liên hệ",
        "CRM_Nguoi_lien_he_chinh": "Người liên hệ  chính",
        "CRM_Nguoi_nhan": "Người nhận",
        "CRM_Nguoi_phu_trach": "Người phụ trách",
        "CRM_Nguoi_sua": "Người sửa",
        "CRM_Nguoi_tao": "Người tạo",
        "CRM_Nguoi_them": "Người thêm",
        "CRM_Nguoi_thuc_hien": "Người thực hiện",
        "CRM_Nguoi_uy_quyen": "Người ủy quyền",
        "CRM_Nguon_goc": "Nguồn gốc",
        "CRM_Nguon_them_khach_hang_va_lien_he": "Nguồn thêm khách hàng hoặc liên hệ",
        "CRM_Nhan_don_hang": "Nhận đơn hàng",
        "CRM_Nhan_tiep": "Nhận tiếp",
        "CRM_Nhan_vien": "Nhân viên",
        "CRM_Nhan_vien_ban_giao": "Nhân viên bàn giao",
        "CRM_Nhan_vien_kinh_doanh": "Nhân viên kinh doanh",
        "CRM_Nhan_vien_nay_da_duoc_su_dung": "Nhân viên này đã được sử dụng",
        "CRM_Nhan_vien_nhan_ban_giao": "Nhân viên nhận bàn giao",
        "CRM_Nhap": "Nhập",
        "CRM_Nhap_chuoi": "Nhập chuỗi",
        "CRM_Nhap_it_nhat_1_san_pham": "Nhập ít nhất 1 sản phẩm",
        "CRM_Nhap_noi_dung_kien_nghi_can_tim_kiem": "Nhập nội dung, kiến nghị cần tìm kiếm",
        "CRM_Nhap_noi_dung_tim_kiem": "Nhập nội dung tìm kiếm",
        "CRM_Nhap_Ten_Dang_Nhap": "Nhập tên đăng nhập",
        "CRM_Nhap_tiep": "Nhập tiếp",
        "CRM_Nho_mat_khau1": "Nhớ mật khẩu",
        "CRM_Nhom_cong_ty": "Nhóm công ty",
        "CRM_Nhom_hang_chu_luc": "Nhóm hàng chủ lực",
        "CRM_Nhom_khach_hang": "Nhóm khách hàng",
        "CRM_Nhom_kinh_doanh": "Nhóm kinh doanh",
        "CRM_Nhom_kinh_doanh_khong_duoc_de_trong": "Nhóm kinh doanh không được để trống",
        "CRM_Nhom_nganh_nghe": "Nhóm ngành nghề",
        "CRM_Nhom_phan_loai": "Nhóm phân loại",
        "CRM_Nhom_phan_quyen": "Nhóm phân quyền",
        "CRM_Nhom_thue": "Nhóm thuế",
        "CRM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "Những ý kiến này sẽ được Công ty chúng tôi ghi nhận và làm cơ sở để nâng cao chất lượng dịch vụ nhằm đáp ứng nhu cầu của khách hàng ngày càng tốt hơn",
        "CRM_Noi_cap": "Nơi cấp",
        "CRM_Noi_cap_CMND": "Nơi cấp CMND",
        "CRM_Noi_dung": "Nội dung",
        "CRM_Noi_dung_can_tim": "Nội dung cần tìm",
        "CRM_Noi_dung_cong_viec": "Nội dung công việc",
        "CRM_Noi_dung_khao_sat": "Nội dung khảo sát",
        "CRM_Noi_dung_khong_duoc_de_trong": "Nội dung không được để trống",
        "CRM_Noi_dung_tim_kiem": "Nội dung tìm kiếm",
        "CRM_Noi_giao_hang": "Nơi giao hàng",
        "CRM_Noi_nhan_hang": "Nơi nhận hàng",
        "CRM_Nu": "Nữ",
        "CRM_NVKD": "NVKD",
        "CRM_Phai_chon_nguoi_ban_giao": "Phải chọn người bàn giao",
        "CRM_Phai_chon_nguoi_duoc_ban_giao": "Phải chọn người được bàn giao",
        "CRM_Phan_he": "Phân hệ",
        "CRM_Phan_loai": "Phân loại",
        "CRM_Phan_loai_doi_tac": "Phân loại đối tác",
        "CRM_Phan_loai_doi_thu": "Phân loại đối thủ",
        "CRM_Phan_loai_KH": "Phân loại KH",
        "CRM_Phan_loai_khach_hang": "Phân loại khách hàng",
        "CRM_Phan_loai_nhom": "Phân loại nhóm",
        "CRM_Phien_ban": "Phiên bản",
        "CRM_Phong_ban": "Phòng ban",
        "CRM_Phuong": "Phường",
        "CRM_Phuong_phap_tinh_tuoi_no": "Phương pháp tính tuổi nợ",
        "CRM_Phuong_thuc_giao_hang": "Phương thức giao hàng",
        "CRM_Phuong_thuc_TT": "Phương thức TT",
        "CRM_Phuong_xa": "Phường, xã",
        "CRM_phut": "phút",
        "CRM_PT_giao_hang": "PT giao hàng",
        "CRM_PT_thanh_toan": "PT thanh toán",
        "CRM_Quan": "Quận",
        "CRM_Quan_huyen": "Quận/Huyện",
        "CRM_Quan_ly": "Quản lý",
        "CRM_Quan_ly_danh_muc": "Quản lý danh mục",
        "CRM_Quay_lai": "Quay lại",
        "CRM_Quoc_gia": "Quốc gia",
        "CRM_Quy_cach_dong_goi": "Quy cách đóng gói",
        "CRM_Quy_mo_cong_ty": "Quy mô công ty",
        "CRM_Quy_uoc_nhu_sau": "Quy ước như sau",
        "CRM_Sao_chep_bao_gia": "Sao chép báo giá",
        "CRM_Sao_chep_chuong_trinh": "Sao chép chương trình",
        "CRM_Sao_chep_doi_tac": "Sao chép đối tác",
        "CRM_Sao_chep_doi_thu": "Sao chép đối thủ",
        "CRM_Sao_chep_khach_hang": "Sao chép khách hàng",
        "CRM_Sau_so_ngay": "Sau số ngày",
        "CRM_Sinh_nhat": "Sinh nhật",
        "CRM_Skype": "Skype",
        "CRM_SL": "SL",
        "CRM_SL_co_the_xuat": "SL có thể xuất",
        "CRM_SL_hoa_don": "SL hóa đơn",
        "CRM_SL_ton_thuc_te": "SL tồn thực tế",
        "CRM_So_bao_gia": "Số báo giá",
        "CRM_So_CCCD_/_CMND": "Số CCCD / CMND",
        "CRM_So_chung_tu": "Số chứng từ",
        "CRM_So_di_dong": "Số di động",
        "CRM_So_dien_thoai": "Số điện thoại",
        "CRM_So_dien_thoai_khong_hop_le": "Số điện thoại không hợp lệ",
        "CRM_So_dien_thoai_nguoi_lien_he": "Số điện thoại người liên hệ",
        "CRM_So_dien_thoai_trung_voi_lien_he": "Số điện thoại trùng với liên hệ ",
        "CRM_So_don_hang": "Số đơn hàng",
        "CRM_So_dong": "Số dòng",
        "CRM_So_dong_RPC": "Số dòng",
        "CRM_So_dong_tren_mot_trang": "Số dòng trên một trang",
        "CRM_So_Fax": "Số Fax",
        "CRM_So_lung_den_khong_duoc_de_trong": "Số lượng đến không được để trống",
        "CRM_So_luong": "Số lượng",
        "CRM_So_luong_co_the_dat_ban": "Số lượng có thể đặt bán",
        "CRM_So_luong_dang_dat_ban": "Số lượng đang đặt bán",
        "CRM_So_luong_dang_dat_hoa_don_ban": "Số lượng đang đặt hóa đơn bán",
        "CRM_So_luong_den_phai_lon_hon_so_luong_tu": "Số lượng đến phải lớn hơn số lượng từ",
        "CRM_So_luong_khong_duoc_de_trong": "Số lượng không được để trống",
        "CRM_So_luong_QD": "Số lượng QĐ",
        "CRM_So_luong_sau_DC": "Số lượng sau ĐC",
        "CRM_So_luong_toi_thieu": "Số lượng tối thiểu",
        "CRM_So_luong_ton_kho": "Số lượng tồn kho",
        "CRM_So_luong_tu_khong_duoc_de_trong": "Số lượng từ không được để trống",
        "CRM_So_luong_tu_phai_nho_hon_so_luong_den": "Số lượng từ phải nhỏ hơn số lượng đến",
        "CRM_So_may_ban": "Số máy bàn",
        "CRM_So_tai_khoan": "Số tài khoản",
        "CRM_So_thu_tu": "Số thứ tự",
        "CRM_Status": "Trạng thái",
        "CRM_Store_kiem_tra_bi_loi": "Store kiểm tra bị lỗi",
        "CRM_STT": "STT",
        "CRM_Su_dung_phan_loai": "Sử dụng phân loại",
        "CRM_Su_dung_tai": "Sử dụng tại",
        "CRM_Sua": "Sửa",
        "CRM_Tai_khoan": "Tài khoản",
        "CRM_Tai_khoan_bi_khoa_10_phut": "Tài khoản bị khoá, hãy thử lại sau 10 phút",
        "CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Tài khoản đăng nhập hoặc mật khẩu không đúng.",
        "CRM_Tai_khoan_khong_co_quyen": "Bạn không có quyền truy cập vào hệ thống.",
        "CRM_Tai_len": "Tải lên",
        "CRM_Tai_lieu": "Tài liệu",
        "CRM_Tai_lieu_da_dinh_kem": "Tài liệu đã đính kèm",
        "CRM_Tai_lieu_dinh_kem": "Tài liệu đính kèm",
        "CRM_Tai_U": "Tải",
        "CRM_Tam_dap_ung_Can_cai_tien_them": "Tạm đáp ứng, cần phải cải tiến thêm",
        "CRM_Tang_dan": "Tăng dần",
        "CRM_Tang_luong_truoc_thoi_han": "Tăng lương trước thời hạn",
        "CRM_Tap_tin": "Tập tin",
        "CRM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Tập tin đính kèm đang được xử lý",
        "CRM_Tat_ca": "Tất cả",
        "CRM_Ten": "Tên",
        "CRM_Ten_bao_cao": "Tên báo cáo",
        "CRM_Ten_cong_viec": "Tên công việc",
        "CRM_Ten_CTV": "Tên CTV",
        "CRM_Ten_Dang_Nhap": "Tên đăng nhập",
        "CRM_Ten_dang_nhap_khong_duoc_de_trong": "Tên đăng nhập không được để trống.",
        "CRM_Ten_danh_sach": "Tên danh sách",
        "CRM_Ten_danh_sach_khong_duoc_de_trong": "Tên danh sách không được để trống",
        "CRM_Ten_dia_ban": "Tên địa bàn",
        "CRM_Ten_dia_ban_khong_duoc_de_trong": "Tên địa bàn không được để trống",
        "CRM_Ten_doi_tac": "Tên công ty",
        "CRM_Ten_doi_thu": "Tên đối thủ",
        "CRM_Ten_don_vi_tinh": "Tên đơn vị tính",
        "CRM_Ten_File": "Tên file",
        "CRM_Ten_hang": "Tên hàng",
        "CRM_Ten_hang_hoa": "Tên hàng hóa",
        "CRM_Ten_khach_hang": "Tên khách hàng",
        "CRM_Ten_kho": "Tên kho",
        "CRM_Ten_khong_duoc_de_trong": "Tên không được để trống",
        "CRM_Ten_lien_he": "Tên liên hệ",
        "CRM_Ten_loai_danh_gia": "Tên loại đánh giá",
        "CRM_Ten_loai_khong_duoc_de_trong": "Tên loại không được để trống",
        "CRM_Ten_loai_thong_tin": "Tên loại thông tin",
        "CRM_Ten_loai_tinh_huong": "Tên loại tình huống",
        "CRM_Ten_mau": "Tên mẫu",
        "CRM_Ten_nhom_hang": "Tên nhóm hàng",
        "CRM_Ten_nhom_khach_hang": "Tên nhóm khách hàng",
        "CRM_Ten_nhom_kinh_doanh": "Tên nhóm kinh doanh",
        "CRM_Ten_nhom_kinh_doanh_khong_duoc_de_trong": "Tên nhóm kinh doanh không được để trống",
        "CRM_Ten_NVKD": "Tên NVKD",
        "CRM_Ten_phan_loai_khach_hang": "Tên phân loại khách hàng",
        "CRM_Ten_tat": "Tên tắt",
        "CRM_Ten_tinh_huong": "Tên tình huống",
        "CRM_Ten_truong_thiet_lap": "Tên trường thiết lập",
        "CRM_Test_1": "Kiểm lỗi",
        "CRM_Tham_so_truyen_vao_khong_hop_le": "Tham số truyền vào không hợp lệ",
        "CRM_Thang": "Tháng",
        "CRM_Thang_1": "Tháng 1",
        "CRM_Thang_10": "Tháng 10",
        "CRM_Thang_11": "Tháng 11",
        "CRM_Thang_12": "Tháng 12",
        "CRM_Thang_2": "Tháng 2",
        "CRM_Thang_3": "Tháng 3",
        "CRM_Thang_4": "Tháng 4",
        "CRM_Thang_5": "Tháng 5",
        "CRM_Thang_6": "Tháng 6",
        "CRM_Thang_7": "Tháng 7",
        "CRM_Thang_8": "Tháng 8",
        "CRM_Thang_9": "Tháng 9",
        "CRM_Thang_chua_nop_bao_cao": "Tháng chưa nộp báo cáo",
        "CRM_Thanh_pho": "Thành phố",
        "CRM_Thanh_tien": "Thành tiền",
        "CRM_Thanh_tien_QD": "Thành tiền QĐ",
        "CRM_Thanh_tien_QD_sau_CK": "Thành tiền QĐ sau CK",
        "CRM_Thanh_tien_sau_CK": "Thành tiền CK",
        "CRM_Thanh_tien_sau_DC": "Thành tiền sau ĐC",
        "CRM_Thanh_tien_sau_DC_QD": "Thành tiền sau ĐC QĐ",
        "CRM_Thanh_tien_sau_thue": "Thành tiền sau thuế",
        "CRM_Thanh_tien_sau_thue_QD": "Thành tiền sau thuế QĐ",
        "CRM_Thanh_tien_truoc_thue": "Thành tiền trước thuế",
        "CRM_Thao_luan": "Thảo luận",
        "CRM_Thao_tac": "Thao tác",
        "CRM_Thay_doi_loai_tien_se_tinh_toan_lai_thanh_tien_QD_ban_co_muon_tiep_tuc_khong": "Thay đổi loại tiền sẽ tính toán lại thành tiền QĐ. Bạn có muốn tiếp tục không ?",
        "CRM_Thay_doi_mat_khau_khong_thanh_cong": "Thay đổi mật khẩu không thành công",
        "CRM_Thay_doi_nghiep_vu_se_xoa_du_lieu_luoi_mat_hang_ban_co_muon_tiep_tuc_khong": "Thay đổi nghiệp vụ sẽ xóa dữ liệu lưới mặt hàng. Bạn có muốn tiếp tục không ?",
        "CRM_Them": "Thêm",
        "CRM_Them_dieu_kien_loc": "Thêm điều kiện lọc",
        "CRM_Them_dong": "Thêm dòng",
        "CRM_Them_hang_hoa": "Thêm hàng hóa",
        "CRM_Them_hinh_anh_mat_hang": "Thêm hình ảnh mặt hàng",
        "CRM_Them_khong_thanh_cong": "Thêm không thành công",
        "CRM_Them_moi": "Thêm mới",
        "CRM_Them_moi_khach_hang": "Thêm mới khách hàng sẽ mất dữ liệu hiện tại đã nhập.Bạn có muốn tiếp tục?",
        "CRM_Them_moi1": "Thêm mới",
        "CRM_Them_nhom_kinh_doanh": "Thêm nhóm kinh doanh",
        "CRM_Them_vao_danh_sach": "Thêm vào danh sách",
        "CRM_Theo_nhan_vien": "Theo nhân viên",
        "CRM_Thiet_lap": "Thiết lập",
        "CRM_Thiet_lap_he_thong": "Thiết lập hệ thống",
        "CRM_Thiet_lap_khach_hang_chung": "Thiết lập khách hàng chung",
        "CRM_Thiet_lap_mail": "Thiết lập mail",
        "CRM_Thiet_lap_mau_bao_cao": "Thiết lập mẫu báo cáo",
        "CRM_Thiet_lap_phan_quyen": "Thiết lập phân quyền",
        "CRM_Thiet_lap_thong_tin_Email": "Thiết lập thông tin Email",
        "CRM_Thiet_lap_truong_bat_buoc": "Thiết lập trường bắt buộc",
        "CRM_Thiet_lap_truong_bat_buoc_nhap": "Thiết lập trường bắt buộc nhập",
        "CRM_Thoi_gian": "Thời gian",
        "CRM_Thoi_gian_den_nho_hon_tu": "Thời gian kết thúc phải lớn hơn hoặc bằng thời gian bắt đầu",
        "CRM_Thoi_gian_giao_hang": "Thời gian giao hàng",
        "CRM_Thoi_gian_khong_tai_ky_dat_hang": "Thời gian không tái ký đặt hàng",
        "CRM_Thoi_gian_thuc_hien": "Thời gian thực hiện",
        "CRM_Thoi_gian_tu_lon_hon_den": "Thời gian bắt đầu phải nhỏ hơn hoặc bằng thời gian kết thúc",
        "CRM_Thong_bao": "Thông báo",
        "CRM_thong_bao_moi": "Thông báo mới",
        "CRM_Thong_bao1": "Thông Báo",
        "CRM_Thong_baoU": "Thông báo",
        "CRM_Thong_so_thoi_gian": "Thông số thời gian",
        "CRM_Thong_tin_bao_gia": "Thông tin báo giá",
        "CRM_Thong_tin_bo_sung": "Thông tin bổ sung",
        "CRM_Thong_tin_ca_nhan": "Thông tin cá nhân",
        "CRM_Thong_tin_chinh": "Thông tin chính",
        "CRM_Thong_tin_chung": "Thông tin chung",
        "CRM_Thong_tin_cong_ty": "Thông tin công ty",
        "CRM_Thong_tin_cung_cap_khong_hop_le": "Thông tin cung cấp không hợp lệ!",
        "CRM_Thong_tin_don_hang": "Thông tin đơn hàng",
        "CRM_Thong_tin_hang_hoa": "Thông tin hàng hóa",
        "CRM_Thong_tin_khach_hang": "Thông tin khách hàng",
        "CRM_Thong_tin_phu": "Thông tin phụ",
        "CRM_Thong_tin_thue_GTGT": "Thông tin thuế GTGT",
        "CRM_Thu_gon": "Thu gọn",
        "CRM_Thu_tu_hien_thi": "Thứ tự hiển thị",
        "CRM_Thue": "Thuế",
        "CRM_Thue_GTGT": "Thuế GTGT",
        "CRM_Thue_GTGT_sau_QD": "Thuế GTGT sau QĐ",
        "CRM_Thue_suat": "Thuế suất",
        "CRM_Thue_suat_khong_duoc_lon_hon": "Thuế suất không được lớn hơn",
        "CRM_Tien_ban": "Tiền bán",
        "CRM_Tien_chiet_khau": "Tiền chiết khấu",
        "CRM_Tien_CK": "Tiền CK",
        "CRM_Tien_CK_QD": "Tiền CK QĐ",
        "CRM_Tien_do_bao_gia": "Tiến độ báo giá",
        "CRM_Tien_do_don_hang": "Tiến độ đơn hàng",
        "CRM_Tien_hang": "Tiền hàng",
        "CRM_Tien_te": "Tiền tệ",
        "CRM_Tien_te_khong_duoc_de_trong": "Tiền tệ không được để trống",
        "CRM_Tien_thue": "Tiền thuế",
        "CRM_Tien_thue_QD": "Tiền thuế QĐ",
        "CRM_Tieu_de": "Tiêu đề",
        "CRM_Tieu_de_khong_duoc_de_trong": "Tiêu đề không được để trống",
        "CRM_Tieu_de_mail": "Tiêu đề mail",
        "CRM_Tieu_thuc_nhom": "Tiêu thức nhóm",
        "CRM_Tim_kiem": "Tìm kiếm",
        "CRM_Tim_kiem_khach_hang": "Tìm kiếm khách hàng",
        "CRM_Tim_kiem_lien_he": "Tìm kiếm liên hệ",
        "CRM_Tim_kiem_nang_cao": "Tìm kiếm nâng cao",
        "CRM_Time_from_>_time_to": "Thời gian bắt đầu phải lớn hơn thời gian kết thúc",
        "CRM_Tinh": "Tính",
        "CRM_Tinh_huong": "Tình huống",
        "CRM_Tinh_thanh_pho": "Tỉnh, thành phố",
        "CRM_To_chuc": "Tổ chức",
        "CRM_Ton_tai_chi_tieu_co_gia_tri_khong_hop_le": "Tồn tại chỉ tiêu có giá trị không hợp lệ.",
        "CRM_Tong": "Tổng",
        "CRM_Tong_chi_tieu_cua_nhom_khong_duoc_nho_hon_chi_tieu_chi_tiet": "Tổng chỉ tiêu của nhóm không được nhỏ hơn chỉ tiêu chi tiết",
        "CRM_Tong_diem": "Tổng điểm",
        "CRM_Tong_gia_tri_A": "Tổng giá trị A",
        "CRM_Tong_gia_tri_A_khong_bang_tong_gia_tri_B": "Tổng giá trị A không bằng tổng giá trị B",
        "CRM_Tong_gia_tri_B": "Tổng giá trị B",
        "CRM_Tong_gia_tri_bao_gia": "Tổng giá trị báo giá",
        "CRM_Tong_gia_tri_don_hang": "Tổng giá trị đơn hàng",
        "CRM_Tong_gia_tri_don_hang_QD": "Tổng giá trị đơn hàng QĐ",
        "CRM_Tong_hop": "Tổng hợp",
        "CRM_Tong_hop_khach_hang_moi": "Tổng hợp khách hàng mới",
        "CRM_Tong_so_luong_bao_gia": "Tổng số lượng báo giá",
        "CRM_Tong_thanh_toan": "Tổng thanh toán",
        "CRM_Tong_thanh_toan_QD": "Tổng thanh toán QĐ",
        "CRM_Tong_tien": "Tổng tiền",
        "CRM_Tong_tien_QD": "Tổng tiền QĐ",
        "CRM_Tong_tien_sau_DC": "Tổng tiền sau ĐC",
        "CRM_Tong_tien_sau_DC_QD": "Tổng tiền sau ĐC QĐ",
        "CRM_Trang": "Trang",
        "CRM_Trang_Chu": "Trang chủ",
        "CRM_Trang_thai": "Trạng thái",
        "CRM_Trang_thai_chi_tiet": "Trạng thái chi tiết",
        "CRM_Trang_thai_cu": "Trạng thái cũ",
        "CRM_Trang_thai_KH": "Trạng thái KH",
        "CRM_Trang_thai_khach_hang": "Trạng thái khách hàng",
        "CRM_Trang_thai_moi": "Trạng thái mới",
        "CRM_Trang_thai_thuc_hien": "Trạng thái thực hiện",
        "CRM_Trang_thai_thuc_hien_DH": "Trạng thái thực hiện ĐH",
        "CRM_Trang_thai_vao_ra": "Trạng thái vào ra",
        "CRM_Tre_han": "Trễ hạn",
        "CRM_Trinh_duyet_khong_co_quyen_mo_popup": "Trình duyệt không có quyền mở popup",
        "CRM_Tron_truong": "Trộn trường",
        "CRM_trong": "trống",
        "CRM_Truc_thuoc_nhom": "Trực thuộc  nhóm",
        "CRM_Truong_thiet_lap": "Trường thiết lập",
        "CRM_Truy_cap_khong_hop_le": "Truy cập không hợp lệ",
        "CRM_Tu": "Từ",
        "CRM_Tu_choi": "Từ chối",
        "CRM_Tu_ngay": "Từ ngày",
        "CRM_Tuy_chon_cong_ty": "Tùy chọn công ty",
        "CRM_Ty_gia": "Tỷ giá",
        "CRM_Ty_le_chiet_khau": "Tỷ lệ chiết khấu",
        "CRM_Ty_le_CK": "Tỷ lệ CK",
        "CRM_Ty_le_qua_han": "Tỷ lệ quá hạn",
        "CRM_Vao_luc": "Vào lúc",
        "CRM_Vao_ngay": "Vào ngày",
        "CRM_Vi_tri_dia_ly": "Vị trí địa lý",
        "CRM_Viet_binh_luan": "Viết bình luận",
        "CRM_VoucherID_khong_duoc_de_trong": "VoucherID không được để trống",
        "CRM_Vui_long_chon_hinh_anh": "Vui lòng chọn hình ảnh",
        "CRM_Vui_long_chon_ma_hang_va_don_vi_tinh": "Vui lòng chọn mã hàng và đơn vị tính",
        "CRM_Vui_long_nhap_day_du_don_gia_truoc_thue_va_so_luong_hang_hoa": "Vui lòng nhập đầy đủ đơn giá trước thuế và số lượng hàng hóa",
        "CRM_Vui_long_nhap_day_du_thong_tin": "Vui lòng nhập đầy đủ thông tin.",
        "CRM_Vui_long_truy_cap_sau": "Vui lòng truy cập sau!",
        "CRM_Xa": "Xã",
        "CRM_Xac_nhan_mat_khau_moi": "Xác nhận mật khẩu mới",
        "CRM_XaPhuong": "Xã/Phường",
        "CRM_Xem": "Xem",
        "CRM_Xem_bao_cao": "Xem báo cáo",
        "CRM_Xem_it_hon": "Xem ít hơn",
        "CRM_Xem_nhieu_hon": "Xem nhiều hơn",
        "CRM_Xem_tap_tin": "Xem tập tin",
        "CRM_Xem_tat_ca": "Xem tất cả",
        "CRM_Xem_them": "Xem thêm",
        "CRM_Xem_truoc": "Xem trước",
        "CRM_Xin_cam_on": "Xin cảm ơn",
        "CRM_Xoa": "Xóa",
        "CRM_Xoa_khong_thanh_cong": "Xóa không thành công",
        "CRM_Xoa_quyen_thanh_cong": "Xóa quyền thành công",
        "CRM_Xuat_bao_cao": "Xuất báo cáo",
        "CRM_Xuat_danh_sach_khach_hang": "Xuất danh sách khách hàng",
        "CRM_Xuat_danh_sach_lien_he": "Xuất danh sách liên hệ",
        "CRM_Xuat_du_lieu": "Xuất dữ liệu",
        "CRM_Xuat_Excel": "Xuất Excel",
        "CRM_Xuat_khau_khach_hang": "Xuất khẩu khách hàng",
        "CRM_Xuat_PDF": "Xuất PDF",
        "CRM_Xuat_word": "Xuất Word",
        "CRM_Y_kien_dong_gop": "Ý kiến đóng góp",
        "CRM_Yeu_cau_chuyen_doi": "Yêu cầu chuyển đổi",
        "CRM_Yeu_cau_chuyen_doi_thanh_cong": "Yêu cầu chuyển đổi thành công"
    },
    "en": {
        "CRM_403_loi": "You do not have permission to view this page",
        "CRM_404_loi": "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
        "CRM_AC_vui_long_chon_y_kien_cua_minh": "Please let us know your evaluation on service level",
        "CRM_Ap": "Quarter",
        "CRM_Ap_Khu_pho": "Hamlet/Quarter",
        "CRM_App": "Quản trị mối quan hệ khách hàng 2",
        "CRM_Ban_chua_chon_chu_so_huu": "You have to choose Salesperson",
        "CRM_Ban_chua_chon_hang_hoa": "CRM_Ban_chua_chon_hang_hoa",
        "CRM_Ban_chua_chon_khach_hang": "You have to choose customer",
        "CRM_Ban_chua_chon_lien_he": "CRM_Ban_chua_chon_lien_he",
        "CRM_Ban_chua_chon_mau_bao_cao": "Ban_chua_chon_mau_bao_cao",
        "CRM_Ban_chua_chon_ngay_den": "Ban_chua_chon_ngay_den",
        "CRM_Ban_chua_chon_nhom_hang": "Ban_chua_chon_nhom_hang",
        "CRM_Ban_chua_chon_truong_thiet_lap_bat_buoc_nhap": "Ban_chua_chon_truong_thiet_lap_bat_buoc_nhap",
        "CRM_Ban_chua_co_thong_bao_nao": "You have no notifications yet",
        "CRM_Ban_chua_nhap_chi_tieu_doanh_so": "Ban_chua_nhap_chi_tieu_doanh_so",
        "CRM_Ban_chua_nhap_chu_de_cua_Email": "Ban_chua_nhap_chu_de_cua_Email",
        "CRM_Ban_chua_nhap_Email_Bcc": "Ban_chua_nhap_Email_Bcc",
        "CRM_Ban_chua_nhap_Email_Cc": "Ban_chua_nhap_Email_Cc",
        "CRM_Ban_chua_nhap_Email_nguoi_nhan": "Ban_chua_nhap_Email_nguoi_nhan",
        "CRM_Ban_chua_nhap_gia_tri": "You have not entered value",
        "CRM_Ban_chua_nhap_noi_dung_Email": "Ban_chua_nhap_noi_dung_Email",
        "CRM_Ban_chua_nhap_truong:_%p_Ban_co_muon_luu_khong?": "Ban_chua_nhap_truong:_%p_Ban_co_muon_luu_khong?",
        "CRM_Ban_chua_thiet_lap_Mau_bao_cao": "You have not set up a report template.",
        "CRM_Ban_co": "You have",
        "CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay": "Ban_co_chac_chan_muon_sua_du_lieu_nay",
        "CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "Are you sure to delete this data?",
        "CRM_Ban_co_muon_chia_se_bao_gia_nay": "Ban_co_muon_chia_se_bao_gia_nay",
        "CRM_Ban_co_muon_dong_bao gia": "CRM_Ban_co_muon_dong_bao gia",
        "CRM_Ban_co_muon_mo_lai_bao_gia": "CRM_Ban_co_muon_mo_lai_bao_gia",
        "CRM_Ban_co_muon_nhap_tiep_khong": "Do you want to continue?",
        "CRM_Ban_co_muon_xoa_quyen_cua_nhan_vien": "Do you want to delete this permission",
        "CRM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai",
        "CRM_Ban_giao": "Handover",
        "CRM_Ban_giao_cho": "CRM_Ban_giao_cho",
        "CRM_Ban_khong_co_quyen_truy_cap": "You do not have permission to access.",
        "CRM_Ban_khong_co_quyen_truy_cap_Module_nay": "Access forbidden",
        "CRM_Ban_phai_chon_doanh_nghiep": "Ban_phai_chon_doanh_nghiep",
        "CRM_Ban_phai_chon_don_vi_ban": "You must choose division",
        "CRM_Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang": "Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang",
        "CRM_Ban_phai_chuyen_khach_hang_thanh_doi_tuong": "You must have convert customer into object",
        "CRM_Bang_bao_gia": "Quotations",
        "CRM_Bang_gia": "Price List",
        "CRM_Bang_gia_khong_duoc_de_trong": "Bang_gia_khong_duoc_de_trong",
        "CRM_Bao_cao": "Report",
        "CRM_Bao_cao_chi_tiet_cong_viec": "Report Activities",
        "CRM_Bao_cao_hien_thi": "Báo cáo hiển thị",
        "CRM_Bao_cao_hoat_dong": "Bao_cao_hoat_dong",
        "CRM_Bao_cao_hoat_dong_theo_khach_hang": "Bao_cao_hoat_dong_theo_khach_hang",
        "CRM_Bao_cao_khach_hang_theo_ma_hang": "CRM_Bao_cao_khach_hang_theo_ma_hang",
        "CRM_Bao_cao_phan_tich_tien_thu_ban_hang": "Sales receipt analysis report",
        "CRM_Bao_cao_so_lieu_hoat_dong": "CRM_Bao_cao_so_lieu_hoat_dong",
        "CRM_Bao_cao_thong_ke_khach_hang": "Statistical Reports Customer",
        "CRM_Bao_gia": "Quotations",
        "CRM_Bao_gia_da_duyet": "Bao_gia_da_duyet",
        "CRM_Bao_gia_duoc_dong": "CRM_Bao_gia_duoc_dong",
        "CRM_Bat_buoc_nhap": "Not blank",
        "CRM_Bo_duyet": "Cancel Approval",
        "CRM_Bo_duyet_thanh_cong": "Approval cancellation successfully",
        "CRM_Bo_phan": "Department",
        "CRM_Boi": "By",
        "CRM_Ca_nam": "Total of Year",
        "CRM_Ca_nhan": "Personal",
        "CRM_Cac_chi_tieu_DK_A": "Formula A",
        "CRM_Cac_chi_tieu_DK_B": "Formula B",
        "CRM_Cac_gop_y_khac": "Other feedback",
        "CRM_Cac_tham_so_la_bat_buoc": "The parameters are compulsory",
        "CRM_Cac_thong_so_luong": "Salary Parameters",
        "CRM_Cac_tieu_chi_la_bat_buoc_nhap": "Compulsory criteria",
        "CRM_Cai_dat": "Settings",
        "CRM_Canh_bao_nhap": "Warning",
        "CRM_Cap_co_cau": "Structure level",
        "CRM_Cap_do": "Levels",
        "CRM_Cap_nhat_danh_gia": "General evaluation update",
        "CRM_Cap_nhat_danh_muc": "Update List",
        "CRM_Cap_nhat_danh_sach_khach_hang": "CRM_Cap_nhat_danh_sach_khach_hang",
        "CRM_Cap_nhat_dia_ban": "Update localities",
        "CRM_Cap_nhat_ghi_nhan_nhu_cau_khach_hang": "Cap_nhat_ghi_nhan_nhu_cau_khach_hang",
        "CRM_Cap_nhat_hoat_dong": "Activity Update",
        "CRM_Cap_nhat_lien_he": "Update contact",
        "CRM_Cap_nhat_loai_danh_gia": "update evaluation type",
        "CRM_Cap_nhat_loai_tinh_huong": "Add/Edit/View Case Type",
        "CRM_Cap_nhat_ma_hang": "Update inventory ID",
        "CRM_Cap_nhat_mau_bao_cao": "Update report template",
        "CRM_Cap_nhat_ngon_ngu": "Update language",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh": "Update Sales Person",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh1": "Cap_nhat_nhan_vien_kinh_doanh",
        "CRM_Cap_nhat_nhom_khach_hang": "Update customer group",
        "CRM_Cap_nhat_thong_tin_bo_sung": "Add/Edit/View Additional Information",
        "CRM_Cap_nhat_thong_tin_giao_hang": "Update delivery information",
        "CRM_Cap_nhat_tinh_huong": "Case update",
        "CRM_Cap_nhat_trang_thai": "Change Status",
        "CRM_Cap_nhat_trang_thai_KH": "Update customer status",
        "CRM_Cap_nhat_trang_thai_thuc_hien_DH": "Update order status voucher",
        "CRM_Chap_nhan_don_hang": "Accept Order",
        "CRM_Chi_nhanh_ngan_hang": "Chi_nhanh_ngan_hang",
        "CRM_Chi_tiet": "Detail",
        "CRM_Chi_tiet_bao_gia": "Chi_tiet_bao_gia",
        "CRM_Chi_tiet_don_hang": "Order Detail",
        "CRM_Chi_tiet_loi": "Description",
        "CRM_Chi_tiet_nguoi_lien_he": "Chi_tiet_nguoi_lien_he",
        "CRM_Chi_tiet_theo": "Chi tiết theo",
        "CRM_Chi_tieu": "Objectives",
        "CRM_Chi_tieu_chi_tiet_vuot_tong_chi_tieu_cua_nhom": "Chi_tieu_chi_tiet_vuot_tong_chi_tieu_cua_nhom",
        "CRM_Chi_tieu_doanh_so": "Chi_tieu_doanh_so",
        "CRM_Chia_se": "Share ",
        "CRM_Chia_se_doi_tac": "Chia_se_doi_tac",
        "CRM_Chia_se_doi_thu": "Chia_se_doi_thu",
        "CRM_Chia_se_khach_hang": "Chia_se_khach_hang",
        "CRM_Chiet_khau": "Discount",
        "CRM_Chiet_khau_khong_duoc_lon_hon": "Chiet_khau_khong_duoc_lon_hon",
        "CRM_Chinh_sua_khong_thanh_cong": "Edit not successful",
        "CRM_Chinh_sua_thanh_cong": "Successful updating",
        "CRM_Cho_duyet": "Pending Approval",
        "CRM_Chon": "Select",
        "CRM_Chon_combo": "-- Choose --",
        "CRM_Chon_gia_tri": "Chon_gia_tri",
        "CRM_Chon_hang": "Chon_hang",
        "CRM_Chon_hang_hoa": "CRM_Chon_hang_hoa",
        "CRM_Chon_hinh": "Load Image",
        "CRM_Chon_kenh": "Choose media",
        "CRM_Chon_khach_hang": "Choose customers",
        "CRM_Chon_loai_tinh_huong": "Choose case types",
        "CRM_Chon_mau_bao_cao": "Select report format",
        "CRM_Chon_nguoi_giai_quyet": "Choose handler",
        "CRM_Chon_nhom_hang": "Select Item Group",
        "CRM_Chon_tap_tin": "Browse...",
        "CRM_Chon_tat_ca": "Select all",
        "CRM_Chon_tinh": "Choose province",
        "CRM_Chon_trang_thai": "Choose status",
        "CRM_Chua_co_lien_ket_nao": "There is no link",
        "CRM_Chua_dap_ung": "Not qualified",
        "CRM_Chua_doc": "Chua_doc",
        "CRM_Chua_thuc_hien": "Not yet do transaction",
        "CRM_Chuc_danh": "Title",
        "CRM_Chuc_nang_chua_phat_trien": "Pending features",
        "CRM_Chuc_nang_dang_duoc_phat_trien": "Functions under development",
        "CRM_Chuc_nang_them_moi": "Add new",
        "CRM_Chuc_vu": "Position",
        "CRM_Chuc_vu_bat_buoc_nhap": "Position is required",
        "CRM_Chuong_trinh_cham_soc_khach_hang": "CRM_Chuong_trinh_cham_soc_khach_hang",
        "CRM_Chuyen_chu_so_huu": "Transfer Salesperson",
        "CRM_Chuyen_doi_trang_thai": "Status switch",
        "CRM_CMR_Khong_tim_thay_trang": "PAGE NOT FOUND",
        "CRM_Co_cau_cha": "Father node",
        "CRM_Co_cau_kinh_doanh": "Business structure",
        "CRM_Co_cau_to_chuc": "Organization Structure",
        "CRM_Co_it_nhat_mot_chu_cai": "Co_it_nhat_mot_chu_cai",
        "CRM_Co_it_nhat_mot_chu_cai_in_hoa": "Co_it_nhat_mot_chu_cai_in_hoa",
        "CRM_Co_it_nhat_mot_so": "Co_it_nhat_mot_so",
        "CRM_Co_it_nhat_sau_ky_tu": "Co_it_nhat_sau_ky_tu",
        "CRM_Co_loi_khi_luu": "Error saving",
        "CRM_Co_loi_khi_luu_lich_su": "CRM_Co_loi_khi_luu_lich_su",
        "CRM_Co_loi_khi_tim_kiem": "CRM_Co_loi_khi_tim_kiem",
        "CRM_Co_loi_khi_xoa": "Co_loi_khi_xoa",
        "CRM_Co_loi_khi_xoa_dinh_kem": "Error When Deleting Attach File",
        "CRM_Co_loi_khi_xoa_file_bao_cao": "Error when delete report",
        "CRM_Co_loi_trong_qua_trinh_gui_Mail": "Co_loi_trong_qua_trinh_gui_Mail",
        "CRM_Co_loi_trong_qua_trinh_luu_File": "Co_loi_trong_qua_trinh_luu_File",
        "CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu": "There's something went wrong",
        "CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "An error occurred during processing.",
        "CRM_Co1": "Yes",
        "CRM_Cong_tac_vien": "Collborator",
        "CRM_Cong_thuc_ton_tai_chi_tieu_chia_cho_0": "Formula is not valid. (divide  zero)",
        "CRM_Cong_trinh/Cong_ty": "Project/Company",
        "CRM_Cong_ty": "Company",
        "CRM_Cong_ty_cong_tac": "CRM_Cong_ty_cong_tac",
        "CRM_Cong_viec": "Job",
        "CRM_Cot_gia_tri": "Value Column",
        "CRM_Cua_bo_phan": "Của bộ phận",
        "CRM_Cua_hang_hoa": "Of inventory",
        "CRM_cua_khach_hang": " of customer ",
        "CRM_Cua_toi": "Của tôi",
        "CRM_Da_co_mat_hang": "Da_co_mat_hang",
        "CRM_Da_dong": "Closed",
        "CRM_Da_duyet": "Approved",
        "CRM_Da_hoan_thanh": "Completed",
        "CRM_Da_nghi_viec": "Terminated",
        "CRM_Dang_cong_tac": "Dang_cong_tac",
        "CRM_Dang_duyet": "Pending for Approval",
        "CRM_Dang_Lam_Viec": "In working",
        "CRM_Dang_nhap": "Log in",
        "CRM_dang_nhap_lai": "Login again",
        "CRM_Dang_nhap_lai2": "You are already logged in on a different device. Please login again.",
        "CRM_Dang_tai": "Loading",
        "CRM_Dang_thuc_hien": "Pending",
        "CRM_Dang_xuat": "Export Type",
        "CRM_Dang_xuat1": "Logout",
        "CRM_Danh_dau_tat_ca_la_da_doc": "Danh_dau_tat_ca_la_da_doc",
        "CRM_Danh_gia": "Evaluation",
        "CRM_Danh_muc/Phan_loai_khach_hang": "Danh_muc/Phan_loai_khach_hang",
        "CRM_Danh_muc_danh_gia": "List of Evalutet Type",
        "CRM_Danh_muc_Loai_tinh_huong": "List Case Types",
        "CRM_Danh_muc_trang_thai_lam_viec": "List of work status",
        "CRM_Danh_sach": "List",
        "CRM_Danh_sach_bao_cao": "List reports",
        "CRM_Danh_sach_bao_gia": "Quotations",
        "CRM_Danh_sach_bo_loc": "CRM_Danh_sach_bo_loc",
        "CRM_Danh_sach_chuong_trinh_cham_soc": "CRM_Danh_sach_chuong_trinh_cham_soc",
        "CRM_Danh_sach_cong_tac_vien": "Danh_sach_cong_tac_vien",
        "CRM_Danh_sach_danh_gia_chung": "General evaluation list",
        "CRM_Danh_sach_dia_ban": "List of localities",
        "CRM_Danh_sach_doi_tac": "CRM_Danh_sach_doi_tac",
        "CRM_Danh_sach_doi_thu": "CRM_Danh_sach_doi_tac",
        "CRM_Danh_sach_don_hang": "Orders list",
        "CRM_Danh_sach_file_dinh_kem": "Attachments list",
        "CRM_Danh_sach_khach_hang": "Danh_sach_khach_hang",
        "CRM_Danh_sach_khach_hang_dung_chung": "List of shared customers",
        "CRM_Danh_sach_lich_su_chia_se_va_chuyen_chu_so_huu": "List of shared history and owner transfers",
        "CRM_Danh_sach_lich_su_gui_mail": "CRM_Danh_sach_lich_su_gui_mail",
        "CRM_Danh_sach_lich_su_thay_doi": "List history of change",
        "CRM_Danh_sach_lien_he": "CRM_Danh_sach_lien_he",
        "CRM_Danh_sach_mat_hang": "Items List",
        "CRM_Danh_sach_nhan_vien_kinh_doanh": "Sales Employee List",
        "CRM_Danh_sach_sinh_nhat_cua_nguoi_lien_he": "Danh_sach_sinh_nhat_cua_nguoi_lien_he",
        "CRM_Danh_sach_thong_tin_bo_sung": "Danh_sach_thong_tin_bo_sung",
        "CRM_Danh_sach_tinh_huong": "List of situation",
        "CRM_Dap_ung": "Qualified",
        "CRM_Date_input_format_invalid": "Date input format invalid",
        "CRM_Den": "To",
        "CRM_Den_ngay": "Date to",
        "CRM_DG_goc": "Original Unit Price",
        "CRM_DG_truoc_thue": "Before VAT Unit Price",
        "CRM_Di_dong": "Mobile",
        "CRM_Dia_ban": "Locality",
        "CRM_Dia_chi": "Address",
        "CRM_Dia_chi_DKKD": "Customer address",
        "CRM_Dia_chi_doi_tuong_thue": "Tax Object Address",
        "CRM_Dia_chi_giao_dich": "CRM_Dia_chi_giao_dich",
        "CRM_Dia_chi_giao_hang": "Delivery Address",
        "CRM_Dia_chi_IP": "IP address",
        "CRM_Dia_chi_khach_hang": "Customer Address",
        "CRM_Dia_chi_khong_duoc_de_trong": "Address is required",
        "CRM_Dia_chi_lien_he": "Contact Address",
        "CRM_Dia_chi_thuong_tru": "Permanent Address",
        "CRM_Dia_diem": "Location",
        "CRM_Dia_diem_giao_dich": "CRM_Dia_diem_giao_dich",
        "CRM_Dia_diem_giao_hang": "Ship place",
        "CRM_Dien_dai": "Description",
        "CRM_Dien_giai": "Description",
        "CRM_Dien_thoai": "Telephone",
        "CRM_Dien_thoai_cong_ty": "Office Tel No",
        "CRM_Dien_thoai_di_dong": "Mobile Phone",
        "CRM_Dieu_chinh": "Adjust",
        "CRM_Dieu_khoan_thuong_mai": "Payment Term",
        "CRM_Dieu_khoan_TM": "Payment Term",
        "CRM_Dieu_kien": "Conditions",
        "CRM_Dieu_kien_loc": "Selection Criteria",
        "CRM_Dieu_kien_loc_chi_tiet": "CRM_Dieu_kien_loc_chi_tiet",
        "CRM_Dieu_kien_loc_khong_hop_le": "CRM_Dieu_kien_loc_khong_hop_le",
        "CRM_Dieu_kien_loc_mac_dinh": "CRM_Dieu_kien_loc_mac_dinh",
        "CRM_Dinh_dang_email_khong_hop_le": "Invalid email format",
        "CRM_Dinh_dang_file_khong_hop_le": "Invalid file format",
        "CRM_Dinh_kem": "Attachments",
        "CRM_Dinh_kem_hinh_anh": "Dinh_kem_hinh_anh",
        "CRM_Do_dai_mat_khau_khong_hop_le": "Do_dai_mat_khau_khong_hop_le",
        "CRM_Do_uu_tien": "Priority",
        "CRM_Doanh_so": "Revenue",
        "CRM_Doanh_thu": "Revenue",
        "CRM_Doanh_thu_nam": "Revenue",
        "CRM_Doi_mat_khau": "Change Password",
        "CRM_Doi_mat_khau_thanh_cong": "Password updated",
        "CRM_Doi_tac": "Company",
        "CRM_Doi_thu": "Competitor",
        "CRM_Doi_tuong": "Object",
        "CRM_Doi_tuong_ap_dung": "Applicable objects",
        "CRM_Doi_tuong_QLCN": "Debt Manager",
        "CRM_Doi_tuong_thue": "VAT Object",
        "CRM_Don_gia": "Unit Price",
        "CRM_Don_gia_ban": "Sales Unit Price",
        "CRM_Don_gia_sau_DC": "Discounted Unit",
        "CRM_Don_gia_sau_thue": "VAT Price",
        "CRM_Don_gia_truoc_thue": "Before Tax Unit Price",
        "CRM_Don_hang": "Sales Orders",
        "CRM_Don_vi": "Business Unit",
        "CRM_Don_vi_ban": "Supplier",
        "CRM_Don_vi_tinh": "Unit",
        "CRM_Dong_bao_gia": "CRM_Dong_bao_gia",
        "CRM_Dong_bao_gia_thanh_cong": "Close PriceList Successfully",
        "CRM_Dong_chuong_trinh": "CRM_Dong_chuong_trinh",
        "CRM_Dong_khong_thanh_cong": "Dong_khong_thanh_cong",
        "CRM_Dong_y": "OK",
        "CRM_Dong1": "Close",
        "CRM_Du_co_cuoi_ky": "CRM_Du_co_cuoi_ky",
        "CRM_Du_co_dau_ky": "CRM_Du_co_dau_ky",
        "CRM_Du_lieu": "Data",
        "CRM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data unsaved. Do you want to save it?",
        "CRM_Du_lieu_co_thay_doi_ban_co_muon_luu_khong": "Du_lieu_thay_doi_ban_co_muon_luu_khong",
        "CRM_Du_lieu_da_duoc_import_dang_o_trang_thai_Chua_duyet_hoac_Tu_choi_Ban_co_muon_tiep_tuc_khong": "Data already imported (Approved or Cancel) .Do you want to continue ?",
        "CRM_Du_lieu_da_duoc_su_dung": "Data in use",
        "CRM_Du_lieu_da_xoa_thanh_cong": "Data is deleted successfully",
        "CRM_Du_lieu_dinh_kem": "Attachments",
        "CRM_Du_lieu_import_bi_loi": "Data is not valid",
        "CRM_Du_lieu_tham_chieu": "Ref. Data",
        "CRM_Du_no_cuoi_ky": "CRM_Du_no_cuoi_ky",
        "CRM_Du_no_dau_ky": "CRM_Du_no_dau_ky",
        "CRM_Dung_luong": "Size",
        "CRM_Dung_luong_File_khong_duoc_lon_hon": "File size must not be larger than",
        "CRM_Duong_dan": "Path",
        "CRM_Duyet": "Approve",
        "CRM_Duyet_thanh_cong": "Approved successfully",
        "CRM_DVT": "unit of measurement",
        "CRM_Email": "Email",
        "CRM_Email_ca_nhan": "Personal email",
        "CRM_Email_cong_ty": "Company email",
        "CRM_Email_nguoi_gui": "Email_nguoi_gui",
        "CRM_Email_nguoi_nhan": "Email_nguoi_nhan",
        "CRM_Facebook": "Facebook",
        "CRM_File_Import_khong_hop_le": "File is not valid!",
        "CRM_Ghi_chu": "Notes",
        "CRM_Ghi_chu_duyet": "Approve Notes",
        "CRM_Ghi_chu_KH": "Customer note",
        "CRM_Ghi_chu_noi_bo": "Internal comment",
        "CRM_Ghi_nhan_nhu_cau": "Ghi_nhan_nhu_cau",
        "CRM_Gia_goc": "Gia_goc",
        "CRM_Gia_tri_bat_buoc_nhap": "Value Obligatory",
        "CRM_Gia_tri_khong_hop_le": "Invalid value",
        "CRM_Giai_phap": "Solution",
        "CRM_Giam_dan": "Descending",
        "CRM_Giao_hang": "Delivery",
        "CRM_Giay": "Seconds",
        "CRM_Giay_CN_DKKD": "Giay_CN_DKKD",
        "CRM_Gioi_tinh": "Gender",
        "CRM_Gioi_tinh_khong_duoc_de_trong": "Gioi_tinh_khong_duoc_de_trong",
        "CRM_Gui": "Send",
        "CRM_Gui_mail": "Send mail",
        "CRM_Gui_thanh_cong": "Sent",
        "CRM_Hai_long_can_cai_tien_them": "Satifactory and needs improvement",
        "CRM_Han_ket_thuc": "Deadline",
        "CRM_Han_xu_ly": "Processing time",
        "CRM_Han_xu_ly_khong_duoc_de_trong": "Han_xu_ly_khong_duoc_de_trong",
        "CRM_Han_xu_ly_phai_lon_hon_ngay_bat_dau": "Complete deadline must be greater then start date",
        "CRM_Hang_hoa": "Inventory",
        "CRM_Hang_hoa_da_chon": "Hang_hoa_da_chon",
        "CRM_Hang_hoa_nay_da_ton_tai": "CRM_Hang_hoa_nay_da_ton_tai",
        "CRM_Hanh_dong": "Action",
        "CRM_He_thong": "System",
        "CRM_He_thong_dang_trong_qua_trinh_nang_cap": "The system is currently undergoing maintenance.",
        "CRM_Het_dong": "CRM_Het_dong",
        "CRM_Hien_thi_chi_tiet": "Show Detail",
        "CRM_Hien_thi_chi_tieu_da_huy": "Display data not use",
        "CRM_Hien_thi_danh_sach_khong_su_dung": "Display disabled list",
        "CRM_Hien_thi_khong_su_dung": "Display not used items",
        "CRM_Hien_thi_ngung_hoat_dong": "Hien_thi_ngung_hoat_dong",
        "CRM_Hien_thi_so_luong_bang_0": "Hien_thi_so_luong_bang_0",
        "CRM_Hien_thi_voi_moi_nguoi": "CRM_Hien_thi_voi_moi_nguoi",
        "CRM_Hieu_luc_den": "Valid to",
        "CRM_Hieu_luc_den_phai_lon_hon_hieu_luc_tu": "Hieu_luc_den_phai_lon_hon_hieu_luc_tu",
        "CRM_Hieu_luc_tu": "Valid from",
        "CRM_Ho_chieu": "Passport",
        "CRM_Ho_ten": "Ho_ten",
        "CRM_Ho_va_ten": "Full name",
        "CRM_Ho_va_ten_khong_duoc_de_trong": "Ho_va_ten_khong_duoc_de_trong",
        "CRM_Ho_va_ten_nguoi_lien_he": "Ho_va_ten_nguoi_lien_he",
        "CRM_Hoan_toan_hai_long": "Fully satisfied",
        "CRM_Hoat_dong": "Activity",
        "CRM_Hoat_dong_cua_toi": "My workpace",
        "CRM_Hom_nay": "Today",
        "CRM_Huy": "Cancel",
        "CRM_Huy_bo": "Cancel",
        "CRM_Huyen": "District",
        "CRM_Import_bao_cao": "Import Report",
        "CRM_Import_du_lieu": "I&mport Data",
        "CRM_In": "&Print",
        "CRM_In1": "Print",
        "CRM_Kenh_gop_y": "Comment channel",
        "CRM_Kenh_truyen_thong": "CRM_Kenh_truyen_thong",
        "CRM_Kenh_truyen_thong_khong_duoc_de_trong": "CRM_Kenh_truyen_thong_khong_duoc_de_trong",
        "CRM_Ket_qua": "Result",
        "CRM_Khac": "Other",
        "CRM_Khach_hang": "Customer",
        "CRM_Khach_hang_da_phat_sinh_doat_dong_ban_khong_duoc_phep_xoa": "Khach_hang_da_phat_sinh_doat_dong_ban_khong_duoc_phep_xoa",
        "CRM_Khach_hang_khong_duoc_de_trong": "Khach_hang_khong_duoc_de_trong",
        "CRM_Khach_hang_la_nguoi_lien_he": "Khach_hang_la_nguoi_lien_he",
        "CRM_Khach_hang_va_lien_he": "Khách hàng và liên hệ",
        "CRM_Khao_sat_y_kien_khach_hang": "Customer survey",
        "CRM_Kho": "Ware house",
        "CRM_Kho_xuat": "Issue WH",
        "CRM_Khoi_la_bat_buoc_nhap": "Division is required",
        "CRM_Khong": "No",
        "CRM_khong_co_du_lieu": "No data",
        "CRM_Khong_co_hang_hoa_moi_nao_duoc_chon": "No new inventory was selected",
        "CRM_Khong_co_thong_bao": "No notifications",
        "CRM_Khong_du_quyen": "Insufficient permissions",
        "CRM_Khong_duoc_de_trong": "Khong_duoc_de_trong",
        "CRM_Khong_duoc_lon_hon_ngay_den": "CRM_Khong_duoc_lon_hon_ngay_den",
        "CRM_Khong_duoc_nho_hon_ngay_tu": "CRM_Khong_duoc_nho_hon_ngay_tu",
        "CRM_Khong_luu": "Not saved",
        "CRM_Khong_su_dung": "Disabled",
        "CRM_Khong_tai_ky_dat_hang": "Khong_tai_ky_dat_hang",
        "CRM_Khong_tim_thay_du_lieu": "No data",
        "CRM_Khong_tim_thay_EOFFICE_URL": "Khong_tim_thay_EOFFICE_URL",
        "CRM_Khong_tim_thay_trang": "PAGE NOT FOUND",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_phai": "No found ItemID in right formula.",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_trai": "No found ItemID in left formula.",
        "CRM_Khong_xet1": "not consider",
        "CRM_Khu_pho": "Street",
        "CRM_Khu_vuc": "Area",
        "CRM_Khu_vuc_nay_da_co_nhan_vien_so_huu": "Khu_vuc_nay_da_co_nhan_vien_so_huu",
        "CRM_Kich_thuoc_cua_kien_nghi_vuot_qua_gioi_han_cho_phep": "CRM_Kich_thuoc_cua_kien_nghi_vuot_qua_gioi_han_cho_phep",
        "CRM_Kich_thuoc_cua_noi_dung_vuot_qua_gioi_han_cho_phep": "CRM_Kich_thuoc_cua_noi_dung_vuot_qua_gioi_han_cho_phep",
        "CRM_Kich_thuoc_vuot_qua_gioi_han_cho_phep": "Size exceeds the allowed limit",
        "CRM_Kiem_tra": "Test",
        "CRM_Kien_nghi": "Kien_nghi",
        "CRM_Kieu_du_lieu": "Data Type",
        "CRM_Kieu_nhap_lieu": "Kieu_nhap_lieu",
        "CRM_Kieu_nhap_lieu_khong_duoc_de_trong": "Kieu_nhap_lieu_khong_duoc_de_trong",
        "CRM_KSD": "Disabled",
        "CRM_Ky_bao_cao": "Report Period",
        "CRM_Ky_chua_import": "Ky_chua_import",
        "CRM_Ky_import": "Kỳ import",
        "CRM_Ky_niem_ngay_thanh_lap": "Ky_niem_ngay_thanh_lap",
        "CRM_La_cong_tac_vien": "La_cong_tac_vien",
        "CRM_La_danh_sach": "Is a list",
        "CRM_La_goc": "Is Root",
        "CRM_Lap_bao_gia": "Quotations",
        "CRM_Lap_don_hang": "Create Purchase Orders",
        "CRM_Lich_su": "History",
        "CRM_Lich_su_chia_se_va_chuyen_chu_so_huu": "Lich_su_chia_se_va_chuyen_chu_so_huu",
        "CRM_Lich_su_chinh_sua": "Lich_su_chinh_sua",
        "CRM_Lich_su_Import": "Import History",
        "CRM_Lich_su_tac_dong": "Transaction History",
        "CRM_Lich_su_thay_doi": "History of change",
        "CRM_Lien_he": "Contact",
        "CRM_Lien_he_chinh": "Lien_he_chinh",
        "CRM_Loai": "Type",
        "CRM_Loai_bao_cao": "Type of report",
        "CRM_Loai_chia_se": "Shared type",
        "CRM_Loai_cong_viec": "Work Types",
        "CRM_Loai_cong_viec_khong_duoc_de_trong": "Loai_cong_viec_khong_duoc_de_trong",
        "CRM_Loai_danh_gia": "Evaluation Type",
        "CRM_Loai_danh_gia_khong_duoc_de_trong": "CRM_Loai_danh_gia_khong_duoc_de_trong",
        "CRM_Loai_dieu_kien": "Condition Type",
        "CRM_Loai_doi_tuong_ap_dung": "Applied Object Types",
        "CRM_Loai_hang": "Classification",
        "CRM_Loai_hang_hoa": "Loai_hang_hoa",
        "CRM_Loai_hoat_dong": "Activity Type",
        "CRM_Loai_mau": "Sample Type",
        "CRM_Loai_quy_trinh": "Workflow type",
        "CRM_Loai_thong_tin_khong_duoc_de_trong": "This type of information cannot be empty",
        "CRM_Loai_thong_tin_quan_ly": "Type of management information",
        "CRM_Loai_thong_tin_tham_chieu": "Type of reference information",
        "CRM_Loai_tien": "CUR",
        "CRM_Loai_tinh_huong": "Kind of situation",
        "CRM_Loc": "Filter",
        "CRM_Loc_chon_hanh_dong": "Select Action",
        "CRM_Loi_chua_xac_dinh": "Error is not determined",
        "CRM_Loi_dieu_kien_loc": "CRM_Loi_dieu_kien_loc",
        "CRM_Loi_khong_lay_duoc_token_CDN": "Error while getting CDN token ",
        "CRM_Luu": "Save",
        "CRM_Luu_lich_su_khong_thanh_cong": "Save failed attempt histrory",
        "CRM_Luu_mat_khau": "Save password",
        "CRM_Luu_quyen_thanh_cong": "Save permission successfully",
        "CRM_Luu_thanh_cong": "Saved successfully",
        "CRM_Luu_thong_tin_chung_tu_khong_thanh_cong": "Document data cannot be saved",
        "CRM_Luu_va_nhap_tiep": "Save and Add New",
        "CRM_Ly_do_chuyen": "Ly_do_chuyen",
        "CRM_Ly_do_dong": "CRM_Ly_do_dong",
        "CRM_Ly_do_dong_bao_gia": "Ly_do_dong_bao_gia",
        "CRM_Ma": "Code",
        "CRM_Ma_403": "Code 403",
        "CRM_Ma_bao_cao": "Report Code",
        "CRM_Ma_chi_tieu": "Criterion code",
        "CRM_Ma_co_cau": "Structure code",
        "CRM_Ma_co_ky_tu_khong_hop_le": "Code with invalid characters.",
        "CRM_Ma_CTV": "Ma_CTV",
        "CRM_Ma_da_ton_tai": "Code already exists",
        "CRM_Ma_danh_gia_khong_ton_tai": "The evaluate id not existed",
        "CRM_Ma_danh_sach": "CRM_Ma_danh_sach",
        "CRM_Ma_dia_ban": "Localities code",
        "CRM_Ma_dia_ban_khong_duoc_de_trong": "Area codes are not left blank",
        "CRM_Ma_doi_tac": "Company Code",
        "CRM_Ma_doi_thu": "Madoi_thu",
        "CRM_Ma_doi_tuong": "Object Code",
        "CRM_Ma_hang": "Item Code",
        "CRM_Ma_hang_hoa": "Ma_hang_hoa",
        "CRM_Ma_khach_hang": "Customer Code",
        "CRM_Ma_kho": "WH Code",
        "CRM_Ma_khong_duoc_de_trong": "CRM_Ma_khong_duoc_de_trong",
        "CRM_Ma_kinh_doanh_da_ton_tai": "Ma_kinh_doanh_da_ton_tai",
        "CRM_Ma_kinh_doanh_khong_duoc_chua_ky_tu_dac_biet": "Ma_kinh_doanh_khong_duoc_chua_ky_tu_dac_biet",
        "CRM_Ma_lien_he": "CRM_Ma_lien_he",
        "CRM_Ma_loai_danh_gia": "Appraisal criterion type code",
        "CRM_Ma_loai_danh_gia_khong_duoc_de_trong": "Review type code may not be blank",
        "CRM_Ma_loai_dieu_kien": "TranTypeID",
        "CRM_Ma_loai_khong_duoc_chua_ky_tu_dac_biet": "Ma_loai_khong_duoc_chua_ky_tu_dac_biet",
        "CRM_Ma_loai_khong_duoc_de_trong": "CRM_Ma_loai_khong_duoc_de_trong",
        "CRM_Ma_loai_thong_tin": "Information Type ID",
        "CRM_Ma_loai_tinh_huong": "CRM_Ma_loai_tinh_huong",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa": "Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_phep_xoa": "Ma_nay_da_duoc_su_dung_ban_khong_phep_xoa",
        "CRM_Ma_nay_da_ton_tai": "This ID has already existed",
        "CRM_Ma_nhan_vien": "Employee ID",
        "CRM_Ma_nhan_vien_khong_duoc_chua_ky_tu_dac_biet": "Ma_nhan_vien_khong_duoc_chua_ky_tu_dac_biet",
        "CRM_Ma_nhan_vien_khong_duoc_de_trong": "Ma_nhan_vien_khong_duoc_de_trong",
        "CRM_Ma_nhan_vien_nay_da_ton_tai": "Ma_nhan_vien_nay_da_ton_tai",
        "CRM_Ma_nhom_hang": "Ma_nhom_hang",
        "CRM_Ma_nhom_kinh_doanh": "Sales Group Code",
        "CRM_Ma_nhom_kinh_doanh_khong_duoc_de_trong": "Ma_nhom_kinh_doanh_khong_duoc_de_trong",
        "CRM_Ma_nhu_cau": "Ma_nhu_cau",
        "CRM_Ma_NVKD": "Sales person ID",
        "CRM_Ma_so_thue": "Tax Codes",
        "CRM_Ma_Ten": "Ma_Ten",
        "CRM_Ma_tinh_huong": "Case No",
        "CRM_Ma_tinh_huong_khong_duoc_de_trong": "Case code is required",
        "CRM_Mac_dinh": "Default",
        "CRM_Mat_hang": "Item",
        "CRM_Mat_hang_chu_luc": "Main Product",
        "CRM_Mat_khau": "Password",
        "CRM_Mat_khau_cu": "Old Password",
        "CRM_Mat_khau_cu_khong_dung": "Old password is wrong",
        "CRM_Mat_khau_khong_duoc_de_trong": "You have enter password",
        "CRM_Mat_khau_moi": "New Password",
        "CRM_Mat_khau_moi_khong_hop_le": "Mat_khau_moi_khong_hop_le",
        "CRM_Mat_khau_xac_nhan_khong_dung": "Password not match",
        "CRM_Mau_bao_cao": "Report Code",
        "CRM_Mau_bao_cao_khong_ton_tai": "CRM_Mau_bao_cao_khong_ton_tai",
        "CRM_Mau_dinh_kem": "Attachment Template",
        "CRM_Mau_giao_dien": "Theme color",
        "CRM_Mau_sac": "Color",
        "CRM_Mo": "Open",
        "CRM_Mo_chuong_trinh": "CRM_Mo_chuong_trinh",
        "CRM_Mo_ta": "Description",
        "CRM_Mo_ta_san_pham": "Mo_ta_san_pham",
        "CRM_MST": "Tax No",
        "CRM_Muc_do_dong_y": "Agreement level",
        "CRM_Nam": "Year",
        "CRM_Nam_thang": "Nam_thang",
        "CRM_Nam1": "Male",
        "CRM_Ngay": "Date",
        "CRM_Ngay_bao_gia": "Quotation Date",
        "CRM_Ngay_bao_gia_khong_duoc_de_trong": "Ngay_bao_gia_khong_duoc_de_trong",
        "CRM_Ngay_bat_dau": "Start date",
        "CRM_Ngay_bat_dau_khong_duoc_lon_hon_ngay_ket_thuc": "Start date cannot be greater then end date.",
        "CRM_Ngay_cap": "Issue date",
        "CRM_Ngay_cap_CMND": "ID Card issue date",
        "CRM_Ngay_cap_ho_chieu": "Passport issue date",
        "CRM_Ngay_cap_nhat": "Date",
        "CRM_Ngay_chinh_sua_cuoi": "Ngay_chinh_sua_cuoi",
        "CRM_Ngay_chung_tuU": "Quotaion date",
        "CRM_Ngay_chuyen": "Transfer date",
        "CRM_Ngay_danh_gia": "Evaluation date",
        "CRM_Ngay_danh_gia_bat_buoc_nhap": "Evaluation date required",
        "CRM_Ngay_danh_gia_khong_duoc_de_trong": "CRM_Ngay_danh_gia_khong_duoc_de_trong",
        "CRM_Ngay_dao_han": "Due Date",
        "CRM_Ngay_den": "Arrival date",
        "CRM_Ngay_den_phai_lon_hon_ngay_tu": "Date(to) must be greater than date(from",
        "CRM_Ngay_dong": "Close Date",
        "CRM_Ngay_dong_khong_duoc_nho_hon_ngay_hieu_luc": "CRM_Ngay_dong_khong_duoc_nho_hon_ngay_hieu_luc",
        "CRM_Ngay_duyet": "Approval date",
        "CRM_Ngay_giao_hang": "Delivery Date",
        "CRM_Ngay_gio": "Date time",
        "CRM_Ngay_gui": "Send Date",
        "CRM_Ngay_het_han": "Expiry date",
        "CRM_Ngay_het_hieu_luc": "Valid Date To",
        "CRM_Ngay_het_hieu_luc_phai_lon_hon_ngay_hieu_luc": "The Effect Date To must be greater than the Effect Date From",
        "CRM_Ngay_hieu_luc": "Valid date",
        "CRM_Ngay_hieu_luc_khong_duoc_de_trong": "Valid date cannot be left blank",
        "CRM_Ngay_hoan_thanh": "Finish Date",
        "CRM_Ngay_hoan_thanh_khong_duoc_de_trong": "Complete Day Not Be Blanked",
        "CRM_Ngay_hoan_thanh_phai_lon_hon_ngay_bat_dau": "CRM_Ngay_hoan_thanh_phai_lon_hon_ngay_bat_dau",
        "CRM_Ngay_ket_thuc": "Finish date",
        "CRM_Ngay_ket_thuc_phai_nho_hon_ngay_bat_dau": "CRM_Ngay_ket_thuc_phai_nho_hon_ngay_bat_dau",
        "CRM_Ngay_lap": "Created date",
        "CRM_Ngay_nghi_viec": "Termination date",
        "CRM_Ngay_nhan": "Received date",
        "CRM_Ngay_phat_sinh": "Trans Date",
        "CRM_Ngay_phieu": "Voucher Date",
        "CRM_Ngay_sinh": "Date of Birth",
        "CRM_Ngay_sua": "Last Modify Date",
        "CRM_Ngay_tac_dong": "Trans Date",
        "CRM_Ngay_tao": "Created date",
        "CRM_Ngay_tao_khach_hang": "Create date",
        "CRM_Ngay_thanh_lap": "Setup Date",
        "CRM_Ngay_them": "CRM_Ngay_them",
        "CRM_Ngay_tinh_no": "As of Date",
        "CRM_Ngay_tu": "Date from",
        "CRM_Ngay_tu/Ngay_den_bat_buoc_nhap": "Date from/Date to is required",
        "CRM_Nghiep_vu": "Transaction",
        "CRM_Ngung_hoat_dong": "Stop activity",
        "CRM_Nguoi_ban_giao_va_nguoi_duoc_ban_giao_phai_khac_nhau": "CRM_Nguoi_ban_giao_va_nguoi_duoc_ban_giao_phai_khac_nhau",
        "CRM_Nguoi_bao_gia": "Nguoi_bao_gia",
        "CRM_Nguoi_cap_nhat": "Login User",
        "CRM_Nguoi_chuyen": "Transfer person",
        "CRM_Nguoi_dai_dien_phap_luat": "Law Agent",
        "CRM_Nguoi_danh_gia": "Evaluation Person",
        "CRM_Nguoi_danh_gia_bat_buoc_nhap": "Evaluation person is required",
        "CRM_Nguoi_dong": "The last person",
        "CRM_Nguoi_dung": "User",
        "CRM_Nguoi_dung_khong_duoc_de_trong": "Nguoi_dung_khong_duoc_de_trong",
        "CRM_Nguoi_dung_khong_su_dung": "User is disabled",
        "CRM_Nguoi_duyet": "Approver",
        "CRM_Nguoi_giai_quyet": "Handler",
        "CRM_Nguoi_gui": "Sender",
        "CRM_Nguoi_lap": "Created by",
        "CRM_Nguoi_lien_he": "Contact",
        "CRM_Nguoi_lien_he_chinh": "Nguoi_lien_he_chinh",
        "CRM_Nguoi_nhan": "Receiver",
        "CRM_Nguoi_phu_trach": "Person in Charge",
        "CRM_Nguoi_sua": "Last Modify User",
        "CRM_Nguoi_tao": "Created by",
        "CRM_Nguoi_them": "CRM_Nguoi_them",
        "CRM_Nguoi_thuc_hien": "Execute Employee",
        "CRM_Nguoi_uy_quyen": "Authorizing person",
        "CRM_Nguon_goc": "Supply Source",
        "CRM_Nguon_them_khach_hang_va_lien_he": "The source for add customer or contact",
        "CRM_Nhan_don_hang": "Order",
        "CRM_Nhan_tiep": "Reception",
        "CRM_Nhan_vien": "Employee",
        "CRM_Nhan_vien_ban_giao": "Nhan_vien_ban_giao",
        "CRM_Nhan_vien_kinh_doanh": "Sales Person",
        "CRM_Nhan_vien_nay_da_duoc_su_dung": "This employee has been used",
        "CRM_Nhan_vien_nhan_ban_giao": "Employees take over",
        "CRM_Nhap": "Text",
        "CRM_Nhap_chuoi": "Nhap_chuoi",
        "CRM_Nhap_it_nhat_1_san_pham": "Nhap_it_nhat_1_san_pham",
        "CRM_Nhap_noi_dung_kien_nghi_can_tim_kiem": "Fill content, recommendations to search",
        "CRM_Nhap_noi_dung_tim_kiem": "Enter content to researching",
        "CRM_Nhap_Ten_Dang_Nhap": "Enter username",
        "CRM_Nhap_tiep": "Next",
        "CRM_Nho_mat_khau1": "Remember me",
        "CRM_Nhom_cong_ty": "Company Group",
        "CRM_Nhom_hang_chu_luc": "Flagship product group",
        "CRM_Nhom_khach_hang": "Customer Group",
        "CRM_Nhom_kinh_doanh": "Sale Group",
        "CRM_Nhom_kinh_doanh_khong_duoc_de_trong": "Nhom_kinh_doanh_khong_duoc_de_trong",
        "CRM_Nhom_nganh_nghe": "Industry Group",
        "CRM_Nhom_phan_loai": "Nhom_phan_loai",
        "CRM_Nhom_phan_quyen": "Nhom_phan_quyen",
        "CRM_Nhom_thue": "VAT Group",
        "CRM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "This feedback will be taken by our company in oder to improve our service quality level and provide better service to our customers.",
        "CRM_Noi_cap": "Issue Place",
        "CRM_Noi_cap_CMND": "ID Card Issue Place",
        "CRM_Noi_dung": "Content",
        "CRM_Noi_dung_can_tim": "Content to searching",
        "CRM_Noi_dung_cong_viec": "Work content",
        "CRM_Noi_dung_khao_sat": "Noi_dung_khao_sat",
        "CRM_Noi_dung_khong_duoc_de_trong": "Content cannot blank",
        "CRM_Noi_dung_tim_kiem": "Search Content",
        "CRM_Noi_giao_hang": "Delivery Location",
        "CRM_Noi_nhan_hang": "Receiving Location",
        "CRM_Nu": "Female",
        "CRM_NVKD": "Sales Person",
        "CRM_Phai_chon_nguoi_ban_giao": "CRM_Phai_chon_nguoi_ban_giao",
        "CRM_Phai_chon_nguoi_duoc_ban_giao": "CRM_Phai_chon_nguoi_duoc_ban_giao",
        "CRM_Phan_he": "Module",
        "CRM_Phan_loai": "Classification",
        "CRM_Phan_loai_doi_tac": "Phan_loai_doi_tac",
        "CRM_Phan_loai_doi_thu": "Type of competitor",
        "CRM_Phan_loai_KH": "Customer classification",
        "CRM_Phan_loai_khach_hang": "Customer Kind",
        "CRM_Phan_loai_nhom": "Phan_loai_nhom",
        "CRM_Phien_ban": "Version",
        "CRM_Phong_ban": "Department",
        "CRM_Phuong": "Ward",
        "CRM_Phuong_phap_tinh_tuoi_no": "CRM_Phuong_phap_tinh_tuoi_no",
        "CRM_Phuong_thuc_giao_hang": "Delivery Methods",
        "CRM_Phuong_thuc_TT": "Payment Method",
        "CRM_Phuong_xa": "Ward",
        "CRM_phut": "minute(s)",
        "CRM_PT_giao_hang": "Delivery Method",
        "CRM_PT_thanh_toan": "Payment Method",
        "CRM_Quan": "District",
        "CRM_Quan_huyen": "District",
        "CRM_Quan_ly": "Management",
        "CRM_Quan_ly_danh_muc": "Management list",
        "CRM_Quay_lai": "Back",
        "CRM_Quoc_gia": "Country",
        "CRM_Quy_cach_dong_goi": "Pallet Size",
        "CRM_Quy_mo_cong_ty": "Company Size",
        "CRM_Quy_uoc_nhu_sau": "Agreed as follows",
        "CRM_Sao_chep_bao_gia": "Copy Pricelist",
        "CRM_Sao_chep_chuong_trinh": "CRM_Sao_chep_chuong_trinh",
        "CRM_Sao_chep_doi_tac": "Sao_chep_doi_tac",
        "CRM_Sao_chep_doi_thu": "Sao_chep_doi_thu",
        "CRM_Sao_chep_khach_hang": "Sao_chep_khach_hang",
        "CRM_Sau_so_ngay": "After number days",
        "CRM_Sinh_nhat": "Birthday",
        "CRM_Skype": "Skype",
        "CRM_SL": "QTY",
        "CRM_SL_co_the_xuat": "Available QTY for Issues",
        "CRM_SL_hoa_don": "Invoice QTY",
        "CRM_SL_ton_thuc_te": "Actual stock QTY",
        "CRM_So_bao_gia": "Quotations No",
        "CRM_So_CCCD_/_CMND": "So_CCCD_/_CMND",
        "CRM_So_chung_tu": "Doc No",
        "CRM_So_di_dong": "Mobile No.",
        "CRM_So_dien_thoai": "Tel no.",
        "CRM_So_dien_thoai_khong_hop_le": "Phone number is invalid",
        "CRM_So_dien_thoai_nguoi_lien_he": "So_dien_thoai_nguoi_lien_he",
        "CRM_So_dien_thoai_trung_voi_lien_he": "The  telephone  to  concurs  the  call  contact ",
        "CRM_So_don_hang": "Sales Order No",
        "CRM_So_dong": "Number of rows",
        "CRM_So_dong_RPC": "Số dòng",
        "CRM_So_dong_tren_mot_trang": "Num of record on page",
        "CRM_So_Fax": "Fax No",
        "CRM_So_lung_den_khong_duoc_de_trong": "CRM_So_lung_den_khong_duoc_de_trong",
        "CRM_So_luong": "Quantity",
        "CRM_So_luong_co_the_dat_ban": "So_luong_co_the_dat_ban",
        "CRM_So_luong_dang_dat_ban": "So_luong_dang_dat_ban",
        "CRM_So_luong_dang_dat_hoa_don_ban": "So_luong_dang_dat_hoa_don_ban",
        "CRM_So_luong_den_phai_lon_hon_so_luong_tu": "Quantity (to) must be greater than quantity (from)",
        "CRM_So_luong_khong_duoc_de_trong": "The quantity is required",
        "CRM_So_luong_QD": "Converted QTY",
        "CRM_So_luong_sau_DC": "Quantity After Adjust",
        "CRM_So_luong_toi_thieu": "MinQuantity",
        "CRM_So_luong_ton_kho": "Stock balance",
        "CRM_So_luong_tu_khong_duoc_de_trong": "CRM_So_luong_tu_khong_duoc_de_trong",
        "CRM_So_luong_tu_phai_nho_hon_so_luong_den": "Quantity From must be smaller than Quantity To",
        "CRM_So_may_ban": "Telephone",
        "CRM_So_tai_khoan": "Account No",
        "CRM_So_thu_tu": "Order No",
        "CRM_Status": "Status",
        "CRM_Store_kiem_tra_bi_loi": "Checking transaction error",
        "CRM_STT": "No.",
        "CRM_Su_dung_phan_loai": "Su_dung_phan_loai",
        "CRM_Su_dung_tai": "Use at",
        "CRM_Sua": "Edit",
        "CRM_Tai_khoan": "Account",
        "CRM_Tai_khoan_bi_khoa_10_phut": "Your account is locked, please try again in 10 minutes",
        "CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Incorrect login or password",
        "CRM_Tai_khoan_khong_co_quyen": "You doo not have the permissioin to access the system.",
        "CRM_Tai_len": "Tai_lieu",
        "CRM_Tai_lieu": "Document",
        "CRM_Tai_lieu_da_dinh_kem": "Document Was Attached",
        "CRM_Tai_lieu_dinh_kem": "Attachments",
        "CRM_Tai_U": "Download",
        "CRM_Tam_dap_ung_Can_cai_tien_them": "Temporarily satisfied, needs improvement",
        "CRM_Tang_dan": "Increase",
        "CRM_Tang_luong_truoc_thoi_han": "Increasing salary ahead of schedule",
        "CRM_Tap_tin": "File",
        "CRM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Attachments in processing",
        "CRM_Tat_ca": "All",
        "CRM_Ten": "Name",
        "CRM_Ten_bao_cao": "Report Name",
        "CRM_Ten_cong_viec": "Task Name",
        "CRM_Ten_CTV": "Ten_CTV",
        "CRM_Ten_Dang_Nhap": "Username",
        "CRM_Ten_dang_nhap_khong_duoc_de_trong": "You have enter account",
        "CRM_Ten_danh_sach": "CRM_Ten_danh_sach",
        "CRM_Ten_danh_sach_khong_duoc_de_trong": "CRM_Ten_danh_sach_khong_duoc_de_trong",
        "CRM_Ten_dia_ban": "Localities name",
        "CRM_Ten_dia_ban_khong_duoc_de_trong": "Area name cannot be blank",
        "CRM_Ten_doi_tac": "Company Name",
        "CRM_Ten_doi_thu": "Tendoi_thu",
        "CRM_Ten_don_vi_tinh": "Unit Name",
        "CRM_Ten_File": "File name",
        "CRM_Ten_hang": "Item Name",
        "CRM_Ten_hang_hoa": "Goods Name",
        "CRM_Ten_khach_hang": "Customer Name",
        "CRM_Ten_kho": "Warehouse name",
        "CRM_Ten_khong_duoc_de_trong": "Name Not Be Blanked",
        "CRM_Ten_lien_he": "CRM_Ten_lien_he",
        "CRM_Ten_loai_danh_gia": "Name type evaluation",
        "CRM_Ten_loai_khong_duoc_de_trong": "Ten_loai_khong_duoc_de_trong",
        "CRM_Ten_loai_thong_tin": "Information Type Name",
        "CRM_Ten_loai_tinh_huong": "CRM_Ten_loai_tinh_huong",
        "CRM_Ten_mau": "Format Name",
        "CRM_Ten_nhom_hang": "Name of commodity group",
        "CRM_Ten_nhom_khach_hang": "Customer group name",
        "CRM_Ten_nhom_kinh_doanh": "Sales Group Name",
        "CRM_Ten_nhom_kinh_doanh_khong_duoc_de_trong": "Ten_nhom_kinh_doanh_khong_duoc_de_trong",
        "CRM_Ten_NVKD": "Sales person name",
        "CRM_Ten_phan_loai_khach_hang": "Ten_phan_loai_khach_hang",
        "CRM_Ten_tat": "Short name",
        "CRM_Ten_tinh_huong": "Situation name",
        "CRM_Ten_truong_thiet_lap": "Ten_truong_thiet_lap",
        "CRM_Test_1": "Test_1",
        "CRM_Tham_so_truyen_vao_khong_hop_le": "Input parameters invalid",
        "CRM_Thang": "Month",
        "CRM_Thang_1": "January",
        "CRM_Thang_10": "October",
        "CRM_Thang_11": "November",
        "CRM_Thang_12": "December",
        "CRM_Thang_2": "February",
        "CRM_Thang_3": "March",
        "CRM_Thang_4": "April",
        "CRM_Thang_5": "May",
        "CRM_Thang_6": "June",
        "CRM_Thang_7": "July",
        "CRM_Thang_8": "August",
        "CRM_Thang_9": "September",
        "CRM_Thang_chua_nop_bao_cao": "Thang_chua_nop_bao_cao",
        "CRM_Thanh_pho": "City",
        "CRM_Thanh_tien": "Amount",
        "CRM_Thanh_tien_QD": "Converted Amount",
        "CRM_Thanh_tien_QD_sau_CK": "Converted After Discount Amount",
        "CRM_Thanh_tien_sau_CK": "Thanh_tien_sau_CK",
        "CRM_Thanh_tien_sau_DC": "Adjust Original",
        "CRM_Thanh_tien_sau_DC_QD": "Adjust Converted",
        "CRM_Thanh_tien_sau_thue": "Amount After Tax",
        "CRM_Thanh_tien_sau_thue_QD": "Convert Amount After Tax",
        "CRM_Thanh_tien_truoc_thue": "Gross amount before tax",
        "CRM_Thao_luan": "Discuss",
        "CRM_Thao_tac": "Action",
        "CRM_Thay_doi_loai_tien_se_tinh_toan_lai_thanh_tien_QD_ban_co_muon_tiep_tuc_khong": "Changing the currency will recalculate to exchange. Do you want to continue ?",
        "CRM_Thay_doi_mat_khau_khong_thanh_cong": "Change password is not successful",
        "CRM_Thay_doi_nghiep_vu_se_xoa_du_lieu_luoi_mat_hang_ban_co_muon_tiep_tuc_khong": "The transaction change will delete the item grid data. Do you want to continue ?",
        "CRM_Them": "Add",
        "CRM_Them_dieu_kien_loc": "CRM_Them_dieu_kien_loc",
        "CRM_Them_dong": "Add row",
        "CRM_Them_hang_hoa": "Add inventory",
        "CRM_Them_hinh_anh_mat_hang": "Them_hinh_anh_mat_hang",
        "CRM_Them_khong_thanh_cong": "Add not successful",
        "CRM_Them_moi": "Add new",
        "CRM_Them_moi_khach_hang": "Add new customer will lose current data. Would you like to continue?",
        "CRM_Them_moi1": "Add new",
        "CRM_Them_nhom_kinh_doanh": "Them_nhom_kinh_doanh",
        "CRM_Them_vao_danh_sach": "CRM_Them_vao_danh_sach",
        "CRM_Theo_nhan_vien": "By employee",
        "CRM_Thiet_lap": "Setup",
        "CRM_Thiet_lap_he_thong": "System Setup",
        "CRM_Thiet_lap_khach_hang_chung": "Setting common customer",
        "CRM_Thiet_lap_mail": "Setup mail",
        "CRM_Thiet_lap_mau_bao_cao": "Setup Report Format",
        "CRM_Thiet_lap_phan_quyen": "Thiet_lap_phan_quyen",
        "CRM_Thiet_lap_thong_tin_Email": "Setup mail",
        "CRM_Thiet_lap_truong_bat_buoc": "Thiet_lap_truong_bat_buoc",
        "CRM_Thiet_lap_truong_bat_buoc_nhap": "Thiet_lap_truong_bat_buoc_nhap",
        "CRM_Thoi_gian": "Time",
        "CRM_Thoi_gian_den_nho_hon_tu": "Thời gian kết thúc phải lớn hơn hoặc bằng thời gian bắt đầu",
        "CRM_Thoi_gian_giao_hang": "Delivery Time",
        "CRM_Thoi_gian_khong_tai_ky_dat_hang": "Thoi_gian_khong_tai_ky_dat_hang",
        "CRM_Thoi_gian_thuc_hien": "Execution Time",
        "CRM_Thoi_gian_tu_lon_hon_den": "Thời gian bắt đầu phải nhỏ hơn hoặc bằng thời gian kết thúc",
        "CRM_Thong_bao": "Announcement",
        "CRM_thong_bao_moi": "New alert",
        "CRM_Thong_bao1": "Alert",
        "CRM_Thong_baoU": "Notifications",
        "CRM_Thong_so_thoi_gian": "Time Parameters",
        "CRM_Thong_tin_bao_gia": "Quotation Information",
        "CRM_Thong_tin_bo_sung": "Supplemental Information",
        "CRM_Thong_tin_ca_nhan": "Personal Information",
        "CRM_Thong_tin_chinh": "Main",
        "CRM_Thong_tin_chung": "General Information",
        "CRM_Thong_tin_cong_ty": "Company Information",
        "CRM_Thong_tin_cung_cap_khong_hop_le": "Provided data invalid",
        "CRM_Thong_tin_don_hang": "Purchase Order Information",
        "CRM_Thong_tin_hang_hoa": "Thong_tin_hang_hoa",
        "CRM_Thong_tin_khach_hang": "Customer Information",
        "CRM_Thong_tin_phu": "Sub Information",
        "CRM_Thong_tin_thue_GTGT": "VAT Information",
        "CRM_Thu_gon": "Collapse",
        "CRM_Thu_tu_hien_thi": "Display order",
        "CRM_Thue": "Tax",
        "CRM_Thue_GTGT": "VAT",
        "CRM_Thue_GTGT_sau_QD": "Thue_GTGT_sau_QD",
        "CRM_Thue_suat": "Tax Rate",
        "CRM_Thue_suat_khong_duoc_lon_hon": "Thue_suat_khong_duoc_lon_hon",
        "CRM_Tien_ban": "CRM_Tien_ban",
        "CRM_Tien_chiet_khau": "Discount Amount",
        "CRM_Tien_CK": "Reduce",
        "CRM_Tien_CK_QD": "Converted Reduce",
        "CRM_Tien_do_bao_gia": "Tien_do_bao_gia",
        "CRM_Tien_do_don_hang": "Order progress",
        "CRM_Tien_hang": "Amount",
        "CRM_Tien_te": "Currency",
        "CRM_Tien_te_khong_duoc_de_trong": "Tien_te_khong_duoc_de_trong",
        "CRM_Tien_thue": "Tax Amount",
        "CRM_Tien_thue_QD": "Converted Tax Amount",
        "CRM_Tieu_de": "Title",
        "CRM_Tieu_de_khong_duoc_de_trong": "CRM_Tieu_de_khong_duoc_de_trong",
        "CRM_Tieu_de_mail": "Mail Title",
        "CRM_Tieu_thuc_nhom": "Grouping Criteria",
        "CRM_Tim_kiem": "Finding Information",
        "CRM_Tim_kiem_khach_hang": "CRM_Tim_kiem_khach_hang",
        "CRM_Tim_kiem_lien_he": "CRM_Tim_kiem_lien_he",
        "CRM_Tim_kiem_nang_cao": "Advanced search",
        "CRM_Time_from_>_time_to": "Thời gian bắt đầu phải lớn hơn thời gian kết thúc",
        "CRM_Tinh": "Calculate",
        "CRM_Tinh_huong": "Cases",
        "CRM_Tinh_thanh_pho": "Province, city  ",
        "CRM_To_chuc": "Organization",
        "CRM_Ton_tai_chi_tieu_co_gia_tri_khong_hop_le": "Value of ItemID is not valid.",
        "CRM_Tong": "Total",
        "CRM_Tong_chi_tieu_cua_nhom_khong_duoc_nho_hon_chi_tieu_chi_tiet": "Tong_chi_tieu_cua_nhom_khong_duoc_nho_hon_chi_tieu_chi_tiet",
        "CRM_Tong_diem": "Total points",
        "CRM_Tong_gia_tri_A": "Sum A",
        "CRM_Tong_gia_tri_A_khong_bang_tong_gia_tri_B": "Sum A not equal sum B",
        "CRM_Tong_gia_tri_B": "Sum B",
        "CRM_Tong_gia_tri_bao_gia": "Tong_gia_tri_bao_gia",
        "CRM_Tong_gia_tri_don_hang": "Total order value",
        "CRM_Tong_gia_tri_don_hang_QD": "Total value of orders converted",
        "CRM_Tong_hop": "Summary",
        "CRM_Tong_hop_khach_hang_moi": "Tong_hop_khach_hang_moi",
        "CRM_Tong_so_luong_bao_gia": "Tong_so_luong_bao_gia",
        "CRM_Tong_thanh_toan": "Total payment",
        "CRM_Tong_thanh_toan_QD": "Total Converted Amount",
        "CRM_Tong_tien": "Total",
        "CRM_Tong_tien_QD": "Total C.Amount",
        "CRM_Tong_tien_sau_DC": "Total Adjust Original",
        "CRM_Tong_tien_sau_DC_QD": "Total Adjust Converted",
        "CRM_Trang": "Page",
        "CRM_Trang_Chu": "Home Page",
        "CRM_Trang_thai": "Status",
        "CRM_Trang_thai_chi_tiet": "detail  status",
        "CRM_Trang_thai_cu": "Old state",
        "CRM_Trang_thai_KH": "Prod Plan Status",
        "CRM_Trang_thai_khach_hang": "Status of Customer ",
        "CRM_Trang_thai_moi": "New Status",
        "CRM_Trang_thai_thuc_hien": "Executive Status",
        "CRM_Trang_thai_thuc_hien_DH": "Order execution status",
        "CRM_Trang_thai_vao_ra": "In-out status",
        "CRM_Tre_han": "Late",
        "CRM_Trinh_duyet_khong_co_quyen_mo_popup": "Not enough permissions to open popup",
        "CRM_Tron_truong": "Mix Fields",
        "CRM_trong": "empty",
        "CRM_Truc_thuoc_nhom": "Truc_thuoc_nhom",
        "CRM_Truong_thiet_lap": "Truong_thiet_lap",
        "CRM_Truy_cap_khong_hop_le": "Invalid access",
        "CRM_Tu": "From",
        "CRM_Tu_choi": "Reject",
        "CRM_Tu_ngay": "Date from",
        "CRM_Tuy_chon_cong_ty": "Tuy_chon_cong_ty",
        "CRM_Ty_gia": "Exchange Rate",
        "CRM_Ty_le_chiet_khau": "Discount Rate",
        "CRM_Ty_le_CK": "Discount %",
        "CRM_Ty_le_qua_han": "CRM_Ty_le_qua_han",
        "CRM_Vao_luc": "Time",
        "CRM_Vao_ngay": "CRM_Vao_ngay",
        "CRM_Vi_tri_dia_ly": "Location",
        "CRM_Viet_binh_luan": "CRM_Viet_binh_luan",
        "CRM_VoucherID_khong_duoc_de_trong": "VoucherID cannot be blank",
        "CRM_Vui_long_chon_hinh_anh": "Vui_long_chon_hinh_anh",
        "CRM_Vui_long_chon_ma_hang_va_don_vi_tinh": "Please select  unitID and inventoryID",
        "CRM_Vui_long_nhap_day_du_don_gia_truoc_thue_va_so_luong_hang_hoa": "Please enter the full unit price before tax and quantity of goods",
        "CRM_Vui_long_nhap_day_du_thong_tin": "Please enter full information",
        "CRM_Vui_long_truy_cap_sau": "Vui_long_truy_cap_sau",
        "CRM_Xa": "Ward",
        "CRM_Xac_nhan_mat_khau_moi": "Confirm New Password",
        "CRM_XaPhuong": "ConAddressWard",
        "CRM_Xem": "View",
        "CRM_Xem_bao_cao": "View Report",
        "CRM_Xem_it_hon": "View least",
        "CRM_Xem_nhieu_hon": "View full",
        "CRM_Xem_tap_tin": "View files",
        "CRM_Xem_tat_ca": "Search all",
        "CRM_Xem_them": "See more",
        "CRM_Xem_truoc": "Preview",
        "CRM_Xin_cam_on": "Xin_cam_on",
        "CRM_Xoa": "Delete",
        "CRM_Xoa_khong_thanh_cong": "Delete not successful!",
        "CRM_Xoa_quyen_thanh_cong": "Delete permission successfully",
        "CRM_Xuat_bao_cao": "Export report",
        "CRM_Xuat_danh_sach_khach_hang": "CRM_Xuat_danh_sach_khach_hang",
        "CRM_Xuat_danh_sach_lien_he": "CRM_Xuat_danh_sach_lien_he",
        "CRM_Xuat_du_lieu": "Export Data",
        "CRM_Xuat_Excel": "Export To Excel",
        "CRM_Xuat_khau_khach_hang": "Xuat_khau_khach_hang",
        "CRM_Xuat_PDF": "Export in PDF",
        "CRM_Xuat_word": "Eport in Word",
        "CRM_Y_kien_dong_gop": "Feedback",
        "CRM_Yeu_cau_chuyen_doi": "Conversion request",
        "CRM_Yeu_cau_chuyen_doi_thanh_cong": "Conversion request success"
    },
    "ja": {
        "CRM_403_loi": "",
        "CRM_404_loi": "",
        "CRM_AC_vui_long_chon_y_kien_cua_minh": "",
        "CRM_Ap": "",
        "CRM_Ap_Khu_pho": "",
        "CRM_App": "",
        "CRM_Ban_chua_chon_chu_so_huu": "",
        "CRM_Ban_chua_chon_hang_hoa": "",
        "CRM_Ban_chua_chon_khach_hang": "",
        "CRM_Ban_chua_chon_lien_he": "",
        "CRM_Ban_chua_chon_mau_bao_cao": "",
        "CRM_Ban_chua_chon_ngay_den": "",
        "CRM_Ban_chua_chon_nhom_hang": "",
        "CRM_Ban_chua_chon_truong_thiet_lap_bat_buoc_nhap": "",
        "CRM_Ban_chua_co_thong_bao_nao": "",
        "CRM_Ban_chua_nhap_chi_tieu_doanh_so": "",
        "CRM_Ban_chua_nhap_chu_de_cua_Email": "",
        "CRM_Ban_chua_nhap_Email_Bcc": "",
        "CRM_Ban_chua_nhap_Email_Cc": "",
        "CRM_Ban_chua_nhap_Email_nguoi_nhan": "",
        "CRM_Ban_chua_nhap_gia_tri": "",
        "CRM_Ban_chua_nhap_noi_dung_Email": "",
        "CRM_Ban_chua_nhap_truong:_%p_Ban_co_muon_luu_khong?": "",
        "CRM_Ban_chua_thiet_lap_Mau_bao_cao": "",
        "CRM_Ban_co": "",
        "CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay": "",
        "CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "",
        "CRM_Ban_co_muon_chia_se_bao_gia_nay": "",
        "CRM_Ban_co_muon_dong_bao gia": "",
        "CRM_Ban_co_muon_mo_lai_bao_gia": "",
        "CRM_Ban_co_muon_nhap_tiep_khong": "",
        "CRM_Ban_co_muon_xoa_quyen_cua_nhan_vien": "",
        "CRM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "",
        "CRM_Ban_giao": "",
        "CRM_Ban_giao_cho": "",
        "CRM_Ban_khong_co_quyen_truy_cap": "",
        "CRM_Ban_khong_co_quyen_truy_cap_Module_nay": "",
        "CRM_Ban_phai_chon_doanh_nghiep": "",
        "CRM_Ban_phai_chon_don_vi_ban": "",
        "CRM_Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang": "",
        "CRM_Ban_phai_chuyen_khach_hang_thanh_doi_tuong": "",
        "CRM_Bang_bao_gia": "",
        "CRM_Bang_gia": "価格表",
        "CRM_Bang_gia_khong_duoc_de_trong": "",
        "CRM_Bao_cao": "",
        "CRM_Bao_cao_chi_tiet_cong_viec": "",
        "CRM_Bao_cao_hien_thi": "",
        "CRM_Bao_cao_hoat_dong": "",
        "CRM_Bao_cao_hoat_dong_theo_khach_hang": "",
        "CRM_Bao_cao_khach_hang_theo_ma_hang": "",
        "CRM_Bao_cao_phan_tich_tien_thu_ban_hang": "領収書分析レポート",
        "CRM_Bao_cao_so_lieu_hoat_dong": "",
        "CRM_Bao_cao_thong_ke_khach_hang": "",
        "CRM_Bao_gia": "引用",
        "CRM_Bao_gia_da_duyet": "",
        "CRM_Bao_gia_duoc_dong": "",
        "CRM_Bat_buoc_nhap": "",
        "CRM_Bo_duyet": "",
        "CRM_Bo_duyet_thanh_cong": "",
        "CRM_Bo_phan": "",
        "CRM_Boi": "",
        "CRM_Ca_nam": "",
        "CRM_Ca_nhan": "Personal",
        "CRM_Cac_chi_tieu_DK_A": "",
        "CRM_Cac_chi_tieu_DK_B": "",
        "CRM_Cac_gop_y_khac": "",
        "CRM_Cac_tham_so_la_bat_buoc": "",
        "CRM_Cac_thong_so_luong": "",
        "CRM_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "CRM_Cai_dat": "",
        "CRM_Canh_bao_nhap": "",
        "CRM_Cap_co_cau": "",
        "CRM_Cap_do": "",
        "CRM_Cap_nhat_danh_gia": "",
        "CRM_Cap_nhat_danh_muc": "",
        "CRM_Cap_nhat_danh_sach_khach_hang": "",
        "CRM_Cap_nhat_dia_ban": "",
        "CRM_Cap_nhat_ghi_nhan_nhu_cau_khach_hang": "",
        "CRM_Cap_nhat_hoat_dong": "",
        "CRM_Cap_nhat_lien_he": "",
        "CRM_Cap_nhat_loai_danh_gia": "",
        "CRM_Cap_nhat_loai_tinh_huong": "",
        "CRM_Cap_nhat_ma_hang": "",
        "CRM_Cap_nhat_mau_bao_cao": "",
        "CRM_Cap_nhat_ngon_ngu": "",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh": "",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh1": "",
        "CRM_Cap_nhat_nhom_khach_hang": "",
        "CRM_Cap_nhat_thong_tin_bo_sung": "",
        "CRM_Cap_nhat_thong_tin_giao_hang": "",
        "CRM_Cap_nhat_tinh_huong": "",
        "CRM_Cap_nhat_trang_thai": "",
        "CRM_Cap_nhat_trang_thai_KH": "",
        "CRM_Cap_nhat_trang_thai_thuc_hien_DH": "",
        "CRM_Chap_nhan_don_hang": "",
        "CRM_Chi_nhanh_ngan_hang": "",
        "CRM_Chi_tiet": "詳細",
        "CRM_Chi_tiet_bao_gia": "",
        "CRM_Chi_tiet_don_hang": "",
        "CRM_Chi_tiet_loi": "",
        "CRM_Chi_tiet_nguoi_lien_he": "",
        "CRM_Chi_tiet_theo": "",
        "CRM_Chi_tieu": "Target",
        "CRM_Chi_tieu_chi_tiet_vuot_tong_chi_tieu_cua_nhom": "",
        "CRM_Chi_tieu_doanh_so": "",
        "CRM_Chia_se": "",
        "CRM_Chia_se_doi_tac": "",
        "CRM_Chia_se_doi_thu": "",
        "CRM_Chia_se_khach_hang": "",
        "CRM_Chiet_khau": "",
        "CRM_Chiet_khau_khong_duoc_lon_hon": "",
        "CRM_Chinh_sua_khong_thanh_cong": "",
        "CRM_Chinh_sua_thanh_cong": "",
        "CRM_Cho_duyet": "承認待ち",
        "CRM_Chon": "Select",
        "CRM_Chon_combo": "",
        "CRM_Chon_gia_tri": "",
        "CRM_Chon_hang": "",
        "CRM_Chon_hang_hoa": "",
        "CRM_Chon_hinh": "",
        "CRM_Chon_kenh": "",
        "CRM_Chon_khach_hang": "",
        "CRM_Chon_loai_tinh_huong": "",
        "CRM_Chon_mau_bao_cao": "",
        "CRM_Chon_nguoi_giai_quyet": "",
        "CRM_Chon_nhom_hang": "",
        "CRM_Chon_tap_tin": "",
        "CRM_Chon_tat_ca": "",
        "CRM_Chon_tinh": "",
        "CRM_Chon_trang_thai": "",
        "CRM_Chua_co_lien_ket_nao": "",
        "CRM_Chua_dap_ung": "",
        "CRM_Chua_doc": "",
        "CRM_Chua_thuc_hien": "",
        "CRM_Chuc_danh": "",
        "CRM_Chuc_nang_chua_phat_trien": "",
        "CRM_Chuc_nang_dang_duoc_phat_trien": "",
        "CRM_Chuc_nang_them_moi": "",
        "CRM_Chuc_vu": "位置",
        "CRM_Chuc_vu_bat_buoc_nhap": "",
        "CRM_Chuong_trinh_cham_soc_khach_hang": "",
        "CRM_Chuyen_chu_so_huu": "",
        "CRM_Chuyen_doi_trang_thai": "",
        "CRM_CMR_Khong_tim_thay_trang": "",
        "CRM_Co_cau_cha": "",
        "CRM_Co_cau_kinh_doanh": "",
        "CRM_Co_cau_to_chuc": "",
        "CRM_Co_it_nhat_mot_chu_cai": "",
        "CRM_Co_it_nhat_mot_chu_cai_in_hoa": "",
        "CRM_Co_it_nhat_mot_so": "",
        "CRM_Co_it_nhat_sau_ky_tu": "",
        "CRM_Co_loi_khi_luu": "",
        "CRM_Co_loi_khi_luu_lich_su": "",
        "CRM_Co_loi_khi_tim_kiem": "",
        "CRM_Co_loi_khi_xoa": "",
        "CRM_Co_loi_khi_xoa_dinh_kem": "",
        "CRM_Co_loi_khi_xoa_file_bao_cao": "",
        "CRM_Co_loi_trong_qua_trinh_gui_Mail": "",
        "CRM_Co_loi_trong_qua_trinh_luu_File": "",
        "CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu": "",
        "CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "CRM_Co1": "",
        "CRM_Cong_tac_vien": "",
        "CRM_Cong_thuc_ton_tai_chi_tieu_chia_cho_0": "",
        "CRM_Cong_trinh/Cong_ty": "",
        "CRM_Cong_ty": "会社",
        "CRM_Cong_ty_cong_tac": "",
        "CRM_Cong_viec": "仕事",
        "CRM_Cot_gia_tri": "",
        "CRM_Cua_bo_phan": "",
        "CRM_Cua_hang_hoa": "",
        "CRM_cua_khach_hang": "",
        "CRM_Cua_toi": "",
        "CRM_Da_co_mat_hang": "",
        "CRM_Da_dong": "",
        "CRM_Da_duyet": "承認された",
        "CRM_Da_hoan_thanh": "",
        "CRM_Da_nghi_viec": "",
        "CRM_Dang_cong_tac": "",
        "CRM_Dang_duyet": "承認待ち",
        "CRM_Dang_Lam_Viec": "",
        "CRM_Dang_nhap": "ログイン",
        "CRM_dang_nhap_lai": "再度ログオンを",
        "CRM_Dang_nhap_lai2": "",
        "CRM_Dang_tai": "",
        "CRM_Dang_thuc_hien": "",
        "CRM_Dang_xuat": "",
        "CRM_Dang_xuat1": "",
        "CRM_Danh_dau_tat_ca_la_da_doc": "",
        "CRM_Danh_gia": "",
        "CRM_Danh_muc/Phan_loai_khach_hang": "",
        "CRM_Danh_muc_danh_gia": "",
        "CRM_Danh_muc_Loai_tinh_huong": "",
        "CRM_Danh_muc_trang_thai_lam_viec": "",
        "CRM_Danh_sach": "",
        "CRM_Danh_sach_bao_cao": "",
        "CRM_Danh_sach_bao_gia": "",
        "CRM_Danh_sach_bo_loc": "",
        "CRM_Danh_sach_chuong_trinh_cham_soc": "",
        "CRM_Danh_sach_cong_tac_vien": "",
        "CRM_Danh_sach_danh_gia_chung": "",
        "CRM_Danh_sach_dia_ban": "",
        "CRM_Danh_sach_doi_tac": "",
        "CRM_Danh_sach_doi_thu": "",
        "CRM_Danh_sach_don_hang": "",
        "CRM_Danh_sach_file_dinh_kem": "Attachments list",
        "CRM_Danh_sach_khach_hang": "",
        "CRM_Danh_sach_khach_hang_dung_chung": "",
        "CRM_Danh_sach_lich_su_chia_se_va_chuyen_chu_so_huu": "",
        "CRM_Danh_sach_lich_su_gui_mail": "",
        "CRM_Danh_sach_lich_su_thay_doi": "",
        "CRM_Danh_sach_lien_he": "",
        "CRM_Danh_sach_mat_hang": "",
        "CRM_Danh_sach_nhan_vien_kinh_doanh": "",
        "CRM_Danh_sach_sinh_nhat_cua_nguoi_lien_he": "",
        "CRM_Danh_sach_thong_tin_bo_sung": "",
        "CRM_Danh_sach_tinh_huong": "",
        "CRM_Dap_ung": "",
        "CRM_Date_input_format_invalid": "",
        "CRM_Den": "まで",
        "CRM_Den_ngay": "Date to",
        "CRM_DG_goc": "",
        "CRM_DG_truoc_thue": "",
        "CRM_Di_dong": "携帯電話",
        "CRM_Dia_ban": "",
        "CRM_Dia_chi": "住所",
        "CRM_Dia_chi_DKKD": "",
        "CRM_Dia_chi_doi_tuong_thue": "",
        "CRM_Dia_chi_giao_dich": "",
        "CRM_Dia_chi_giao_hang": "",
        "CRM_Dia_chi_IP": "",
        "CRM_Dia_chi_khach_hang": "",
        "CRM_Dia_chi_khong_duoc_de_trong": "",
        "CRM_Dia_chi_lien_he": "Contact Address",
        "CRM_Dia_chi_thuong_tru": "本籍地",
        "CRM_Dia_diem": "",
        "CRM_Dia_diem_giao_dich": "",
        "CRM_Dia_diem_giao_hang": "",
        "CRM_Dien_dai": "",
        "CRM_Dien_giai": "説明",
        "CRM_Dien_thoai": "電話",
        "CRM_Dien_thoai_cong_ty": "",
        "CRM_Dien_thoai_di_dong": "携帯電話",
        "CRM_Dieu_chinh": "",
        "CRM_Dieu_khoan_thuong_mai": "",
        "CRM_Dieu_khoan_TM": "",
        "CRM_Dieu_kien": "Filter",
        "CRM_Dieu_kien_loc": "選択基準",
        "CRM_Dieu_kien_loc_chi_tiet": "",
        "CRM_Dieu_kien_loc_khong_hop_le": "",
        "CRM_Dieu_kien_loc_mac_dinh": "",
        "CRM_Dinh_dang_email_khong_hop_le": "",
        "CRM_Dinh_dang_file_khong_hop_le": "",
        "CRM_Dinh_kem": "Attachments",
        "CRM_Dinh_kem_hinh_anh": "",
        "CRM_Do_dai_mat_khau_khong_hop_le": "",
        "CRM_Do_uu_tien": "",
        "CRM_Doanh_so": "",
        "CRM_Doanh_thu": "",
        "CRM_Doanh_thu_nam": "",
        "CRM_Doi_mat_khau": "",
        "CRM_Doi_mat_khau_thanh_cong": "",
        "CRM_Doi_tac": "",
        "CRM_Doi_thu": "",
        "CRM_Doi_tuong": "オブジェクト",
        "CRM_Doi_tuong_ap_dung": "",
        "CRM_Doi_tuong_QLCN": "",
        "CRM_Doi_tuong_thue": "",
        "CRM_Don_gia": "価格",
        "CRM_Don_gia_ban": "",
        "CRM_Don_gia_sau_DC": "",
        "CRM_Don_gia_sau_thue": "",
        "CRM_Don_gia_truoc_thue": "",
        "CRM_Don_hang": "",
        "CRM_Don_vi": "単位",
        "CRM_Don_vi_ban": "",
        "CRM_Don_vi_tinh": "",
        "CRM_Dong_bao_gia": "",
        "CRM_Dong_bao_gia_thanh_cong": "",
        "CRM_Dong_chuong_trinh": "",
        "CRM_Dong_khong_thanh_cong": "",
        "CRM_Dong_y": "",
        "CRM_Dong1": "",
        "CRM_Du_co_cuoi_ky": "",
        "CRM_Du_co_dau_ky": "",
        "CRM_Du_lieu": "データ",
        "CRM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data not saved. Do you want to save?",
        "CRM_Du_lieu_co_thay_doi_ban_co_muon_luu_khong": "",
        "CRM_Du_lieu_da_duoc_import_dang_o_trang_thai_Chua_duyet_hoac_Tu_choi_Ban_co_muon_tiep_tuc_khong": "",
        "CRM_Du_lieu_da_duoc_su_dung": "",
        "CRM_Du_lieu_da_xoa_thanh_cong": "",
        "CRM_Du_lieu_dinh_kem": "",
        "CRM_Du_lieu_import_bi_loi": "",
        "CRM_Du_lieu_tham_chieu": "",
        "CRM_Du_no_cuoi_ky": "",
        "CRM_Du_no_dau_ky": "",
        "CRM_Dung_luong": "",
        "CRM_Dung_luong_File_khong_duoc_lon_hon": "",
        "CRM_Duong_dan": "",
        "CRM_Duyet": "承認",
        "CRM_Duyet_thanh_cong": "",
        "CRM_DVT": "単位",
        "CRM_Email": "メール",
        "CRM_Email_ca_nhan": "",
        "CRM_Email_cong_ty": "",
        "CRM_Email_nguoi_gui": "",
        "CRM_Email_nguoi_nhan": "",
        "CRM_Facebook": "",
        "CRM_File_Import_khong_hop_le": "",
        "CRM_Ghi_chu": " 言い草",
        "CRM_Ghi_chu_duyet": "Approve Notes",
        "CRM_Ghi_chu_KH": "",
        "CRM_Ghi_chu_noi_bo": "",
        "CRM_Ghi_nhan_nhu_cau": "",
        "CRM_Gia_goc": "",
        "CRM_Gia_tri_bat_buoc_nhap": "",
        "CRM_Gia_tri_khong_hop_le": "",
        "CRM_Giai_phap": "",
        "CRM_Giam_dan": "",
        "CRM_Giao_hang": "配達",
        "CRM_Giay": "",
        "CRM_Giay_CN_DKKD": "",
        "CRM_Gioi_tinh": "性別",
        "CRM_Gioi_tinh_khong_duoc_de_trong": "",
        "CRM_Gui": "Send",
        "CRM_Gui_mail": "",
        "CRM_Gui_thanh_cong": "",
        "CRM_Hai_long_can_cai_tien_them": "",
        "CRM_Han_ket_thuc": "",
        "CRM_Han_xu_ly": "",
        "CRM_Han_xu_ly_khong_duoc_de_trong": "",
        "CRM_Han_xu_ly_phai_lon_hon_ngay_bat_dau": "",
        "CRM_Hang_hoa": "",
        "CRM_Hang_hoa_da_chon": "",
        "CRM_Hang_hoa_nay_da_ton_tai": "",
        "CRM_Hanh_dong": "",
        "CRM_He_thong": "システム",
        "CRM_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "CRM_Het_dong": "",
        "CRM_Hien_thi_chi_tiet": "",
        "CRM_Hien_thi_chi_tieu_da_huy": "",
        "CRM_Hien_thi_danh_sach_khong_su_dung": "",
        "CRM_Hien_thi_khong_su_dung": "",
        "CRM_Hien_thi_ngung_hoat_dong": "",
        "CRM_Hien_thi_so_luong_bang_0": "",
        "CRM_Hien_thi_voi_moi_nguoi": "",
        "CRM_Hieu_luc_den": "への有効",
        "CRM_Hieu_luc_den_phai_lon_hon_hieu_luc_tu": "",
        "CRM_Hieu_luc_tu": "から有効な",
        "CRM_Ho_chieu": "",
        "CRM_Ho_ten": "",
        "CRM_Ho_va_ten": "姓名",
        "CRM_Ho_va_ten_khong_duoc_de_trong": "",
        "CRM_Ho_va_ten_nguoi_lien_he": "",
        "CRM_Hoan_toan_hai_long": "",
        "CRM_Hoat_dong": "",
        "CRM_Hoat_dong_cua_toi": "",
        "CRM_Hom_nay": "今日",
        "CRM_Huy": "Cancel",
        "CRM_Huy_bo": "",
        "CRM_Huyen": "",
        "CRM_Import_bao_cao": "",
        "CRM_Import_du_lieu": "",
        "CRM_In": "Print",
        "CRM_In1": "Print",
        "CRM_Kenh_gop_y": "",
        "CRM_Kenh_truyen_thong": "",
        "CRM_Kenh_truyen_thong_khong_duoc_de_trong": "",
        "CRM_Ket_qua": "Result",
        "CRM_Khac": "その他",
        "CRM_Khach_hang": "顧客",
        "CRM_Khach_hang_da_phat_sinh_doat_dong_ban_khong_duoc_phep_xoa": "",
        "CRM_Khach_hang_khong_duoc_de_trong": "",
        "CRM_Khach_hang_la_nguoi_lien_he": "",
        "CRM_Khach_hang_va_lien_he": "",
        "CRM_Khao_sat_y_kien_khach_hang": "",
        "CRM_Kho": "",
        "CRM_Kho_xuat": "",
        "CRM_Khoi_la_bat_buoc_nhap": "",
        "CRM_Khong": "いいえ",
        "CRM_khong_co_du_lieu": "",
        "CRM_Khong_co_hang_hoa_moi_nao_duoc_chon": "",
        "CRM_Khong_co_thong_bao": "",
        "CRM_Khong_du_quyen": "",
        "CRM_Khong_duoc_de_trong": "",
        "CRM_Khong_duoc_lon_hon_ngay_den": "",
        "CRM_Khong_duoc_nho_hon_ngay_tu": "",
        "CRM_Khong_luu": "Not saved",
        "CRM_Khong_su_dung": "非活動",
        "CRM_Khong_tai_ky_dat_hang": "",
        "CRM_Khong_tim_thay_du_lieu": "",
        "CRM_Khong_tim_thay_EOFFICE_URL": "",
        "CRM_Khong_tim_thay_trang": "",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_phai": "",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_trai": "",
        "CRM_Khong_xet1": "",
        "CRM_Khu_pho": "",
        "CRM_Khu_vuc": "",
        "CRM_Khu_vuc_nay_da_co_nhan_vien_so_huu": "",
        "CRM_Kich_thuoc_cua_kien_nghi_vuot_qua_gioi_han_cho_phep": "",
        "CRM_Kich_thuoc_cua_noi_dung_vuot_qua_gioi_han_cho_phep": "",
        "CRM_Kich_thuoc_vuot_qua_gioi_han_cho_phep": "",
        "CRM_Kiem_tra": "",
        "CRM_Kien_nghi": "",
        "CRM_Kieu_du_lieu": "",
        "CRM_Kieu_nhap_lieu": "",
        "CRM_Kieu_nhap_lieu_khong_duoc_de_trong": "",
        "CRM_KSD": "非活動",
        "CRM_Ky_bao_cao": "",
        "CRM_Ky_chua_import": "",
        "CRM_Ky_import": "",
        "CRM_Ky_niem_ngay_thanh_lap": "",
        "CRM_La_cong_tac_vien": "",
        "CRM_La_danh_sach": "",
        "CRM_La_goc": "",
        "CRM_Lap_bao_gia": "",
        "CRM_Lap_don_hang": "",
        "CRM_Lich_su": "歴史",
        "CRM_Lich_su_chia_se_va_chuyen_chu_so_huu": "",
        "CRM_Lich_su_chinh_sua": "",
        "CRM_Lich_su_Import": "",
        "CRM_Lich_su_tac_dong": "",
        "CRM_Lich_su_thay_doi": "",
        "CRM_Lien_he": "",
        "CRM_Lien_he_chinh": "",
        "CRM_Loai": "",
        "CRM_Loai_bao_cao": "",
        "CRM_Loai_chia_se": "",
        "CRM_Loai_cong_viec": "",
        "CRM_Loai_cong_viec_khong_duoc_de_trong": "",
        "CRM_Loai_danh_gia": "",
        "CRM_Loai_danh_gia_khong_duoc_de_trong": "",
        "CRM_Loai_dieu_kien": "",
        "CRM_Loai_doi_tuong_ap_dung": "",
        "CRM_Loai_hang": "",
        "CRM_Loai_hang_hoa": "",
        "CRM_Loai_hoat_dong": "",
        "CRM_Loai_mau": "",
        "CRM_Loai_quy_trinh": "",
        "CRM_Loai_thong_tin_khong_duoc_de_trong": "",
        "CRM_Loai_thong_tin_quan_ly": "",
        "CRM_Loai_thong_tin_tham_chieu": "",
        "CRM_Loai_tien": "通貨",
        "CRM_Loai_tinh_huong": "",
        "CRM_Loc": "フィルター",
        "CRM_Loc_chon_hanh_dong": "",
        "CRM_Loi_chua_xac_dinh": "",
        "CRM_Loi_dieu_kien_loc": "",
        "CRM_Loi_khong_lay_duoc_token_CDN": "",
        "CRM_Luu": "保存",
        "CRM_Luu_lich_su_khong_thanh_cong": "",
        "CRM_Luu_mat_khau": "",
        "CRM_Luu_quyen_thanh_cong": "",
        "CRM_Luu_thanh_cong": "",
        "CRM_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "CRM_Luu_va_nhap_tiep": "",
        "CRM_Ly_do_chuyen": "",
        "CRM_Ly_do_dong": "",
        "CRM_Ly_do_dong_bao_gia": "",
        "CRM_Ma": "Code",
        "CRM_Ma_403": "",
        "CRM_Ma_bao_cao": "Report Code",
        "CRM_Ma_chi_tieu": "",
        "CRM_Ma_co_cau": "",
        "CRM_Ma_co_ky_tu_khong_hop_le": "",
        "CRM_Ma_CTV": "",
        "CRM_Ma_da_ton_tai": "",
        "CRM_Ma_danh_gia_khong_ton_tai": "",
        "CRM_Ma_danh_sach": "",
        "CRM_Ma_dia_ban": "",
        "CRM_Ma_dia_ban_khong_duoc_de_trong": "",
        "CRM_Ma_doi_tac": "",
        "CRM_Ma_doi_thu": "",
        "CRM_Ma_doi_tuong": "オブジェクトコード",
        "CRM_Ma_hang": "アイテムコード",
        "CRM_Ma_hang_hoa": "",
        "CRM_Ma_khach_hang": "",
        "CRM_Ma_kho": "倉庫コード",
        "CRM_Ma_khong_duoc_de_trong": "",
        "CRM_Ma_kinh_doanh_da_ton_tai": "",
        "CRM_Ma_kinh_doanh_khong_duoc_chua_ky_tu_dac_biet": "",
        "CRM_Ma_lien_he": "",
        "CRM_Ma_loai_danh_gia": "",
        "CRM_Ma_loai_danh_gia_khong_duoc_de_trong": "",
        "CRM_Ma_loai_dieu_kien": "",
        "CRM_Ma_loai_khong_duoc_chua_ky_tu_dac_biet": "",
        "CRM_Ma_loai_khong_duoc_de_trong": "",
        "CRM_Ma_loai_thong_tin": "",
        "CRM_Ma_loai_tinh_huong": "",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa": "",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_phep_xoa": "",
        "CRM_Ma_nay_da_ton_tai": "",
        "CRM_Ma_nhan_vien": "従業員コード",
        "CRM_Ma_nhan_vien_khong_duoc_chua_ky_tu_dac_biet": "",
        "CRM_Ma_nhan_vien_khong_duoc_de_trong": "",
        "CRM_Ma_nhan_vien_nay_da_ton_tai": "",
        "CRM_Ma_nhom_hang": "",
        "CRM_Ma_nhom_kinh_doanh": "",
        "CRM_Ma_nhom_kinh_doanh_khong_duoc_de_trong": "",
        "CRM_Ma_nhu_cau": "",
        "CRM_Ma_NVKD": "",
        "CRM_Ma_so_thue": "",
        "CRM_Ma_Ten": "",
        "CRM_Ma_tinh_huong": "",
        "CRM_Ma_tinh_huong_khong_duoc_de_trong": "",
        "CRM_Mac_dinh": "",
        "CRM_Mat_hang": "Item",
        "CRM_Mat_hang_chu_luc": "",
        "CRM_Mat_khau": "パスワード",
        "CRM_Mat_khau_cu": "",
        "CRM_Mat_khau_cu_khong_dung": "Old password is wrong",
        "CRM_Mat_khau_khong_duoc_de_trong": "",
        "CRM_Mat_khau_moi": "Change Password",
        "CRM_Mat_khau_moi_khong_hop_le": "",
        "CRM_Mat_khau_xac_nhan_khong_dung": "",
        "CRM_Mau_bao_cao": "レポートコード",
        "CRM_Mau_bao_cao_khong_ton_tai": "",
        "CRM_Mau_dinh_kem": "",
        "CRM_Mau_giao_dien": "",
        "CRM_Mau_sac": "",
        "CRM_Mo": "",
        "CRM_Mo_chuong_trinh": "",
        "CRM_Mo_ta": "",
        "CRM_Mo_ta_san_pham": "",
        "CRM_MST": "",
        "CRM_Muc_do_dong_y": "",
        "CRM_Nam": "Year",
        "CRM_Nam_thang": "",
        "CRM_Nam1": "",
        "CRM_Ngay": "年月日 \t",
        "CRM_Ngay_bao_gia": "",
        "CRM_Ngay_bao_gia_khong_duoc_de_trong": "",
        "CRM_Ngay_bat_dau": "開始日",
        "CRM_Ngay_bat_dau_khong_duoc_lon_hon_ngay_ket_thuc": "",
        "CRM_Ngay_cap": "発行日",
        "CRM_Ngay_cap_CMND": "",
        "CRM_Ngay_cap_ho_chieu": "",
        "CRM_Ngay_cap_nhat": "",
        "CRM_Ngay_chinh_sua_cuoi": "",
        "CRM_Ngay_chung_tuU": "",
        "CRM_Ngay_chuyen": "",
        "CRM_Ngay_danh_gia": "",
        "CRM_Ngay_danh_gia_bat_buoc_nhap": "",
        "CRM_Ngay_danh_gia_khong_duoc_de_trong": "",
        "CRM_Ngay_dao_han": "Due Date",
        "CRM_Ngay_den": "",
        "CRM_Ngay_den_phai_lon_hon_ngay_tu": "",
        "CRM_Ngay_dong": "",
        "CRM_Ngay_dong_khong_duoc_nho_hon_ngay_hieu_luc": "",
        "CRM_Ngay_duyet": "承認年月日",
        "CRM_Ngay_giao_hang": "",
        "CRM_Ngay_gio": "",
        "CRM_Ngay_gui": "",
        "CRM_Ngay_het_han": "有効期限",
        "CRM_Ngay_het_hieu_luc": "Valid Date To",
        "CRM_Ngay_het_hieu_luc_phai_lon_hon_ngay_hieu_luc": "",
        "CRM_Ngay_hieu_luc": "有効な日付",
        "CRM_Ngay_hieu_luc_khong_duoc_de_trong": "",
        "CRM_Ngay_hoan_thanh": "Finish Date",
        "CRM_Ngay_hoan_thanh_khong_duoc_de_trong": "",
        "CRM_Ngay_hoan_thanh_phai_lon_hon_ngay_bat_dau": "",
        "CRM_Ngay_ket_thuc": "終了日",
        "CRM_Ngay_ket_thuc_phai_nho_hon_ngay_bat_dau": "",
        "CRM_Ngay_lap": "Created Date",
        "CRM_Ngay_nghi_viec": "",
        "CRM_Ngay_nhan": "Received Date",
        "CRM_Ngay_phat_sinh": "Trans Date",
        "CRM_Ngay_phieu": "バウチャー日",
        "CRM_Ngay_sinh": "誕生日",
        "CRM_Ngay_sua": "",
        "CRM_Ngay_tac_dong": "",
        "CRM_Ngay_tao": "",
        "CRM_Ngay_tao_khach_hang": "",
        "CRM_Ngay_thanh_lap": "",
        "CRM_Ngay_them": "",
        "CRM_Ngay_tinh_no": "As of Date",
        "CRM_Ngay_tu": "",
        "CRM_Ngay_tu/Ngay_den_bat_buoc_nhap": "",
        "CRM_Nghiep_vu": "",
        "CRM_Ngung_hoat_dong": "",
        "CRM_Nguoi_ban_giao_va_nguoi_duoc_ban_giao_phai_khac_nhau": "",
        "CRM_Nguoi_bao_gia": "",
        "CRM_Nguoi_cap_nhat": "",
        "CRM_Nguoi_chuyen": "",
        "CRM_Nguoi_dai_dien_phap_luat": "",
        "CRM_Nguoi_danh_gia": "",
        "CRM_Nguoi_danh_gia_bat_buoc_nhap": "",
        "CRM_Nguoi_dong": "",
        "CRM_Nguoi_dung": "ユーザー",
        "CRM_Nguoi_dung_khong_duoc_de_trong": "",
        "CRM_Nguoi_dung_khong_su_dung": "",
        "CRM_Nguoi_duyet": "承認者",
        "CRM_Nguoi_giai_quyet": "",
        "CRM_Nguoi_gui": "",
        "CRM_Nguoi_lap": "によって作成された",
        "CRM_Nguoi_lien_he": "",
        "CRM_Nguoi_lien_he_chinh": "",
        "CRM_Nguoi_nhan": "",
        "CRM_Nguoi_phu_trach": "",
        "CRM_Nguoi_sua": "",
        "CRM_Nguoi_tao": "",
        "CRM_Nguoi_them": "",
        "CRM_Nguoi_thuc_hien": "",
        "CRM_Nguoi_uy_quyen": "",
        "CRM_Nguon_goc": "",
        "CRM_Nguon_them_khach_hang_va_lien_he": "",
        "CRM_Nhan_don_hang": "",
        "CRM_Nhan_tiep": "",
        "CRM_Nhan_vien": "",
        "CRM_Nhan_vien_ban_giao": "",
        "CRM_Nhan_vien_kinh_doanh": "Sales Person",
        "CRM_Nhan_vien_nay_da_duoc_su_dung": "",
        "CRM_Nhan_vien_nhan_ban_giao": "",
        "CRM_Nhap": "",
        "CRM_Nhap_chuoi": "",
        "CRM_Nhap_it_nhat_1_san_pham": "",
        "CRM_Nhap_noi_dung_kien_nghi_can_tim_kiem": "",
        "CRM_Nhap_noi_dung_tim_kiem": "",
        "CRM_Nhap_Ten_Dang_Nhap": "Enter username",
        "CRM_Nhap_tiep": "",
        "CRM_Nho_mat_khau1": "",
        "CRM_Nhom_cong_ty": "",
        "CRM_Nhom_hang_chu_luc": "",
        "CRM_Nhom_khach_hang": "カスタマーグループ",
        "CRM_Nhom_kinh_doanh": "",
        "CRM_Nhom_kinh_doanh_khong_duoc_de_trong": "",
        "CRM_Nhom_nganh_nghe": "",
        "CRM_Nhom_phan_loai": "",
        "CRM_Nhom_phan_quyen": "",
        "CRM_Nhom_thue": "",
        "CRM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "CRM_Noi_cap": "発行場所",
        "CRM_Noi_cap_CMND": "",
        "CRM_Noi_dung": "内容",
        "CRM_Noi_dung_can_tim": "",
        "CRM_Noi_dung_cong_viec": "Work content",
        "CRM_Noi_dung_khao_sat": "",
        "CRM_Noi_dung_khong_duoc_de_trong": "",
        "CRM_Noi_dung_tim_kiem": "",
        "CRM_Noi_giao_hang": "",
        "CRM_Noi_nhan_hang": "",
        "CRM_Nu": "",
        "CRM_NVKD": "",
        "CRM_Phai_chon_nguoi_ban_giao": "",
        "CRM_Phai_chon_nguoi_duoc_ban_giao": "",
        "CRM_Phan_he": "",
        "CRM_Phan_loai": "",
        "CRM_Phan_loai_doi_tac": "",
        "CRM_Phan_loai_doi_thu": "",
        "CRM_Phan_loai_KH": "",
        "CRM_Phan_loai_khach_hang": "",
        "CRM_Phan_loai_nhom": "",
        "CRM_Phien_ban": "",
        "CRM_Phong_ban": "部門",
        "CRM_Phuong": "",
        "CRM_Phuong_phap_tinh_tuoi_no": "",
        "CRM_Phuong_thuc_giao_hang": "",
        "CRM_Phuong_thuc_TT": "",
        "CRM_Phuong_xa": "",
        "CRM_phut": "",
        "CRM_PT_giao_hang": "",
        "CRM_PT_thanh_toan": "",
        "CRM_Quan": "",
        "CRM_Quan_huyen": "",
        "CRM_Quan_ly": "",
        "CRM_Quan_ly_danh_muc": "",
        "CRM_Quay_lai": "",
        "CRM_Quoc_gia": "",
        "CRM_Quy_cach_dong_goi": "",
        "CRM_Quy_mo_cong_ty": "",
        "CRM_Quy_uoc_nhu_sau": "",
        "CRM_Sao_chep_bao_gia": "",
        "CRM_Sao_chep_chuong_trinh": "",
        "CRM_Sao_chep_doi_tac": "",
        "CRM_Sao_chep_doi_thu": "",
        "CRM_Sao_chep_khach_hang": "",
        "CRM_Sau_so_ngay": "",
        "CRM_Sinh_nhat": "",
        "CRM_Skype": "",
        "CRM_SL": "Qty",
        "CRM_SL_co_the_xuat": "",
        "CRM_SL_hoa_don": "",
        "CRM_SL_ton_thuc_te": "",
        "CRM_So_bao_gia": "",
        "CRM_So_CCCD_/_CMND": "",
        "CRM_So_chung_tu": "書類番号",
        "CRM_So_di_dong": "",
        "CRM_So_dien_thoai": "Tel no.",
        "CRM_So_dien_thoai_khong_hop_le": "",
        "CRM_So_dien_thoai_nguoi_lien_he": "",
        "CRM_So_dien_thoai_trung_voi_lien_he": "",
        "CRM_So_don_hang": "受注番号",
        "CRM_So_dong": "",
        "CRM_So_dong_RPC": "",
        "CRM_So_dong_tren_mot_trang": "",
        "CRM_So_Fax": "",
        "CRM_So_lung_den_khong_duoc_de_trong": "",
        "CRM_So_luong": "数量",
        "CRM_So_luong_co_the_dat_ban": "",
        "CRM_So_luong_dang_dat_ban": "",
        "CRM_So_luong_dang_dat_hoa_don_ban": "",
        "CRM_So_luong_den_phai_lon_hon_so_luong_tu": "",
        "CRM_So_luong_khong_duoc_de_trong": "",
        "CRM_So_luong_QD": "",
        "CRM_So_luong_sau_DC": "",
        "CRM_So_luong_toi_thieu": "",
        "CRM_So_luong_ton_kho": "",
        "CRM_So_luong_tu_khong_duoc_de_trong": "",
        "CRM_So_luong_tu_phai_nho_hon_so_luong_den": "",
        "CRM_So_may_ban": "",
        "CRM_So_tai_khoan": "",
        "CRM_So_thu_tu": "",
        "CRM_Status": "",
        "CRM_Store_kiem_tra_bi_loi": "",
        "CRM_STT": "次",
        "CRM_Su_dung_phan_loai": "",
        "CRM_Su_dung_tai": "",
        "CRM_Sua": "Edit",
        "CRM_Tai_khoan": "口座",
        "CRM_Tai_khoan_bi_khoa_10_phut": "",
        "CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "CRM_Tai_khoan_khong_co_quyen": "",
        "CRM_Tai_len": "",
        "CRM_Tai_lieu": "",
        "CRM_Tai_lieu_da_dinh_kem": "",
        "CRM_Tai_lieu_dinh_kem": "",
        "CRM_Tai_U": "",
        "CRM_Tam_dap_ung_Can_cai_tien_them": "",
        "CRM_Tang_dan": "",
        "CRM_Tang_luong_truoc_thoi_han": "",
        "CRM_Tap_tin": "",
        "CRM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "CRM_Tat_ca": "",
        "CRM_Ten": "名前",
        "CRM_Ten_bao_cao": "Report Name",
        "CRM_Ten_cong_viec": "",
        "CRM_Ten_CTV": "",
        "CRM_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "CRM_Ten_dang_nhap_khong_duoc_de_trong": "",
        "CRM_Ten_danh_sach": "",
        "CRM_Ten_danh_sach_khong_duoc_de_trong": "",
        "CRM_Ten_dia_ban": "",
        "CRM_Ten_dia_ban_khong_duoc_de_trong": "",
        "CRM_Ten_doi_tac": "",
        "CRM_Ten_doi_thu": "",
        "CRM_Ten_don_vi_tinh": "",
        "CRM_Ten_File": "",
        "CRM_Ten_hang": "アイテム名",
        "CRM_Ten_hang_hoa": "",
        "CRM_Ten_khach_hang": "",
        "CRM_Ten_kho": "倉庫名",
        "CRM_Ten_khong_duoc_de_trong": "",
        "CRM_Ten_lien_he": "",
        "CRM_Ten_loai_danh_gia": "",
        "CRM_Ten_loai_khong_duoc_de_trong": "",
        "CRM_Ten_loai_thong_tin": "",
        "CRM_Ten_loai_tinh_huong": "",
        "CRM_Ten_mau": "",
        "CRM_Ten_nhom_hang": "",
        "CRM_Ten_nhom_khach_hang": "",
        "CRM_Ten_nhom_kinh_doanh": "",
        "CRM_Ten_nhom_kinh_doanh_khong_duoc_de_trong": "",
        "CRM_Ten_NVKD": "",
        "CRM_Ten_phan_loai_khach_hang": "",
        "CRM_Ten_tat": "",
        "CRM_Ten_tinh_huong": "",
        "CRM_Ten_truong_thiet_lap": "",
        "CRM_Test_1": "",
        "CRM_Tham_so_truyen_vao_khong_hop_le": "",
        "CRM_Thang": "",
        "CRM_Thang_1": "",
        "CRM_Thang_10": "",
        "CRM_Thang_11": "",
        "CRM_Thang_12": "",
        "CRM_Thang_2": "",
        "CRM_Thang_3": "",
        "CRM_Thang_4": "",
        "CRM_Thang_5": "",
        "CRM_Thang_6": "",
        "CRM_Thang_7": "",
        "CRM_Thang_8": "",
        "CRM_Thang_9": "",
        "CRM_Thang_chua_nop_bao_cao": "",
        "CRM_Thanh_pho": "",
        "CRM_Thanh_tien": "Amount",
        "CRM_Thanh_tien_QD": "",
        "CRM_Thanh_tien_QD_sau_CK": "",
        "CRM_Thanh_tien_sau_CK": "",
        "CRM_Thanh_tien_sau_DC": "",
        "CRM_Thanh_tien_sau_DC_QD": "",
        "CRM_Thanh_tien_sau_thue": "",
        "CRM_Thanh_tien_sau_thue_QD": "",
        "CRM_Thanh_tien_truoc_thue": "",
        "CRM_Thao_luan": "",
        "CRM_Thao_tac": "",
        "CRM_Thay_doi_loai_tien_se_tinh_toan_lai_thanh_tien_QD_ban_co_muon_tiep_tuc_khong": "",
        "CRM_Thay_doi_mat_khau_khong_thanh_cong": "",
        "CRM_Thay_doi_nghiep_vu_se_xoa_du_lieu_luoi_mat_hang_ban_co_muon_tiep_tuc_khong": "",
        "CRM_Them": "Add",
        "CRM_Them_dieu_kien_loc": "",
        "CRM_Them_dong": "",
        "CRM_Them_hang_hoa": "",
        "CRM_Them_hinh_anh_mat_hang": "",
        "CRM_Them_khong_thanh_cong": "",
        "CRM_Them_moi": "",
        "CRM_Them_moi_khach_hang": "",
        "CRM_Them_moi1": "新しく作る",
        "CRM_Them_nhom_kinh_doanh": "",
        "CRM_Them_vao_danh_sach": "",
        "CRM_Theo_nhan_vien": "",
        "CRM_Thiet_lap": "",
        "CRM_Thiet_lap_he_thong": "システムのセットアップ",
        "CRM_Thiet_lap_khach_hang_chung": "",
        "CRM_Thiet_lap_mail": "",
        "CRM_Thiet_lap_mau_bao_cao": "",
        "CRM_Thiet_lap_phan_quyen": "",
        "CRM_Thiet_lap_thong_tin_Email": "",
        "CRM_Thiet_lap_truong_bat_buoc": "",
        "CRM_Thiet_lap_truong_bat_buoc_nhap": "",
        "CRM_Thoi_gian": "時間",
        "CRM_Thoi_gian_den_nho_hon_tu": "",
        "CRM_Thoi_gian_giao_hang": "",
        "CRM_Thoi_gian_khong_tai_ky_dat_hang": "",
        "CRM_Thoi_gian_thuc_hien": "Execution Time",
        "CRM_Thoi_gian_tu_lon_hon_den": "",
        "CRM_Thong_bao": "Announcement",
        "CRM_thong_bao_moi": "",
        "CRM_Thong_bao1": "",
        "CRM_Thong_baoU": "",
        "CRM_Thong_so_thoi_gian": "",
        "CRM_Thong_tin_bao_gia": "",
        "CRM_Thong_tin_bo_sung": "",
        "CRM_Thong_tin_ca_nhan": "個人情報",
        "CRM_Thong_tin_chinh": "",
        "CRM_Thong_tin_chung": "一般的な情報",
        "CRM_Thong_tin_cong_ty": "",
        "CRM_Thong_tin_cung_cap_khong_hop_le": "",
        "CRM_Thong_tin_don_hang": "",
        "CRM_Thong_tin_hang_hoa": "",
        "CRM_Thong_tin_khach_hang": "",
        "CRM_Thong_tin_phu": "",
        "CRM_Thong_tin_thue_GTGT": "",
        "CRM_Thu_gon": "",
        "CRM_Thu_tu_hien_thi": "シーケンス番号",
        "CRM_Thue": "",
        "CRM_Thue_GTGT": "VAT",
        "CRM_Thue_GTGT_sau_QD": "",
        "CRM_Thue_suat": "",
        "CRM_Thue_suat_khong_duoc_lon_hon": "",
        "CRM_Tien_ban": "",
        "CRM_Tien_chiet_khau": "",
        "CRM_Tien_CK": "チャージ",
        "CRM_Tien_CK_QD": "割引キャッシュ·コンバージョン",
        "CRM_Tien_do_bao_gia": "",
        "CRM_Tien_do_don_hang": "",
        "CRM_Tien_hang": "量",
        "CRM_Tien_te": "",
        "CRM_Tien_te_khong_duoc_de_trong": "",
        "CRM_Tien_thue": "課税額",
        "CRM_Tien_thue_QD": "変換された税額",
        "CRM_Tieu_de": "Title",
        "CRM_Tieu_de_khong_duoc_de_trong": "",
        "CRM_Tieu_de_mail": "",
        "CRM_Tieu_thuc_nhom": "",
        "CRM_Tim_kiem": "",
        "CRM_Tim_kiem_khach_hang": "",
        "CRM_Tim_kiem_lien_he": "",
        "CRM_Tim_kiem_nang_cao": "",
        "CRM_Time_from_>_time_to": "",
        "CRM_Tinh": "",
        "CRM_Tinh_huong": "",
        "CRM_Tinh_thanh_pho": "",
        "CRM_To_chuc": "組織",
        "CRM_Ton_tai_chi_tieu_co_gia_tri_khong_hop_le": "",
        "CRM_Tong": "",
        "CRM_Tong_chi_tieu_cua_nhom_khong_duoc_nho_hon_chi_tieu_chi_tiet": "",
        "CRM_Tong_diem": "",
        "CRM_Tong_gia_tri_A": "",
        "CRM_Tong_gia_tri_A_khong_bang_tong_gia_tri_B": "",
        "CRM_Tong_gia_tri_B": "",
        "CRM_Tong_gia_tri_bao_gia": "",
        "CRM_Tong_gia_tri_don_hang": "",
        "CRM_Tong_gia_tri_don_hang_QD": "",
        "CRM_Tong_hop": "合成",
        "CRM_Tong_hop_khach_hang_moi": "",
        "CRM_Tong_so_luong_bao_gia": "",
        "CRM_Tong_thanh_toan": "総支払",
        "CRM_Tong_thanh_toan_QD": "支払総額は、変換された",
        "CRM_Tong_tien": "Total",
        "CRM_Tong_tien_QD": "al C.Amount",
        "CRM_Tong_tien_sau_DC": "",
        "CRM_Tong_tien_sau_DC_QD": "",
        "CRM_Trang": "",
        "CRM_Trang_Chu": "ホームページ",
        "CRM_Trang_thai": "ステータス",
        "CRM_Trang_thai_chi_tiet": "",
        "CRM_Trang_thai_cu": "",
        "CRM_Trang_thai_KH": "",
        "CRM_Trang_thai_khach_hang": "",
        "CRM_Trang_thai_moi": "",
        "CRM_Trang_thai_thuc_hien": "",
        "CRM_Trang_thai_thuc_hien_DH": "",
        "CRM_Trang_thai_vao_ra": "",
        "CRM_Tre_han": "",
        "CRM_Trinh_duyet_khong_co_quyen_mo_popup": "",
        "CRM_Tron_truong": "",
        "CRM_trong": "",
        "CRM_Truc_thuoc_nhom": "",
        "CRM_Truong_thiet_lap": "",
        "CRM_Truy_cap_khong_hop_le": "",
        "CRM_Tu": "から",
        "CRM_Tu_choi": "拒絶",
        "CRM_Tu_ngay": "Date from",
        "CRM_Tuy_chon_cong_ty": "",
        "CRM_Ty_gia": "為替レート",
        "CRM_Ty_le_chiet_khau": "",
        "CRM_Ty_le_CK": "",
        "CRM_Ty_le_qua_han": "",
        "CRM_Vao_luc": "",
        "CRM_Vao_ngay": "",
        "CRM_Vi_tri_dia_ly": "",
        "CRM_Viet_binh_luan": "",
        "CRM_VoucherID_khong_duoc_de_trong": "",
        "CRM_Vui_long_chon_hinh_anh": "",
        "CRM_Vui_long_chon_ma_hang_va_don_vi_tinh": "",
        "CRM_Vui_long_nhap_day_du_don_gia_truoc_thue_va_so_luong_hang_hoa": "",
        "CRM_Vui_long_nhap_day_du_thong_tin": "",
        "CRM_Vui_long_truy_cap_sau": "",
        "CRM_Xa": "",
        "CRM_Xac_nhan_mat_khau_moi": "",
        "CRM_XaPhuong": "",
        "CRM_Xem": "表示",
        "CRM_Xem_bao_cao": "",
        "CRM_Xem_it_hon": "",
        "CRM_Xem_nhieu_hon": "",
        "CRM_Xem_tap_tin": "",
        "CRM_Xem_tat_ca": "",
        "CRM_Xem_them": "",
        "CRM_Xem_truoc": "",
        "CRM_Xin_cam_on": "",
        "CRM_Xoa": "Delete",
        "CRM_Xoa_khong_thanh_cong": "",
        "CRM_Xoa_quyen_thanh_cong": "",
        "CRM_Xuat_bao_cao": "レポートのエクスポート",
        "CRM_Xuat_danh_sach_khach_hang": "",
        "CRM_Xuat_danh_sach_lien_he": "",
        "CRM_Xuat_du_lieu": "",
        "CRM_Xuat_Excel": "",
        "CRM_Xuat_khau_khach_hang": "",
        "CRM_Xuat_PDF": "",
        "CRM_Xuat_word": "",
        "CRM_Y_kien_dong_gop": "",
        "CRM_Yeu_cau_chuyen_doi": "変換リクエスト",
        "CRM_Yeu_cau_chuyen_doi_thanh_cong": ""
    },
    "zh": {
        "CRM_403_loi": "",
        "CRM_404_loi": "",
        "CRM_AC_vui_long_chon_y_kien_cua_minh": "",
        "CRM_Ap": "",
        "CRM_Ap_Khu_pho": "",
        "CRM_App": "",
        "CRM_Ban_chua_chon_chu_so_huu": "",
        "CRM_Ban_chua_chon_hang_hoa": "",
        "CRM_Ban_chua_chon_khach_hang": "",
        "CRM_Ban_chua_chon_lien_he": "",
        "CRM_Ban_chua_chon_mau_bao_cao": "",
        "CRM_Ban_chua_chon_ngay_den": "",
        "CRM_Ban_chua_chon_nhom_hang": "",
        "CRM_Ban_chua_chon_truong_thiet_lap_bat_buoc_nhap": "",
        "CRM_Ban_chua_co_thong_bao_nao": "",
        "CRM_Ban_chua_nhap_chi_tieu_doanh_so": "",
        "CRM_Ban_chua_nhap_chu_de_cua_Email": "",
        "CRM_Ban_chua_nhap_Email_Bcc": "",
        "CRM_Ban_chua_nhap_Email_Cc": "",
        "CRM_Ban_chua_nhap_Email_nguoi_nhan": "",
        "CRM_Ban_chua_nhap_gia_tri": "",
        "CRM_Ban_chua_nhap_noi_dung_Email": "",
        "CRM_Ban_chua_nhap_truong:_%p_Ban_co_muon_luu_khong?": "",
        "CRM_Ban_chua_thiet_lap_Mau_bao_cao": "",
        "CRM_Ban_co": "",
        "CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay": "",
        "CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "",
        "CRM_Ban_co_muon_chia_se_bao_gia_nay": "",
        "CRM_Ban_co_muon_dong_bao gia": "",
        "CRM_Ban_co_muon_mo_lai_bao_gia": "",
        "CRM_Ban_co_muon_nhap_tiep_khong": "",
        "CRM_Ban_co_muon_xoa_quyen_cua_nhan_vien": "",
        "CRM_Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%p_de_dang_nhap_lai": "",
        "CRM_Ban_giao": "",
        "CRM_Ban_giao_cho": "",
        "CRM_Ban_khong_co_quyen_truy_cap": "",
        "CRM_Ban_khong_co_quyen_truy_cap_Module_nay": "",
        "CRM_Ban_phai_chon_doanh_nghiep": "",
        "CRM_Ban_phai_chon_don_vi_ban": "",
        "CRM_Ban_phai_chon_thoi_gian_khong_tai_ky_dat_hang": "",
        "CRM_Ban_phai_chuyen_khach_hang_thanh_doi_tuong": "",
        "CRM_Bang_bao_gia": "",
        "CRM_Bang_gia": "价格表",
        "CRM_Bang_gia_khong_duoc_de_trong": "",
        "CRM_Bao_cao": "",
        "CRM_Bao_cao_chi_tiet_cong_viec": "",
        "CRM_Bao_cao_hien_thi": "",
        "CRM_Bao_cao_hoat_dong": "",
        "CRM_Bao_cao_hoat_dong_theo_khach_hang": "",
        "CRM_Bao_cao_khach_hang_theo_ma_hang": "",
        "CRM_Bao_cao_phan_tich_tien_thu_ban_hang": "銷售收據分析報告",
        "CRM_Bao_cao_so_lieu_hoat_dong": "",
        "CRM_Bao_cao_thong_ke_khach_hang": "",
        "CRM_Bao_gia": " 行情",
        "CRM_Bao_gia_da_duyet": "",
        "CRM_Bao_gia_duoc_dong": "",
        "CRM_Bat_buoc_nhap": "",
        "CRM_Bo_duyet": "",
        "CRM_Bo_duyet_thanh_cong": "",
        "CRM_Bo_phan": "",
        "CRM_Boi": "",
        "CRM_Ca_nam": "",
        "CRM_Ca_nhan": "Personal",
        "CRM_Cac_chi_tieu_DK_A": "",
        "CRM_Cac_chi_tieu_DK_B": "",
        "CRM_Cac_gop_y_khac": "",
        "CRM_Cac_tham_so_la_bat_buoc": "",
        "CRM_Cac_thong_so_luong": "",
        "CRM_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "CRM_Cai_dat": "",
        "CRM_Canh_bao_nhap": "",
        "CRM_Cap_co_cau": "",
        "CRM_Cap_do": "",
        "CRM_Cap_nhat_danh_gia": "",
        "CRM_Cap_nhat_danh_muc": "",
        "CRM_Cap_nhat_danh_sach_khach_hang": "",
        "CRM_Cap_nhat_dia_ban": "",
        "CRM_Cap_nhat_ghi_nhan_nhu_cau_khach_hang": "",
        "CRM_Cap_nhat_hoat_dong": "",
        "CRM_Cap_nhat_lien_he": "",
        "CRM_Cap_nhat_loai_danh_gia": "",
        "CRM_Cap_nhat_loai_tinh_huong": "",
        "CRM_Cap_nhat_ma_hang": "",
        "CRM_Cap_nhat_mau_bao_cao": "",
        "CRM_Cap_nhat_ngon_ngu": "",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh": "",
        "CRM_Cap_nhat_nhan_vien_kinh_doanh1": "",
        "CRM_Cap_nhat_nhom_khach_hang": "",
        "CRM_Cap_nhat_thong_tin_bo_sung": "",
        "CRM_Cap_nhat_thong_tin_giao_hang": "",
        "CRM_Cap_nhat_tinh_huong": "",
        "CRM_Cap_nhat_trang_thai": "",
        "CRM_Cap_nhat_trang_thai_KH": "",
        "CRM_Cap_nhat_trang_thai_thuc_hien_DH": "",
        "CRM_Chap_nhan_don_hang": "",
        "CRM_Chi_nhanh_ngan_hang": "",
        "CRM_Chi_tiet": "细节",
        "CRM_Chi_tiet_bao_gia": "",
        "CRM_Chi_tiet_don_hang": "",
        "CRM_Chi_tiet_loi": "",
        "CRM_Chi_tiet_nguoi_lien_he": "",
        "CRM_Chi_tiet_theo": "",
        "CRM_Chi_tieu": "Target",
        "CRM_Chi_tieu_chi_tiet_vuot_tong_chi_tieu_cua_nhom": "",
        "CRM_Chi_tieu_doanh_so": "",
        "CRM_Chia_se": "",
        "CRM_Chia_se_doi_tac": "",
        "CRM_Chia_se_doi_thu": "",
        "CRM_Chia_se_khach_hang": "",
        "CRM_Chiet_khau": "",
        "CRM_Chiet_khau_khong_duoc_lon_hon": "",
        "CRM_Chinh_sua_khong_thanh_cong": "",
        "CRM_Chinh_sua_thanh_cong": "",
        "CRM_Cho_duyet": "待批准",
        "CRM_Chon": "Select",
        "CRM_Chon_combo": "",
        "CRM_Chon_gia_tri": "",
        "CRM_Chon_hang": "",
        "CRM_Chon_hang_hoa": "",
        "CRM_Chon_hinh": "",
        "CRM_Chon_kenh": "",
        "CRM_Chon_khach_hang": "",
        "CRM_Chon_loai_tinh_huong": "",
        "CRM_Chon_mau_bao_cao": "",
        "CRM_Chon_nguoi_giai_quyet": "",
        "CRM_Chon_nhom_hang": "",
        "CRM_Chon_tap_tin": "",
        "CRM_Chon_tat_ca": "",
        "CRM_Chon_tinh": "",
        "CRM_Chon_trang_thai": "",
        "CRM_Chua_co_lien_ket_nao": "",
        "CRM_Chua_dap_ung": "",
        "CRM_Chua_doc": "",
        "CRM_Chua_thuc_hien": "",
        "CRM_Chuc_danh": "",
        "CRM_Chuc_nang_chua_phat_trien": "",
        "CRM_Chuc_nang_dang_duoc_phat_trien": "",
        "CRM_Chuc_nang_them_moi": "",
        "CRM_Chuc_vu": "位置",
        "CRM_Chuc_vu_bat_buoc_nhap": "",
        "CRM_Chuong_trinh_cham_soc_khach_hang": "",
        "CRM_Chuyen_chu_so_huu": "",
        "CRM_Chuyen_doi_trang_thai": "",
        "CRM_CMR_Khong_tim_thay_trang": "",
        "CRM_Co_cau_cha": "",
        "CRM_Co_cau_kinh_doanh": "",
        "CRM_Co_cau_to_chuc": "",
        "CRM_Co_it_nhat_mot_chu_cai": "",
        "CRM_Co_it_nhat_mot_chu_cai_in_hoa": "",
        "CRM_Co_it_nhat_mot_so": "",
        "CRM_Co_it_nhat_sau_ky_tu": "",
        "CRM_Co_loi_khi_luu": "",
        "CRM_Co_loi_khi_luu_lich_su": "",
        "CRM_Co_loi_khi_tim_kiem": "",
        "CRM_Co_loi_khi_xoa": "",
        "CRM_Co_loi_khi_xoa_dinh_kem": "",
        "CRM_Co_loi_khi_xoa_file_bao_cao": "",
        "CRM_Co_loi_trong_qua_trinh_gui_Mail": "",
        "CRM_Co_loi_trong_qua_trinh_luu_File": "",
        "CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu": "",
        "CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "CRM_Co1": "",
        "CRM_Cong_tac_vien": "",
        "CRM_Cong_thuc_ton_tai_chi_tieu_chia_cho_0": "",
        "CRM_Cong_trinh/Cong_ty": "",
        "CRM_Cong_ty": "公司",
        "CRM_Cong_ty_cong_tac": "",
        "CRM_Cong_viec": "工作",
        "CRM_Cot_gia_tri": "",
        "CRM_Cua_bo_phan": "",
        "CRM_Cua_hang_hoa": "",
        "CRM_cua_khach_hang": "",
        "CRM_Cua_toi": "",
        "CRM_Da_co_mat_hang": "",
        "CRM_Da_dong": "",
        "CRM_Da_duyet": "已批准",
        "CRM_Da_hoan_thanh": "",
        "CRM_Da_nghi_viec": "",
        "CRM_Dang_cong_tac": "",
        "CRM_Dang_duyet": "待批准",
        "CRM_Dang_Lam_Viec": "",
        "CRM_Dang_nhap": "登录",
        "CRM_dang_nhap_lai": "以再次登陆",
        "CRM_Dang_nhap_lai2": "",
        "CRM_Dang_tai": "",
        "CRM_Dang_thuc_hien": "",
        "CRM_Dang_xuat": "",
        "CRM_Dang_xuat1": "",
        "CRM_Danh_dau_tat_ca_la_da_doc": "",
        "CRM_Danh_gia": "",
        "CRM_Danh_muc/Phan_loai_khach_hang": "",
        "CRM_Danh_muc_danh_gia": "",
        "CRM_Danh_muc_Loai_tinh_huong": "",
        "CRM_Danh_muc_trang_thai_lam_viec": "",
        "CRM_Danh_sach": "",
        "CRM_Danh_sach_bao_cao": "",
        "CRM_Danh_sach_bao_gia": "",
        "CRM_Danh_sach_bo_loc": "",
        "CRM_Danh_sach_chuong_trinh_cham_soc": "",
        "CRM_Danh_sach_cong_tac_vien": "",
        "CRM_Danh_sach_danh_gia_chung": "",
        "CRM_Danh_sach_dia_ban": "",
        "CRM_Danh_sach_doi_tac": "",
        "CRM_Danh_sach_doi_thu": "",
        "CRM_Danh_sach_don_hang": "",
        "CRM_Danh_sach_file_dinh_kem": "Attachments list",
        "CRM_Danh_sach_khach_hang": "",
        "CRM_Danh_sach_khach_hang_dung_chung": "",
        "CRM_Danh_sach_lich_su_chia_se_va_chuyen_chu_so_huu": "",
        "CRM_Danh_sach_lich_su_gui_mail": "",
        "CRM_Danh_sach_lich_su_thay_doi": "",
        "CRM_Danh_sach_lien_he": "",
        "CRM_Danh_sach_mat_hang": "",
        "CRM_Danh_sach_nhan_vien_kinh_doanh": "",
        "CRM_Danh_sach_sinh_nhat_cua_nguoi_lien_he": "",
        "CRM_Danh_sach_thong_tin_bo_sung": "",
        "CRM_Danh_sach_tinh_huong": "",
        "CRM_Dap_ung": "",
        "CRM_Date_input_format_invalid": "",
        "CRM_Den": "至",
        "CRM_Den_ngay": "Date to",
        "CRM_DG_goc": "",
        "CRM_DG_truoc_thue": "",
        "CRM_Di_dong": "手机",
        "CRM_Dia_ban": "",
        "CRM_Dia_chi": "地址",
        "CRM_Dia_chi_DKKD": "",
        "CRM_Dia_chi_doi_tuong_thue": "",
        "CRM_Dia_chi_giao_dich": "",
        "CRM_Dia_chi_giao_hang": "",
        "CRM_Dia_chi_IP": "",
        "CRM_Dia_chi_khach_hang": "",
        "CRM_Dia_chi_khong_duoc_de_trong": "",
        "CRM_Dia_chi_lien_he": "Contact Address",
        "CRM_Dia_chi_thuong_tru": "永久地址",
        "CRM_Dia_diem": "",
        "CRM_Dia_diem_giao_dich": "",
        "CRM_Dia_diem_giao_hang": "",
        "CRM_Dien_dai": "",
        "CRM_Dien_giai": "描述",
        "CRM_Dien_thoai": "电话",
        "CRM_Dien_thoai_cong_ty": "",
        "CRM_Dien_thoai_di_dong": "手机",
        "CRM_Dieu_chinh": "",
        "CRM_Dieu_khoan_thuong_mai": "",
        "CRM_Dieu_khoan_TM": "",
        "CRM_Dieu_kien": "Filter",
        "CRM_Dieu_kien_loc": "选择标准",
        "CRM_Dieu_kien_loc_chi_tiet": "",
        "CRM_Dieu_kien_loc_khong_hop_le": "",
        "CRM_Dieu_kien_loc_mac_dinh": "",
        "CRM_Dinh_dang_email_khong_hop_le": "",
        "CRM_Dinh_dang_file_khong_hop_le": "",
        "CRM_Dinh_kem": "Attachments",
        "CRM_Dinh_kem_hinh_anh": "",
        "CRM_Do_dai_mat_khau_khong_hop_le": "",
        "CRM_Do_uu_tien": "",
        "CRM_Doanh_so": "",
        "CRM_Doanh_thu": "",
        "CRM_Doanh_thu_nam": "",
        "CRM_Doi_mat_khau": "",
        "CRM_Doi_mat_khau_thanh_cong": "",
        "CRM_Doi_tac": "",
        "CRM_Doi_thu": "",
        "CRM_Doi_tuong": "对象",
        "CRM_Doi_tuong_ap_dung": "",
        "CRM_Doi_tuong_QLCN": "",
        "CRM_Doi_tuong_thue": "",
        "CRM_Don_gia": "价格",
        "CRM_Don_gia_ban": "",
        "CRM_Don_gia_sau_DC": "",
        "CRM_Don_gia_sau_thue": "",
        "CRM_Don_gia_truoc_thue": "",
        "CRM_Don_hang": "",
        "CRM_Don_vi": "单位",
        "CRM_Don_vi_ban": "",
        "CRM_Don_vi_tinh": "",
        "CRM_Dong_bao_gia": "",
        "CRM_Dong_bao_gia_thanh_cong": "",
        "CRM_Dong_chuong_trinh": "",
        "CRM_Dong_khong_thanh_cong": "",
        "CRM_Dong_y": "",
        "CRM_Dong1": "",
        "CRM_Du_co_cuoi_ky": "",
        "CRM_Du_co_dau_ky": "",
        "CRM_Du_lieu": "数据",
        "CRM_Du_lieu_chua_duoc_luu_Ban_co_muon_luu_khong": "Data not saved. Do you want to save?",
        "CRM_Du_lieu_co_thay_doi_ban_co_muon_luu_khong": "",
        "CRM_Du_lieu_da_duoc_import_dang_o_trang_thai_Chua_duyet_hoac_Tu_choi_Ban_co_muon_tiep_tuc_khong": "",
        "CRM_Du_lieu_da_duoc_su_dung": "",
        "CRM_Du_lieu_da_xoa_thanh_cong": "",
        "CRM_Du_lieu_dinh_kem": "",
        "CRM_Du_lieu_import_bi_loi": "",
        "CRM_Du_lieu_tham_chieu": "",
        "CRM_Du_no_cuoi_ky": "",
        "CRM_Du_no_dau_ky": "",
        "CRM_Dung_luong": "",
        "CRM_Dung_luong_File_khong_duoc_lon_hon": "",
        "CRM_Duong_dan": "",
        "CRM_Duyet": "批准",
        "CRM_Duyet_thanh_cong": "",
        "CRM_DVT": "单位",
        "CRM_Email": "电子邮件",
        "CRM_Email_ca_nhan": "",
        "CRM_Email_cong_ty": "",
        "CRM_Email_nguoi_gui": "",
        "CRM_Email_nguoi_nhan": "",
        "CRM_Facebook": "",
        "CRM_File_Import_khong_hop_le": "",
        "CRM_Ghi_chu": "注解 ",
        "CRM_Ghi_chu_duyet": "Approve Notes",
        "CRM_Ghi_chu_KH": "",
        "CRM_Ghi_chu_noi_bo": "",
        "CRM_Ghi_nhan_nhu_cau": "",
        "CRM_Gia_goc": "",
        "CRM_Gia_tri_bat_buoc_nhap": "",
        "CRM_Gia_tri_khong_hop_le": "",
        "CRM_Giai_phap": "",
        "CRM_Giam_dan": "",
        "CRM_Giao_hang": "交货",
        "CRM_Giay": "",
        "CRM_Giay_CN_DKKD": "",
        "CRM_Gioi_tinh": "性别",
        "CRM_Gioi_tinh_khong_duoc_de_trong": "",
        "CRM_Gui": "Send",
        "CRM_Gui_mail": "",
        "CRM_Gui_thanh_cong": "",
        "CRM_Hai_long_can_cai_tien_them": "",
        "CRM_Han_ket_thuc": "",
        "CRM_Han_xu_ly": "",
        "CRM_Han_xu_ly_khong_duoc_de_trong": "",
        "CRM_Han_xu_ly_phai_lon_hon_ngay_bat_dau": "",
        "CRM_Hang_hoa": "",
        "CRM_Hang_hoa_da_chon": "",
        "CRM_Hang_hoa_nay_da_ton_tai": "",
        "CRM_Hanh_dong": "",
        "CRM_He_thong": "系統",
        "CRM_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "CRM_Het_dong": "",
        "CRM_Hien_thi_chi_tiet": "",
        "CRM_Hien_thi_chi_tieu_da_huy": "",
        "CRM_Hien_thi_danh_sach_khong_su_dung": "",
        "CRM_Hien_thi_khong_su_dung": "",
        "CRM_Hien_thi_ngung_hoat_dong": "",
        "CRM_Hien_thi_so_luong_bang_0": "",
        "CRM_Hien_thi_voi_moi_nguoi": "",
        "CRM_Hieu_luc_den": "有效期至",
        "CRM_Hieu_luc_den_phai_lon_hon_hieu_luc_tu": "",
        "CRM_Hieu_luc_tu": "有效期从",
        "CRM_Ho_chieu": "",
        "CRM_Ho_ten": "",
        "CRM_Ho_va_ten": "全名",
        "CRM_Ho_va_ten_khong_duoc_de_trong": "",
        "CRM_Ho_va_ten_nguoi_lien_he": "",
        "CRM_Hoan_toan_hai_long": "",
        "CRM_Hoat_dong": "",
        "CRM_Hoat_dong_cua_toi": "",
        "CRM_Hom_nay": "今天",
        "CRM_Huy": "Cancel",
        "CRM_Huy_bo": "",
        "CRM_Huyen": "",
        "CRM_Import_bao_cao": "",
        "CRM_Import_du_lieu": "",
        "CRM_In": "Print",
        "CRM_In1": "Print",
        "CRM_Kenh_gop_y": "",
        "CRM_Kenh_truyen_thong": "",
        "CRM_Kenh_truyen_thong_khong_duoc_de_trong": "",
        "CRM_Ket_qua": "Result",
        "CRM_Khac": "其他",
        "CRM_Khach_hang": "顾客",
        "CRM_Khach_hang_da_phat_sinh_doat_dong_ban_khong_duoc_phep_xoa": "",
        "CRM_Khach_hang_khong_duoc_de_trong": "",
        "CRM_Khach_hang_la_nguoi_lien_he": "",
        "CRM_Khach_hang_va_lien_he": "",
        "CRM_Khao_sat_y_kien_khach_hang": "",
        "CRM_Kho": "",
        "CRM_Kho_xuat": "",
        "CRM_Khoi_la_bat_buoc_nhap": "",
        "CRM_Khong": " 不是",
        "CRM_khong_co_du_lieu": "",
        "CRM_Khong_co_hang_hoa_moi_nao_duoc_chon": "",
        "CRM_Khong_co_thong_bao": "",
        "CRM_Khong_du_quyen": "",
        "CRM_Khong_duoc_de_trong": "",
        "CRM_Khong_duoc_lon_hon_ngay_den": "",
        "CRM_Khong_duoc_nho_hon_ngay_tu": "",
        "CRM_Khong_luu": "Not saved",
        "CRM_Khong_su_dung": "迟顿 ",
        "CRM_Khong_tai_ky_dat_hang": "",
        "CRM_Khong_tim_thay_du_lieu": "",
        "CRM_Khong_tim_thay_EOFFICE_URL": "",
        "CRM_Khong_tim_thay_trang": "",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_phai": "",
        "CRM_Khong_ton_tai_ma_chi_tieu_o_cong_thuc_trai": "",
        "CRM_Khong_xet1": "",
        "CRM_Khu_pho": "",
        "CRM_Khu_vuc": "",
        "CRM_Khu_vuc_nay_da_co_nhan_vien_so_huu": "",
        "CRM_Kich_thuoc_cua_kien_nghi_vuot_qua_gioi_han_cho_phep": "",
        "CRM_Kich_thuoc_cua_noi_dung_vuot_qua_gioi_han_cho_phep": "",
        "CRM_Kich_thuoc_vuot_qua_gioi_han_cho_phep": "",
        "CRM_Kiem_tra": "",
        "CRM_Kien_nghi": "",
        "CRM_Kieu_du_lieu": "",
        "CRM_Kieu_nhap_lieu": "",
        "CRM_Kieu_nhap_lieu_khong_duoc_de_trong": "",
        "CRM_KSD": "迟顿 \t",
        "CRM_Ky_bao_cao": "",
        "CRM_Ky_chua_import": "",
        "CRM_Ky_import": "",
        "CRM_Ky_niem_ngay_thanh_lap": "",
        "CRM_La_cong_tac_vien": "",
        "CRM_La_danh_sach": "",
        "CRM_La_goc": "",
        "CRM_Lap_bao_gia": "",
        "CRM_Lap_don_hang": "",
        "CRM_Lich_su": "历史",
        "CRM_Lich_su_chia_se_va_chuyen_chu_so_huu": "",
        "CRM_Lich_su_chinh_sua": "",
        "CRM_Lich_su_Import": "",
        "CRM_Lich_su_tac_dong": "",
        "CRM_Lich_su_thay_doi": "",
        "CRM_Lien_he": "",
        "CRM_Lien_he_chinh": "",
        "CRM_Loai": "",
        "CRM_Loai_bao_cao": "",
        "CRM_Loai_chia_se": "",
        "CRM_Loai_cong_viec": "",
        "CRM_Loai_cong_viec_khong_duoc_de_trong": "",
        "CRM_Loai_danh_gia": "",
        "CRM_Loai_danh_gia_khong_duoc_de_trong": "",
        "CRM_Loai_dieu_kien": "",
        "CRM_Loai_doi_tuong_ap_dung": "",
        "CRM_Loai_hang": "",
        "CRM_Loai_hang_hoa": "",
        "CRM_Loai_hoat_dong": "",
        "CRM_Loai_mau": "",
        "CRM_Loai_quy_trinh": "",
        "CRM_Loai_thong_tin_khong_duoc_de_trong": "",
        "CRM_Loai_thong_tin_quan_ly": "",
        "CRM_Loai_thong_tin_tham_chieu": "",
        "CRM_Loai_tien": "货币",
        "CRM_Loai_tinh_huong": "",
        "CRM_Loc": "过滤器",
        "CRM_Loc_chon_hanh_dong": "",
        "CRM_Loi_chua_xac_dinh": "",
        "CRM_Loi_dieu_kien_loc": "",
        "CRM_Loi_khong_lay_duoc_token_CDN": "",
        "CRM_Luu": "保存",
        "CRM_Luu_lich_su_khong_thanh_cong": "",
        "CRM_Luu_mat_khau": "",
        "CRM_Luu_quyen_thanh_cong": "",
        "CRM_Luu_thanh_cong": "",
        "CRM_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "CRM_Luu_va_nhap_tiep": "",
        "CRM_Ly_do_chuyen": "",
        "CRM_Ly_do_dong": "",
        "CRM_Ly_do_dong_bao_gia": "",
        "CRM_Ma": "Code",
        "CRM_Ma_403": "",
        "CRM_Ma_bao_cao": "Report Code",
        "CRM_Ma_chi_tieu": "",
        "CRM_Ma_co_cau": "",
        "CRM_Ma_co_ky_tu_khong_hop_le": "",
        "CRM_Ma_CTV": "",
        "CRM_Ma_da_ton_tai": "",
        "CRM_Ma_danh_gia_khong_ton_tai": "",
        "CRM_Ma_danh_sach": "",
        "CRM_Ma_dia_ban": "",
        "CRM_Ma_dia_ban_khong_duoc_de_trong": "",
        "CRM_Ma_doi_tac": "",
        "CRM_Ma_doi_thu": "",
        "CRM_Ma_doi_tuong": "对象代码",
        "CRM_Ma_hang": "产品编号",
        "CRM_Ma_hang_hoa": "",
        "CRM_Ma_khach_hang": "",
        "CRM_Ma_kho": "仓库代码",
        "CRM_Ma_khong_duoc_de_trong": "",
        "CRM_Ma_kinh_doanh_da_ton_tai": "",
        "CRM_Ma_kinh_doanh_khong_duoc_chua_ky_tu_dac_biet": "",
        "CRM_Ma_lien_he": "",
        "CRM_Ma_loai_danh_gia": "",
        "CRM_Ma_loai_danh_gia_khong_duoc_de_trong": "",
        "CRM_Ma_loai_dieu_kien": "",
        "CRM_Ma_loai_khong_duoc_chua_ky_tu_dac_biet": "",
        "CRM_Ma_loai_khong_duoc_de_trong": "",
        "CRM_Ma_loai_thong_tin": "",
        "CRM_Ma_loai_tinh_huong": "",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa": "",
        "CRM_Ma_nay_da_duoc_su_dung_ban_khong_phep_xoa": "",
        "CRM_Ma_nay_da_ton_tai": "",
        "CRM_Ma_nhan_vien": "员工代码",
        "CRM_Ma_nhan_vien_khong_duoc_chua_ky_tu_dac_biet": "",
        "CRM_Ma_nhan_vien_khong_duoc_de_trong": "",
        "CRM_Ma_nhan_vien_nay_da_ton_tai": "",
        "CRM_Ma_nhom_hang": "",
        "CRM_Ma_nhom_kinh_doanh": "",
        "CRM_Ma_nhom_kinh_doanh_khong_duoc_de_trong": "",
        "CRM_Ma_nhu_cau": "",
        "CRM_Ma_NVKD": "",
        "CRM_Ma_so_thue": "",
        "CRM_Ma_Ten": "",
        "CRM_Ma_tinh_huong": "",
        "CRM_Ma_tinh_huong_khong_duoc_de_trong": "",
        "CRM_Mac_dinh": "",
        "CRM_Mat_hang": "Item",
        "CRM_Mat_hang_chu_luc": "",
        "CRM_Mat_khau": "密码",
        "CRM_Mat_khau_cu": "",
        "CRM_Mat_khau_cu_khong_dung": "Old password is wrong",
        "CRM_Mat_khau_khong_duoc_de_trong": "",
        "CRM_Mat_khau_moi": "Change Password",
        "CRM_Mat_khau_moi_khong_hop_le": "",
        "CRM_Mat_khau_xac_nhan_khong_dung": "",
        "CRM_Mau_bao_cao": "報告碼",
        "CRM_Mau_bao_cao_khong_ton_tai": "",
        "CRM_Mau_dinh_kem": "",
        "CRM_Mau_giao_dien": "",
        "CRM_Mau_sac": "",
        "CRM_Mo": "",
        "CRM_Mo_chuong_trinh": "",
        "CRM_Mo_ta": "",
        "CRM_Mo_ta_san_pham": "",
        "CRM_MST": "",
        "CRM_Muc_do_dong_y": "",
        "CRM_Nam": "Year",
        "CRM_Nam_thang": "",
        "CRM_Nam1": "",
        "CRM_Ngay": "日期",
        "CRM_Ngay_bao_gia": "",
        "CRM_Ngay_bao_gia_khong_duoc_de_trong": "",
        "CRM_Ngay_bat_dau": "开始日期",
        "CRM_Ngay_bat_dau_khong_duoc_lon_hon_ngay_ket_thuc": "",
        "CRM_Ngay_cap": "发行日期",
        "CRM_Ngay_cap_CMND": "",
        "CRM_Ngay_cap_ho_chieu": "",
        "CRM_Ngay_cap_nhat": "",
        "CRM_Ngay_chinh_sua_cuoi": "",
        "CRM_Ngay_chung_tuU": "",
        "CRM_Ngay_chuyen": "",
        "CRM_Ngay_danh_gia": "",
        "CRM_Ngay_danh_gia_bat_buoc_nhap": "",
        "CRM_Ngay_danh_gia_khong_duoc_de_trong": "",
        "CRM_Ngay_dao_han": "Due Date",
        "CRM_Ngay_den": "",
        "CRM_Ngay_den_phai_lon_hon_ngay_tu": "",
        "CRM_Ngay_dong": "",
        "CRM_Ngay_dong_khong_duoc_nho_hon_ngay_hieu_luc": "",
        "CRM_Ngay_duyet": "批准日期",
        "CRM_Ngay_giao_hang": "",
        "CRM_Ngay_gio": "",
        "CRM_Ngay_gui": "",
        "CRM_Ngay_het_han": "到期日",
        "CRM_Ngay_het_hieu_luc": "Valid Date To",
        "CRM_Ngay_het_hieu_luc_phai_lon_hon_ngay_hieu_luc": "",
        "CRM_Ngay_hieu_luc": "有效期",
        "CRM_Ngay_hieu_luc_khong_duoc_de_trong": "",
        "CRM_Ngay_hoan_thanh": "Finish Date",
        "CRM_Ngay_hoan_thanh_khong_duoc_de_trong": "",
        "CRM_Ngay_hoan_thanh_phai_lon_hon_ngay_bat_dau": "",
        "CRM_Ngay_ket_thuc": "完成日期",
        "CRM_Ngay_ket_thuc_phai_nho_hon_ngay_bat_dau": "",
        "CRM_Ngay_lap": "Created Date",
        "CRM_Ngay_nghi_viec": "",
        "CRM_Ngay_nhan": "Received Date",
        "CRM_Ngay_phat_sinh": "Trans Date",
        "CRM_Ngay_phieu": "傳票日期",
        "CRM_Ngay_sinh": "出生日期",
        "CRM_Ngay_sua": "",
        "CRM_Ngay_tac_dong": "",
        "CRM_Ngay_tao": "",
        "CRM_Ngay_tao_khach_hang": "",
        "CRM_Ngay_thanh_lap": "",
        "CRM_Ngay_them": "",
        "CRM_Ngay_tinh_no": "As of Date",
        "CRM_Ngay_tu": "",
        "CRM_Ngay_tu/Ngay_den_bat_buoc_nhap": "",
        "CRM_Nghiep_vu": "",
        "CRM_Ngung_hoat_dong": "",
        "CRM_Nguoi_ban_giao_va_nguoi_duoc_ban_giao_phai_khac_nhau": "",
        "CRM_Nguoi_bao_gia": "",
        "CRM_Nguoi_cap_nhat": "",
        "CRM_Nguoi_chuyen": "",
        "CRM_Nguoi_dai_dien_phap_luat": "",
        "CRM_Nguoi_danh_gia": "",
        "CRM_Nguoi_danh_gia_bat_buoc_nhap": "",
        "CRM_Nguoi_dong": "",
        "CRM_Nguoi_dung": "用戶",
        "CRM_Nguoi_dung_khong_duoc_de_trong": "",
        "CRM_Nguoi_dung_khong_su_dung": "",
        "CRM_Nguoi_duyet": "批准者",
        "CRM_Nguoi_giai_quyet": "",
        "CRM_Nguoi_gui": "",
        "CRM_Nguoi_lap": "创建者",
        "CRM_Nguoi_lien_he": "",
        "CRM_Nguoi_lien_he_chinh": "",
        "CRM_Nguoi_nhan": "",
        "CRM_Nguoi_phu_trach": "",
        "CRM_Nguoi_sua": "",
        "CRM_Nguoi_tao": "",
        "CRM_Nguoi_them": "",
        "CRM_Nguoi_thuc_hien": "",
        "CRM_Nguoi_uy_quyen": "",
        "CRM_Nguon_goc": "",
        "CRM_Nguon_them_khach_hang_va_lien_he": "",
        "CRM_Nhan_don_hang": "",
        "CRM_Nhan_tiep": "",
        "CRM_Nhan_vien": "",
        "CRM_Nhan_vien_ban_giao": "",
        "CRM_Nhan_vien_kinh_doanh": "Sales Person",
        "CRM_Nhan_vien_nay_da_duoc_su_dung": "",
        "CRM_Nhan_vien_nhan_ban_giao": "",
        "CRM_Nhap": "",
        "CRM_Nhap_chuoi": "",
        "CRM_Nhap_it_nhat_1_san_pham": "",
        "CRM_Nhap_noi_dung_kien_nghi_can_tim_kiem": "",
        "CRM_Nhap_noi_dung_tim_kiem": "",
        "CRM_Nhap_Ten_Dang_Nhap": "Enter username",
        "CRM_Nhap_tiep": "",
        "CRM_Nho_mat_khau1": "",
        "CRM_Nhom_cong_ty": "",
        "CRM_Nhom_hang_chu_luc": "",
        "CRM_Nhom_khach_hang": "客户集团",
        "CRM_Nhom_kinh_doanh": "",
        "CRM_Nhom_kinh_doanh_khong_duoc_de_trong": "",
        "CRM_Nhom_nganh_nghe": "",
        "CRM_Nhom_phan_loai": "",
        "CRM_Nhom_phan_quyen": "",
        "CRM_Nhom_thue": "",
        "CRM_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "CRM_Noi_cap": "发行地点",
        "CRM_Noi_cap_CMND": "",
        "CRM_Noi_dung": "内容",
        "CRM_Noi_dung_can_tim": "",
        "CRM_Noi_dung_cong_viec": "Work content",
        "CRM_Noi_dung_khao_sat": "",
        "CRM_Noi_dung_khong_duoc_de_trong": "",
        "CRM_Noi_dung_tim_kiem": "",
        "CRM_Noi_giao_hang": "",
        "CRM_Noi_nhan_hang": "",
        "CRM_Nu": "",
        "CRM_NVKD": "",
        "CRM_Phai_chon_nguoi_ban_giao": "",
        "CRM_Phai_chon_nguoi_duoc_ban_giao": "",
        "CRM_Phan_he": "",
        "CRM_Phan_loai": "",
        "CRM_Phan_loai_doi_tac": "",
        "CRM_Phan_loai_doi_thu": "",
        "CRM_Phan_loai_KH": "",
        "CRM_Phan_loai_khach_hang": "",
        "CRM_Phan_loai_nhom": "",
        "CRM_Phien_ban": "",
        "CRM_Phong_ban": "部门",
        "CRM_Phuong": "",
        "CRM_Phuong_phap_tinh_tuoi_no": "",
        "CRM_Phuong_thuc_giao_hang": "",
        "CRM_Phuong_thuc_TT": "",
        "CRM_Phuong_xa": "",
        "CRM_phut": "",
        "CRM_PT_giao_hang": "",
        "CRM_PT_thanh_toan": "",
        "CRM_Quan": "",
        "CRM_Quan_huyen": "",
        "CRM_Quan_ly": "",
        "CRM_Quan_ly_danh_muc": "",
        "CRM_Quay_lai": "",
        "CRM_Quoc_gia": "",
        "CRM_Quy_cach_dong_goi": "",
        "CRM_Quy_mo_cong_ty": "",
        "CRM_Quy_uoc_nhu_sau": "",
        "CRM_Sao_chep_bao_gia": "",
        "CRM_Sao_chep_chuong_trinh": "",
        "CRM_Sao_chep_doi_tac": "",
        "CRM_Sao_chep_doi_thu": "",
        "CRM_Sao_chep_khach_hang": "",
        "CRM_Sau_so_ngay": "",
        "CRM_Sinh_nhat": "",
        "CRM_Skype": "",
        "CRM_SL": "Qty",
        "CRM_SL_co_the_xuat": "",
        "CRM_SL_hoa_don": "",
        "CRM_SL_ton_thuc_te": "",
        "CRM_So_bao_gia": "",
        "CRM_So_CCCD_/_CMND": "",
        "CRM_So_chung_tu": "文件编号",
        "CRM_So_di_dong": "",
        "CRM_So_dien_thoai": "Tel no.",
        "CRM_So_dien_thoai_khong_hop_le": "",
        "CRM_So_dien_thoai_nguoi_lien_he": "",
        "CRM_So_dien_thoai_trung_voi_lien_he": "",
        "CRM_So_don_hang": "销售订单编号",
        "CRM_So_dong": "",
        "CRM_So_dong_RPC": "",
        "CRM_So_dong_tren_mot_trang": "",
        "CRM_So_Fax": "",
        "CRM_So_lung_den_khong_duoc_de_trong": "",
        "CRM_So_luong": "数量",
        "CRM_So_luong_co_the_dat_ban": "",
        "CRM_So_luong_dang_dat_ban": "",
        "CRM_So_luong_dang_dat_hoa_don_ban": "",
        "CRM_So_luong_den_phai_lon_hon_so_luong_tu": "",
        "CRM_So_luong_khong_duoc_de_trong": "",
        "CRM_So_luong_QD": "",
        "CRM_So_luong_sau_DC": "",
        "CRM_So_luong_toi_thieu": "",
        "CRM_So_luong_ton_kho": "",
        "CRM_So_luong_tu_khong_duoc_de_trong": "",
        "CRM_So_luong_tu_phai_nho_hon_so_luong_den": "",
        "CRM_So_may_ban": "",
        "CRM_So_tai_khoan": "",
        "CRM_So_thu_tu": "",
        "CRM_Status": "",
        "CRM_Store_kiem_tra_bi_loi": "",
        "CRM_STT": " 序",
        "CRM_Su_dung_phan_loai": "",
        "CRM_Su_dung_tai": "",
        "CRM_Sua": "Edit",
        "CRM_Tai_khoan": "帐户",
        "CRM_Tai_khoan_bi_khoa_10_phut": "",
        "CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "CRM_Tai_khoan_khong_co_quyen": "",
        "CRM_Tai_len": "",
        "CRM_Tai_lieu": "",
        "CRM_Tai_lieu_da_dinh_kem": "",
        "CRM_Tai_lieu_dinh_kem": "",
        "CRM_Tai_U": "",
        "CRM_Tam_dap_ung_Can_cai_tien_them": "",
        "CRM_Tang_dan": "",
        "CRM_Tang_luong_truoc_thoi_han": "",
        "CRM_Tap_tin": "",
        "CRM_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "CRM_Tat_ca": "",
        "CRM_Ten": "名称 \t",
        "CRM_Ten_bao_cao": "Report Name",
        "CRM_Ten_cong_viec": "",
        "CRM_Ten_CTV": "",
        "CRM_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "CRM_Ten_dang_nhap_khong_duoc_de_trong": "",
        "CRM_Ten_danh_sach": "",
        "CRM_Ten_danh_sach_khong_duoc_de_trong": "",
        "CRM_Ten_dia_ban": "",
        "CRM_Ten_dia_ban_khong_duoc_de_trong": "",
        "CRM_Ten_doi_tac": "",
        "CRM_Ten_doi_thu": "",
        "CRM_Ten_don_vi_tinh": "",
        "CRM_Ten_File": "",
        "CRM_Ten_hang": "产品名称",
        "CRM_Ten_hang_hoa": "",
        "CRM_Ten_khach_hang": "",
        "CRM_Ten_kho": "仓库名称",
        "CRM_Ten_khong_duoc_de_trong": "",
        "CRM_Ten_lien_he": "",
        "CRM_Ten_loai_danh_gia": "",
        "CRM_Ten_loai_khong_duoc_de_trong": "",
        "CRM_Ten_loai_thong_tin": "",
        "CRM_Ten_loai_tinh_huong": "",
        "CRM_Ten_mau": "",
        "CRM_Ten_nhom_hang": "",
        "CRM_Ten_nhom_khach_hang": "",
        "CRM_Ten_nhom_kinh_doanh": "",
        "CRM_Ten_nhom_kinh_doanh_khong_duoc_de_trong": "",
        "CRM_Ten_NVKD": "",
        "CRM_Ten_phan_loai_khach_hang": "",
        "CRM_Ten_tat": "",
        "CRM_Ten_tinh_huong": "",
        "CRM_Ten_truong_thiet_lap": "",
        "CRM_Test_1": "",
        "CRM_Tham_so_truyen_vao_khong_hop_le": "",
        "CRM_Thang": "",
        "CRM_Thang_1": "",
        "CRM_Thang_10": "",
        "CRM_Thang_11": "",
        "CRM_Thang_12": "",
        "CRM_Thang_2": "",
        "CRM_Thang_3": "",
        "CRM_Thang_4": "",
        "CRM_Thang_5": "",
        "CRM_Thang_6": "",
        "CRM_Thang_7": "",
        "CRM_Thang_8": "",
        "CRM_Thang_9": "",
        "CRM_Thang_chua_nop_bao_cao": "",
        "CRM_Thanh_pho": "",
        "CRM_Thanh_tien": "Amount",
        "CRM_Thanh_tien_QD": "",
        "CRM_Thanh_tien_QD_sau_CK": "",
        "CRM_Thanh_tien_sau_CK": "",
        "CRM_Thanh_tien_sau_DC": "",
        "CRM_Thanh_tien_sau_DC_QD": "",
        "CRM_Thanh_tien_sau_thue": "",
        "CRM_Thanh_tien_sau_thue_QD": "",
        "CRM_Thanh_tien_truoc_thue": "",
        "CRM_Thao_luan": "",
        "CRM_Thao_tac": "",
        "CRM_Thay_doi_loai_tien_se_tinh_toan_lai_thanh_tien_QD_ban_co_muon_tiep_tuc_khong": "",
        "CRM_Thay_doi_mat_khau_khong_thanh_cong": "",
        "CRM_Thay_doi_nghiep_vu_se_xoa_du_lieu_luoi_mat_hang_ban_co_muon_tiep_tuc_khong": "",
        "CRM_Them": "Add",
        "CRM_Them_dieu_kien_loc": "",
        "CRM_Them_dong": "",
        "CRM_Them_hang_hoa": "",
        "CRM_Them_hinh_anh_mat_hang": "",
        "CRM_Them_khong_thanh_cong": "",
        "CRM_Them_moi": "",
        "CRM_Them_moi_khach_hang": "",
        "CRM_Them_moi1": "添新",
        "CRM_Them_nhom_kinh_doanh": "",
        "CRM_Them_vao_danh_sach": "",
        "CRM_Theo_nhan_vien": "",
        "CRM_Thiet_lap": "",
        "CRM_Thiet_lap_he_thong": "系统设置",
        "CRM_Thiet_lap_khach_hang_chung": "",
        "CRM_Thiet_lap_mail": "",
        "CRM_Thiet_lap_mau_bao_cao": "",
        "CRM_Thiet_lap_phan_quyen": "",
        "CRM_Thiet_lap_thong_tin_Email": "",
        "CRM_Thiet_lap_truong_bat_buoc": "",
        "CRM_Thiet_lap_truong_bat_buoc_nhap": "",
        "CRM_Thoi_gian": "时间",
        "CRM_Thoi_gian_den_nho_hon_tu": "",
        "CRM_Thoi_gian_giao_hang": "",
        "CRM_Thoi_gian_khong_tai_ky_dat_hang": "",
        "CRM_Thoi_gian_thuc_hien": "Execution Time",
        "CRM_Thoi_gian_tu_lon_hon_den": "",
        "CRM_Thong_bao": "Announcement",
        "CRM_thong_bao_moi": "",
        "CRM_Thong_bao1": "",
        "CRM_Thong_baoU": "",
        "CRM_Thong_so_thoi_gian": "",
        "CRM_Thong_tin_bao_gia": "",
        "CRM_Thong_tin_bo_sung": "",
        "CRM_Thong_tin_ca_nhan": "个人信息",
        "CRM_Thong_tin_chinh": "",
        "CRM_Thong_tin_chung": "一般资料",
        "CRM_Thong_tin_cong_ty": "",
        "CRM_Thong_tin_cung_cap_khong_hop_le": "",
        "CRM_Thong_tin_don_hang": "",
        "CRM_Thong_tin_hang_hoa": "",
        "CRM_Thong_tin_khach_hang": "",
        "CRM_Thong_tin_phu": "",
        "CRM_Thong_tin_thue_GTGT": "",
        "CRM_Thu_gon": "",
        "CRM_Thu_tu_hien_thi": "序列号",
        "CRM_Thue": "",
        "CRM_Thue_GTGT": "VAT",
        "CRM_Thue_GTGT_sau_QD": "",
        "CRM_Thue_suat": "",
        "CRM_Thue_suat_khong_duoc_lon_hon": "",
        "CRM_Tien_ban": "",
        "CRM_Tien_chiet_khau": "",
        "CRM_Tien_CK": "扣款",
        "CRM_Tien_CK_QD": "貼現現金轉換",
        "CRM_Tien_do_bao_gia": "",
        "CRM_Tien_do_don_hang": "",
        "CRM_Tien_hang": "量",
        "CRM_Tien_te": "",
        "CRM_Tien_te_khong_duoc_de_trong": "",
        "CRM_Tien_thue": "稅金額",
        "CRM_Tien_thue_QD": "轉換後的納稅金額",
        "CRM_Tieu_de": "Title",
        "CRM_Tieu_de_khong_duoc_de_trong": "",
        "CRM_Tieu_de_mail": "",
        "CRM_Tieu_thuc_nhom": "",
        "CRM_Tim_kiem": "",
        "CRM_Tim_kiem_khach_hang": "",
        "CRM_Tim_kiem_lien_he": "",
        "CRM_Tim_kiem_nang_cao": "",
        "CRM_Time_from_>_time_to": "",
        "CRM_Tinh": "",
        "CRM_Tinh_huong": "",
        "CRM_Tinh_thanh_pho": "",
        "CRM_To_chuc": "组织",
        "CRM_Ton_tai_chi_tieu_co_gia_tri_khong_hop_le": "",
        "CRM_Tong": "",
        "CRM_Tong_chi_tieu_cua_nhom_khong_duoc_nho_hon_chi_tieu_chi_tiet": "",
        "CRM_Tong_diem": "",
        "CRM_Tong_gia_tri_A": "",
        "CRM_Tong_gia_tri_A_khong_bang_tong_gia_tri_B": "",
        "CRM_Tong_gia_tri_B": "",
        "CRM_Tong_gia_tri_bao_gia": "",
        "CRM_Tong_gia_tri_don_hang": "",
        "CRM_Tong_gia_tri_don_hang_QD": "",
        "CRM_Tong_hop": "合成",
        "CRM_Tong_hop_khach_hang_moi": "",
        "CRM_Tong_so_luong_bao_gia": "",
        "CRM_Tong_thanh_toan": "付款總額",
        "CRM_Tong_thanh_toan_QD": "付款總額轉換",
        "CRM_Tong_tien": "Total",
        "CRM_Tong_tien_QD": "al C.Amount",
        "CRM_Tong_tien_sau_DC": "",
        "CRM_Tong_tien_sau_DC_QD": "",
        "CRM_Trang": "",
        "CRM_Trang_Chu": "首页",
        "CRM_Trang_thai": "状态",
        "CRM_Trang_thai_chi_tiet": "",
        "CRM_Trang_thai_cu": "",
        "CRM_Trang_thai_KH": "",
        "CRM_Trang_thai_khach_hang": "",
        "CRM_Trang_thai_moi": "",
        "CRM_Trang_thai_thuc_hien": "",
        "CRM_Trang_thai_thuc_hien_DH": "",
        "CRM_Trang_thai_vao_ra": "",
        "CRM_Tre_han": "",
        "CRM_Trinh_duyet_khong_co_quyen_mo_popup": "",
        "CRM_Tron_truong": "",
        "CRM_trong": "",
        "CRM_Truc_thuoc_nhom": "",
        "CRM_Truong_thiet_lap": "",
        "CRM_Truy_cap_khong_hop_le": "",
        "CRM_Tu": "从",
        "CRM_Tu_choi": "拒绝",
        "CRM_Tu_ngay": "Date from",
        "CRM_Tuy_chon_cong_ty": "",
        "CRM_Ty_gia": "汇率",
        "CRM_Ty_le_chiet_khau": "",
        "CRM_Ty_le_CK": "",
        "CRM_Ty_le_qua_han": "",
        "CRM_Vao_luc": "",
        "CRM_Vao_ngay": "",
        "CRM_Vi_tri_dia_ly": "",
        "CRM_Viet_binh_luan": "",
        "CRM_VoucherID_khong_duoc_de_trong": "",
        "CRM_Vui_long_chon_hinh_anh": "",
        "CRM_Vui_long_chon_ma_hang_va_don_vi_tinh": "",
        "CRM_Vui_long_nhap_day_du_don_gia_truoc_thue_va_so_luong_hang_hoa": "",
        "CRM_Vui_long_nhap_day_du_thong_tin": "",
        "CRM_Vui_long_truy_cap_sau": "",
        "CRM_Xa": "",
        "CRM_Xac_nhan_mat_khau_moi": "",
        "CRM_XaPhuong": "",
        "CRM_Xem": "看",
        "CRM_Xem_bao_cao": "",
        "CRM_Xem_it_hon": "",
        "CRM_Xem_nhieu_hon": "",
        "CRM_Xem_tap_tin": "",
        "CRM_Xem_tat_ca": "",
        "CRM_Xem_them": "",
        "CRM_Xem_truoc": "",
        "CRM_Xin_cam_on": "",
        "CRM_Xoa": "Delete",
        "CRM_Xoa_khong_thanh_cong": "",
        "CRM_Xoa_quyen_thanh_cong": "",
        "CRM_Xuat_bao_cao": "出口報告",
        "CRM_Xuat_danh_sach_khach_hang": "",
        "CRM_Xuat_danh_sach_lien_he": "",
        "CRM_Xuat_du_lieu": "",
        "CRM_Xuat_Excel": "",
        "CRM_Xuat_khau_khach_hang": "",
        "CRM_Xuat_PDF": "",
        "CRM_Xuat_word": "",
        "CRM_Y_kien_dong_gop": "",
        "CRM_Yeu_cau_chuyen_doi": "轉換要求",
        "CRM_Yeu_cau_chuyen_doi_thanh_cong": ""
    },
    "timestamps": 1690442534889
};
/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW18F1022: null,
    loadCbTranType: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1022_LOAD_COMPLETE:
            return {
                ...state,
                loadW18F1022: action.loadW18F1022
            };
        case types.W18_W18F1022_LOAD_TRANSTYPE_COMPLETE:
            return {
                ...state,
                loadCbTranType: action.loadCbTranType
            };
        default:
            return state;
    }
}
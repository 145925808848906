/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    dataMaster: null,
    dataPopUp: null,
    dataWareHouse:null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1017_DATAMASTER_COMPLETE:
            return {
                ...state,
                dataMaster: action.dataMaster
            };
        case types.W18_W18F1017_POPUP_COMPLETE:
            return {
                ...state,
                dataPopUp: action.dataPopUp
            };
            case types.W18_W18F1017_WAREHOUSE_COMPLETE:
            return {
                ...state,
                dataWareHouse: action.dataWareHouse
            };

        default:
            return state;
    }
}
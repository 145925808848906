/**
 * @copyright 2019 @ DigiNet
 * @author tranhoang
 * @create 2019/06/0315:37
 * @file src/reducers/dash-board.js
 */

import * as types from '../actions/types.js';

const initialState = {
    listDashboard: null,
    typeTime: null,
    typeTime2: null,
    listReportColumn: null,
    gridReportColumn: null,
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.GET_LIST_PART_COMPLETE:
            return {
                ...state,
                listDashboard: action.listDashboard
            };
        case types.GET_TYPE_TIME_COMPLETE:
            return {
                ...state,
                typeTime: action.typeTime
            };
        case types.GET_TYPE_TIME2_COMPLETE:
            return {
                ...state,
                typeTime2: action.typeTime2
            };
        case types.GET_LIST_REPORT_COMPLETE:
            return {
                ...state,
                listReportColumn: action.listReportColumn
            };
        case types.GET_GRID_REPORT_COMPLETE:
            return {
                ...state,
                gridReportColumn: action.gridReportColumn
            };
        default:
            return state;
    }
}
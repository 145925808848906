/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/4/9
 * @update 2019/4/9
 */

import * as types from '../actions/types.js';

const initialState = {
    dataGrid: null,
    cboShipAddress: null,
    dataShare: null,
    dataMaster: null,
    numValue: null,
    extraInfo: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W19_W19F2001_CBOSHIPADDRESS_COMPLETE:
            return {
                ...state,
                cboShipAddress: action.cboShipAddress
            };
        case types.W19_W19F2001_CBOSHARE_COMPLETE:
            return {
                ...state,
                dataShare: action.dataShare
            };
        case types.W19_W19F2001_CBOSTATUS_COMPLETE:
            return {
                ...state,
                cboStatus: action.cboStatus
            };
        case types.W19_W19F2001_DATAMASTER_COMPLETE:
            return {
                ...state,
                dataMaster: action.dataMaster
            };
        case types.W19_W19F2001_DATAGRID_COMPLETE:
            return {
                ...state,
                dataGrid: action.dataGrid
            };
        case types.W19_W19F2001_NUMVALUE_COMPLETE:
            return {
                ...state,
                numValue: action.numValue
            };
        case types.W19_W19F2001_EXTRAINFO_COMPLETE:
            return {
                ...state,
                extraInfo: action.extraInfo
            };
        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from '../actions/types.js';

const initialState = {
    cboCustomerStatus:null,
    cboCustomerLevel:null,
    cboFieldGroup:null,
    dataCboGender: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18F4001_LOAD_CUSTOMER_STATUS_COMPLETE:
            return {
                ...state,
                cboCustomerStatus: action.cboCustomerStatus
            };
        case types.W18F4001_LOAD_CUSTOMER_LEVEL_COMPLETE:
            return {
                ...state,
                cboCustomerLevel: action.cboCustomerLevel
            };
        case types.W18F4001_LOAD_FIELD_GROUP_COMPLETE:
            return {
                ...state,
                cboFieldGroup: action.cboFieldGroup
            };
        case types.W18F4001_CBO_GENDER_COMPLETE:
            return {
                ...state,
                dataCboGender: action.dataCboGender
            };
        default:
            return state;
    }
}

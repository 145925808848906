/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/09/04 16:50
 * @update 2019/09/04 16:50
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW17F1510: null,
    addW17F1511: null,
    editW17F1511: null,
    delW17F1511: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F1510_DATAGRID_COMPLETE:
            return {
                ...state,
                loadW17F1510: action.loadW17F1510
            };
        case types.W17_W17F1511_ADD_COMPLETE:
            return {
                ...state,
                addW17F1511: action.addW17F1511
            };
        case types.W17_W17F1511_EDIT_COMPLETE:
            return {
                ...state,
                editW17F1511: action.editW17F1511
            };
        case types.W17_W17F1511_DELETE_COMPLETE:
            return {
                ...state,
                delW17F1511: action.delW17F1511
            };
        default:
            return state;
    }
}
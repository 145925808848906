/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/08/12 11:00
 * @update 2019/08/12 11:00
 */

import * as types from '../actions/types.js';

const initialState = {
    dataGrid: null,
    cbSalePerson: null,
    cbEvaluation: null,
    deleteEvaluation: null,
    cbTypeTime: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W19_W19F1030_DATAGRID_COMPLETE:
            return {
                ...state,
                dataGrid: action.dataGrid
            };
        case types.W19_W19F1030_CB_SALEPERSON_COMPLETE:
            return {
                ...state,
                cbSalePerson: action.cbSalePerson
            };
        case types.W19_W19F1030_CB_EVALUATION_COMPLETE:
            return {
                ...state,
                cbEvaluation: action.cbEvaluation
            };
        case types.W19_W19F1030_DELETE_COMPLETE:
            return {
                ...state,
                deleteEvaluation: action.deleteEvaluation
            };
        case types.W19_W19F1030_CB_TYPETIME_COMPLETE:
            return {
                ...state,
                cbTypeTime: action.cbTypeTime
            };
        default:
            return state;
    }
}
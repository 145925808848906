/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 03/01/2021
 * @update 03/01/2021
 */

import _ from "lodash";
import * as types from '../actions/types.js';

const initialState = {
    dataCboStatus: [],
    dataCboDivision: [],
    dataCboTranType: [],
    dataCboEmployee: [],
    dataCboDeliveryMethod: [],
    dataCboCurrency: [],
    dataCboVATGroup: [],
    dataCboPaymentTerm: [],
    dataCboPaymentMethod: [],
    dataDMFSetting: {},
    dataCboVAT: [],
    dataCboWarehouse: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F2040_GET_CBO_DIVISION_SUCCESS:
            let dataCboDivision = [];
            if (!_.isEmpty(action.dataCboDivision)) {
                dataCboDivision = action.dataCboDivision.map(item => {
                    const line = !_.isEmpty(item.DivisionName) ? "-" : "";
                    return ({ ...item, DivisionCustomName: `${item.DivisionID} ${line} ${item.DivisionName}` })
                });
            }
            return { ...state, dataCboDivision };
        case types.W17_W17F2040_GET_CBO_TRANTYPE_SUCCESS:
            let dataCboTranType = [];
            if (!_.isEmpty(action.dataCboTranType)) {
                dataCboTranType = action.dataCboTranType.map(item => {
                    const line = !_.isEmpty(item.Description) ? "-" : "";
                    return ({ ...item, TranTypeCustomName: `${item.TranTypeID} ${line} ${item.Description}` })
                });
            }
            return { ...state, dataCboTranType };
        case types.W17_W17F2040_GET_CBO_EMPLOYEE_SUCCESS:
            let dataCboEmployee = [];
            if (!_.isEmpty(action.dataCboEmployee)) {
                dataCboEmployee = action.dataCboEmployee.map(item => {
                    const line = !_.isEmpty(item.EmployeeName) ? "-" : "";
                    return ({ ...item, EmployeeCustomName: `${item.EmployeeID} ${line} ${item.EmployeeName}` })
                });
            }
            return { ...state, dataCboEmployee };
        case types.W17_W17F2040_GET_CBO_DELIVERY_SUCCESS:
            let dataCboDeliveryMethod = [];
            if (!_.isEmpty(action.dataCboDeliveryMethod)) {
                dataCboDeliveryMethod = action.dataCboDeliveryMethod.map(item => {
                    const line = !_.isEmpty(item.DeliveryMethodName) ? "-" : "";
                    return ({ ...item, DeliveryMethodCustomName: `${item.DeliveryMethodID} ${line} ${item.DeliveryMethodName}` })
                });
            }
            return { ...state, dataCboDeliveryMethod };
        case types.W17_W17F2040_GET_CBO_PAYMENT_TERM_SUCCESS:
            let dataCboPaymentTerm = [];
            if (!_.isEmpty(action.dataCboPaymentTerm)) {
                dataCboPaymentTerm = action.dataCboPaymentTerm.map(item => {
                    const line = !_.isEmpty(item.PaymentTermName) ? "-" : "";
                    return ({ ...item, PaymentTermCustomName: `${item.PaymentTermID} ${line} ${item.PaymentTermName}` })
                });
            }
            return { ...state, dataCboPaymentTerm };
        case types.W17_W17F2040_GET_CBO_PAYMENT_METHOD_SUCCESS:
            let dataCboPaymentMethod = [];
            if (!_.isEmpty(action.dataCboPaymentMethod)) {
                dataCboPaymentMethod = action.dataCboPaymentMethod.map(item => {
                    const line = !_.isEmpty(item.PaymentMethodName) ? "-" : "";
                    return ({ ...item, PaymentMethodCustomName: `${item.PaymentMethodID} ${line} ${item.PaymentMethodName}` })
                });
            }
            return { ...state, dataCboPaymentMethod };
        case types.W17_W17F2040_GET_CBO_STATUS_SUCCESS:
            let dataCboStatus = [];
            if (!_.isEmpty(action.dataCboStatus)) {
                dataCboStatus = action.dataCboStatus.map(item => {
                    const line = !_.isEmpty(item.StatusName) ? "-" : "";
                    return ({ ...item, StatusCustomName: `${item.StatusID} ${line} ${item.StatusName}` })
                });
            }
            return { ...state, dataCboStatus };
        case types.W17_W17F2040_GET_CBO_CURRENCY_SUCCESS:
            return { ...state, dataCboCurrency: action.dataCboCurrency };
        case types.W17_W17F2040_GET_CBO_VAT_GROUP_SUCCESS:
            return { ...state, dataCboVATGroup: action.dataCboVATGroup };
        case types.W17_W17F2040_GET_DMF_SETTING_SUCCESS:
            return { ...state, dataDMFSetting: action.dataDMFSetting?.[0] || {} };
        case types.W17_W17F2040_GET_CBO_VAT_SUCCESS:
            return { ...state, dataCboVAT: action.dataCboVAT };
        case types.W17_W17F2040_GET_CBO_WAREHOUSE_SUCCESS:
            return { ...state, dataCboWarehouse: action.data };
        default:
            return state;
    }
}

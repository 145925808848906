/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from '../actions/types.js';

const initialState = {
    loadW17F0041: null,
    loadW17F0040: null,
    loadW17F0040Detail:null,
    addW17F0041: null,
    editW17F0041:null,
    delW17F0041:null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F0040_LOAD_GRID_REPORTTYPE_COMPLETE:
            return {
                ...state,
                loadW17F0040: action.loadW17F0040
            };
        case types.W17F0041_LOAD_CB_REPORTTYPE_COMPLETE:
            return {
                ...state,
                loadW17F0041: action.loadW17F0041
            };
        case types.W17F0040_LOAD_GRID_REPORT_COMPLETE:
            return {
                ...state,
                loadW17F0040Detail: action.loadW17F0040Detail
            };
        case types.W17_W17F0041_ADD_COMPLETE:
            return {
                ...state,
                addW17F0041: action.addW17F0041
            };
        case types.W17_W17F0041_EDIT_COMPLETE:
            return {
                ...state,
                editW17F0041: action.editW17F0041
            };
        case types.W17_W17F0041_DELETE_COMPLETE:
            return {
                ...state,
                delW17F0041: action.delW17F0041
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/03/15 11:30
 * @update 2019/03/15 11:30
 */

import * as types from '../actions/types.js';

const initialState = {
    changePassW17F0020: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17_W17F0020_LOAD_COMPLETE:
            return {
                ...state,
                changePassW17F0020: action.changePassW17F0020
            };
        default:
            return state;
    }
}
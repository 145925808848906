/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/02 16:36
 * @update 2019/10/02 16:36
 */


import * as types from '../actions/types.js';

const initialState = {
    cbCaseTypes: [],
    cbMedia: [],
    cbStatus: [],
    createPerson: null,
    loadEditW18F1061: null,
    addW18F1061: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1061_CB_CUSTOMERS_COMPLETE:
            return {
                ...state,
                cbCustomers: action.cbCustomers
            };
        case types.W18_W18F1061_CB_CASETYPE_COMPLETE:
            return {
                ...state,
                cbCaseTypes: action.cbCaseTypes
            };
        case types.W18_W18F1061_CB_MEDIA_COMPLETE:
            return {
                ...state,
                cbMedia: action.cbMedia
            };
        case types.W18_W18F1061_CB_STATUS_COMPLETE:
            return {
                ...state,
                cbStatus: action.cbStatus
            };
        case types.W18_W18F1061_CB_CREATEPERSON_COMPLETE:
            return {
                ...state,
                createPerson: action.createPerson
            };
        case types.W18_W18F1061_CB_SALEPERSON_COMPLETE:
            return {
                ...state,
                cbSalePersons: action.cbSalePersons
            };
        case types.W18_W18F1061_EDIT_FORM_COMPLETE:
            return {
                ...state,
                loadEditW18F1061: action.loadEditW18F1061
            };
        case types.W18_W18F1051_ADD_COMPLETE:
            return {
                ...state,
                addW18F1061: action.addW18F1061
            };
        default:
            return state;
    }
}

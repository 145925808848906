/**
 * @copyright 2021 @ DigiNet
 * @author thanhHai
 * @create 15/3/2021
 * @update 15/3/2021
 */

import * as types from '../../actions/types.js';

const initialState = {
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F2051_GET_CBO_CATEGORY_TYPE_COMPLETE:
            return {
                ...state,
                cboCategoryType: action.data
            };
        case types.W17F2051_GET_CBO_GENDER_COMPLETE:
            return {
                ...state,
                cboGender: action.data
            };
        case types.W17F2051_GET_ANA_MASTER_COMPLETE:
            return {
                ...state,
                anaMaster: action.data
            };
        case types.W17F2051_GET_ANA_MASTER_CAPTION_COMPLETE:
            return {
                ...state,
                anaMasterCation: action.data
            };
            case types.W17F2051_LOAD_FORM_MASTER_COMPLETE:
                return {
                    ...state,
                    formMaster: action.data
                };
        default:
            return state;
    }
}
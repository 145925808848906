/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/14 17:37
 * @update 2019/02/14 17:37
 */

import * as types from '../../actions/types.js';

const initialState = {
    loadW17F1025Store: null,
    dataFilter: null,
    delW17F1025: null,
    loadCompertitorW17F1025: null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W17F1025_STORE_COMPLETE:
            return {
                ...state,
                loadW17F1025Store: action.loadW17F1025Store
            };
        case types.W17_W17F1025_FILTER_COMPLETE:
            return {
                ...state,
                dataFilter: action.dataFilter
            };
        case types.W17_W17F1025_DEL_COMPLETE:
            return {
                ...state,
                delW17F1025: action.delW17F1025
            };
        case types.W17F1025_COMPERTITOR_COMPLETE:
            return {
                ...state,
                loadCompertitorW17F1025: action.loadCompertitorW17F1025
            };
        default:
            return state;
    }
}
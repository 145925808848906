/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/14 02:42
 * @update 2019/01/14 02:42
 * @file src/reducers/loading.js
 */

import * as types from '../actions/types';

const initialState = {
    token: false,
    profile: false,
    setting: false,
    language: false,
    maintenance: null
};

export default function(state = initialState, action = {}) {

    switch (action.type) {
        case types.GET_SETTING_COMPLETE:
            return {
                ...state,
                token: action.token,
                profile: action.profile,
                setting: action.setting,
                language:  action.language,
                maintenance: action.maintenance
            };
        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */


import * as types from '../actions/types.js';
import _ from "lodash";

const initialState = {
    dataGrid: null,
    masterW19: null,
    dataGridW19: null,
    loadBaseCurrency: null,
    cbTypeTimeW19F1020: null,
    loadDataW19F1020: null,
    statusList: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W19_W19F1020_DATAGRID_COMPLETE:
            return {
                ...state,
                dataGrid: action.dataGrid
            };
        case types.W19_W19F1021_MASTER_COMPLETE:
            return {
                ...state,
                masterW19: action.masterW19
            };
        case types.W19_W19F1021_DATAGRID_COMPLETE:
            return {
                ...state,
                dataGridW19: action.dataGridW19
            };
        case types.W19_W19F1021_LOAD_BASE_CURRENCY_COMPLETE:
            return {
                ...state,
                loadBaseCurrency: action.loadBaseCurrency
            };
        case types.W19_W19F1020_CB_TYPE_TIME_COMPLETE:
            return {
                ...state,
                cbTypeTimeW19F1020: action.cbTypeTimeW19F1020
            };
        case types.W19_W19F1020_LOAD_DATA_COMPLETE:
            return {
                ...state,
                loadDataW19F1020: action.loadDataW19F1020
            };
        case types.W19_W19F1021_LOAD_LIST_STATUS_COMPLETE:
            let statusList = action.statusList;
            let ListStatus = _.get(action.statusList, "ListStatus", []);
            const TotalStatusID = _.get(action.statusList, "TotalStatusID", 0);
            if (!_.isEmpty(ListStatus)) {
                ListStatus = action.statusList.ListStatus.map(item => ({
                    number: item.StatusID,
                    title: item.StatusSOName,
                    info: item?.Info || []
                }));
                statusList = {
                    TotalStatusID,
                    ListStatus
                }
            }
            return {
                ...state,
                statusList
            };
        default:
            return state;
    }
}

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/4/9
 * @update 2019/4/9
 */

import * as types from '../actions/types.js';

const initialState = {
    cboEvaluate: null,
};

export default function (state = initialState, action = {}) {
    const {type} = action;
    if (type === types.W19_W19F1031_CBOEVALUATE_COMPLETE) {
        return {
            ...state,
            cboEvaluate: action.cboEvaluate
        };
    }
    return {
        ...state,
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/index.js
 */

import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/routes.js';
import Config from './config';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import 'antd/dist/antd.css';
import './style/awesome/css/fontawesome-all.css';
import 'bootstrap/dist/css/bootstrap.css';
import './style/index.scss';

function render(Component) {
    const svg = Config.getLocalStorage('LOGO_URL');
    if(svg){
        const link = document.querySelector('#favicon');
        link.href = svg;
    }
    ReactDOM.render(
        (
            <Component/>
        ),
        document.getElementById('root')
    );
}

render(Routes);

if (module.hot) {
    module.hot.accept('./routes/routes.js', () => {
        const NextApp = require('./routes/routes.js').default;
        render(NextApp);
    });
}

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 * @file src/reducers/user.js
 */

import * as types from '../actions/types.js';

const initialState = {
    detailW18F1031: null,
    storeW18F1031: null,
    addW18F1031: null,
    editW18F1031: null,
    actionW18F1031: null,
    comboGroupSalesW18F1031: null,
    loadDataAttchW18F1031: null,
    loadGroupSalesW18F1031: null,
    getStatusW18F1031: null,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1031_DETAIL_COMPLETE:
            return {
                ...state,
                detailW18F1031: action.detailW18F1031
            };
        case types.W18_W18F1031_STORE_COMPLETE:
            return {
                ...state,
                storeW18F1031: action.storeW18F1031
            };
        case types.W18_W18F1031_ADD_COMPLETE:
            return {
                ...state,
                addW18F1031: action.addW18F1031
            };
        case types.W18_W18F1031_EDIT_COMPLETE:
            return {
                ...state,
                editW18F1031: action.editW18F1031
            };
        case types.W18_W18F1031_ACTION_COMPLETE:
            return {
                ...state,
                actionW18F1031: action.actionW18F1031
            };
        case types.W18_W18F1031_GroupSales_COMPLETE:
            return {
                ...state,
                comboGroupSalesW18F1031: action.comboGroupSalesW18F1031
            };
        case types.W18_W18F1031_LOADATTCH_COMPLETE:
            return {
                ...state,
                loadDataAttchW18F1031: action.loadDataAttchW18F1031
            };
        case types.W18_W18F1031_LOADGROUPSALES_COMPLETE:
            return {
                ...state,
                loadGroupSalesW18F1031: action.loadGroupSalesW18F1031
            };
            case types.W18_W18F1031_STATUS_COMPLETE:
            return {
                ...state,
                getStatusW18F1031: action.getStatusW18F1031
            };
        default:
            return state;
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/01/18 15:37
 * @update 2019/01/18 15:37
 */

import * as types from '../actions/types.js';

const initialState = {
    cboGroupSaleW18F1020:null,
    cboSalePersonW18F1020:null,
    cboTypeTimeW18F1020: null,
    loadGridW18F1020:null,
    loadDetailW18F1020:null,
    scheduleData:null
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W18_W18F1020_CBO_GROUPSALE_COMPLETE:
            return {
                ...state,
                cboGroupSaleW18F1020: action.cboGroupSaleW18F1020
            };
        case types.W18_W18F1020_CBO_SALEPERSON_COMPLETE:
            return {
                ...state,
                cboSalePersonW18F1020: action.cboSalePersonW18F1020
            };
        case types.W18_W18F1020_CBO_TYPETIME_COMPLETE:
            return {
                ...state,
                cboTypeTimeW18F1020: action.cboTypeTimeW18F1020
            };
        case types.W18_W18F1020_GRID_COMPLETE:
            return {
                ...state,
                loadGridW18F1020: action.loadGridW18F1020
            };
        case types.W18_W18F1020_DETAIL_COMPLETE:
            return {
                ...state,
                loadDetailW18F1020: action.loadDetailW18F1020
            };
            case types.W18_W18F1020_SCHEDULE_COMPLETE:
            return {
                ...state,
                scheduleData: action.scheduleData
            };
        default:
            return state;
    }
}